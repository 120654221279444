<template>
  <div>
    <b-modal
      ref="dealDetailsModal"
      id="deal-details-modal"
      v-model="showModal"
      @hidden="hide()"
      ok-only
      ok-variant="outline-secondary"
      ok-title="Close"
      lazy
      title="Deal details"
      size="lg"
    >
      <div v-if="deal">
        <GeneralDealDetails :deal="deal" :supply="supply" :currentDeal="currentDeal" :suppliers="suppliers" />

        <b-row class="details-container" v-if="deal.type == 'WASTE'">
          <WasteBinDetails
            :deal="deal"
            id="waste-offer"
            title="Offer Waste Bins"
            :color="color"
            @toggle="toggleCollapsed"
          />

          <WasteBinDetails
            :deal="currentDeal"
            id="waste-current"
            title="Current Contract Waste Bins"
            :color="color"
            @toggle="toggleCollapsed"
          />
        </b-row>

        <b-row class="details-container" v-if="deal.type == 'MOBILE'">
          <MobileDetails
            :deal="deal"
            id="mobile-offer"
            title="Offer Details"
            :color="color"
            @toggle="toggleCollapsed"
            :suppliers="suppliers"
          />
          <MobileDetails
            :deal="currentDeal"
            id="mobile-current"
            title="Current Contract Details"
            :color="color"
            @toggle="toggleCollapsed"
            :suppliers="suppliers"
          />
        </b-row>

        <b-row class="details-container" v-if="deal.type == 'CARD_PAYMENTS'">
          <CardDetails
            :deal="deal"
            :currentDeal="currentDeal"
            id="card-offer"
            title="Offer Details"
            :color="color"
            @toggle="toggleCollapsed"
            :suppliers="suppliers"
          />
        </b-row>

        <b-row class="details-container" v-if="deal.type == 'TELECOMS'">
          <TelecomsDetails
            :deal="deal"
            id="telecoms-offer"
            title="Offer Details"
            :color="color"
            @toggle="toggleCollapsed"
          />
          <TelecomsDetails
            :deal="currentDeal"
            id="telecoms-current"
            title="Current Contract Details"
            :color="color"
            @toggle="toggleCollapsed"
          />
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<style lang="scss">
@import "@/styles/common.scss";

#deal-details-modal {
  .modal-header {
    padding-bottom: 0.5rem !important;
    background-color: $color-font-headings;

    button {
      color: white;
    }

    h5 {
      font-family: "poppins";
      color: white;
      margin-left: 1rem;
    }
  }

  .details-title {
    margin: 0 1rem 0 1rem;

    & > .title-parts {
      padding: 0 !important;
      margin-bottom: 1rem;
      .one {
        max-width: 60%;
        float: left;
        .badge {
          font-family: "Encode Sans", arial, sans-serif;
          font-weight: bold;
          text-align: center;
          padding: 0.25rem !important;
          margin-right: 0.5rem !important;
          margin-bottom: 0.25rem;
        }

        @media screen and (max-width: 991px) {
          text-align: center;
          width: 100%;
          max-width: 100%;
          float: none;
        }
      }
    }
  }

  .details-container {
    //   .basic-details

    .dropdown-reducer {
      background-color: $color-grey-lighter3;
      margin: 0.5rem 15px 0 15px;
      border-radius: 10px;

      & > h5 {
        margin: 0;
        padding: 0.5rem 0.5rem 0.5rem 1rem;
      }

      &:hover {
        cursor: pointer;
      }
    }

    .details-flex {
      padding: 10px;
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      justify-content: flex-start;

      & > div {
        flex-grow: 2;
        padding: 9px;
        margin: 8px;
        width: 23rem;
        @media screen and (max-width: 991px) {
          width: 100%;
        }

        p {
          // Don't apply border to the last p
          &:not(:last-of-type) {
            border-bottom: solid 1px $color-grey-lighter3;
          }
          padding: 5px 10px 5px 10px;
          color: $color-font-headings;
          margin-bottom: 0.3rem;
          display: inline-block;
          width: 100%;
          & > b {
            float: right;
            text-align: right;

            &.note-style {
              max-width: 85%;
            }

            &.description-style {
              max-width: 70%;
            }

            &.basic-style {
              max-width: 80%;
            }
          }
        }
      }

      .reducer-box-shadow {
        border-radius: 12px;
        padding-bottom: 0;
      }
    }

    .card-payments-style {
      h5 {
        font-family: "Encode Sans", arial, sans-serif;
        margin-bottom: 0px;
        color: $color-font-headings;
      }

      p {
        color: $color-font-headings;
      }

      .reducer-box-shadow {
        border-radius: 1rem;
        margin-bottom: 12px !important;
      }

      @media screen and (max-width: 991px) {
        p {
          font-size: 0.8rem;
        }
      }

      @media screen and (max-width: 575px) {
        text-align: center;
        h5 {
          font-size: 1.08rem;
        }
      }
    }

    h5 {
      font-family: "poppins";
      font-size: 1rem;
    }

    p {
      margin-bottom: 0.4rem;
    }

    .fa-chevron-up {
      transition: all 0.5s ease;
    }

    .fa-chevron-up.animate-chevron-down {
      transition: all 0.5s ease;
      transform: rotate(180deg);
    }
  }
}
</style>

<script>
import GeneralDealDetails from "@/components/savingsreport/GeneralDealDetails";
import WasteBinDetails from "@/components/savingsreport/WasteBinDetails";
import MobileDetails from "@/components/savingsreport/MobileDetails";
import CardDetails from "@/components/savingsreport/CardDetails";
import TelecomsDetails from "@/components/savingsreport/TelecomsDetails";

export default {
  name: "DealDetailsModal",
  props: {
    deal: Object,
    currentDeal: {
      type: Object,
      default: null
    },
    supply: Object,
    color: {
      type: String,
      default: "#F40C8F"
    },
    suppliers: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      showModal: false
    };
  },
  components: {
    GeneralDealDetails,
    WasteBinDetails,
    MobileDetails,
    CardDetails,
    TelecomsDetails
  },
  methods: {
    show() {
      this.showModal = true;
    },

    hide() {
      this.showModal = false;
    },

    toggleCollapsed(id) {
      var element = document.getElementById(id);
      var chevron = document.getElementById(id + "-chevron");

      if (element.className.includes(" show")) {
        element.className = element.className.replace(" show", "");
      } else {
        element.className += " show";
      }

      if (!chevron.classList.value.includes(" animate-chevron-down")) {
        chevron.classList.value += " animate-chevron-down";
      } else {
        chevron.classList.value = chevron.classList.value.replace(" animate-chevron-down", "");
      }
    }
  }
};
</script>
