<template>
  <div class="other-saving-options-container other-saving-options-mobile-container px-4 px-md-0">
    <div v-for="(deal, dealName) in offers" :key="dealName" v-show="deal.supplierId">
      <!-- Recommended badge -->
      <div :style="styleObject" :class="'mx-3 mx-md-5 info-badge-' + displayInfoBadge(deal)">
        <p v-if="hasActiveContract && contract.dealId == deal.dealId" class="mb-0 px-3 pt-1">
          {{ contract.state == "OUT" ? "Contract ready to sign" : "Contract has been signed" }}
        </p>
        <p v-else-if="deal.recommended" class="mb-0 px-3 pt-1">
          Recommended
        </p>
      </div>

      <div class="w-100 other-savings-option other-savings-option-mobile">
        <b-row
          :style="styleObject"
          :class="
            'my-3 d-flex justify-content-center align-items-center position-relative reducer-box-shadow savings-card show-border-' +
              displayInfoBadge(deal)
          "
        >
          <!-- Supplier and handset images column -->
          <b-col
            cols="12"
            md="2"
            class="deal-col image-col"
            @click="openSupplierDetailsModal(deal.supplierId)"
          >
            <b-img
              v-if="deal.numbers && deal.numbers[0].handset && deal.numbers[0].handset.s3ImageUrl"
              :src="deal.numbers[0].handset.s3ImageUrl"
              class="handset-image-size"
              v-b-popover.hover.top="'Handset included'"
            />
            <b-img
              v-else-if="deal.numbers && deal.numbers[0].handset"
              v-b-popover.hover.top="'Handset included'"
              class="handset-image-size fa-lg missing"
              :src="require('@/assets/images/mobile.png')"
            />
            <b-img
              v-else
              v-b-popover.hover.top="'Sim-only options'"
              class="handset-image-size missing sim-drop-shadow"
              :src="require('@/assets/images/simcard.png')"
            />
            <div
              v-if="
                deal.supplierId &&
                  suppliers &&
                  suppliers[deal.supplierId] &&
                  typeof suppliers[deal.supplierId] != 'undefined' &&
                  suppliers[deal.supplierId].logos3url
              "
              class="supplier-image-div d-none d-md-flex justify-content-center align-items-center"
            >
              <b-img
                :src="suppliers[deal.supplierId].logos3url"
                :alt="suppliers[deal.supplierId].name"
                class="image-size p-1"
              />
            </div>
            <div class="badge-styles absolute-badge">
              <b-badge variant="primary">
                <span v-if="deal.numbers && deal.numbers[0].handset">Handset included</span>
                <span v-else>SIM only</span>
              </b-badge>
            </div>
          </b-col>

          <b-col cols="12" class="d-md-none">
            <div class="text-center" v-if="hasEnoughData(deal)">
              <u class="pointer supplier-link" @click="openSupplierDetailsModal(deal.supplierId)"
                >Supplier details</u
              >
            </div>
          </b-col>

          <!-- Deal details (sm screens) -->
          <b-col cols="12" class="d-md-none p-0">
            <div
              v-if="
                deal.supplierId &&
                  suppliers &&
                  suppliers[deal.supplierId] &&
                  typeof suppliers[deal.supplierId] != 'undefined'
              "
              class="deal-details-row px-3"
            >
              <span>Mobile Provider</span>
              <b-img
                v-if="suppliers[deal.supplierId].logos3url"
                :src="suppliers[deal.supplierId].logos3url"
                :alt="suppliers[deal.supplierId].name"
                height="25px"
                style="margin-top: -5px"
              />
              <b v-else>{{ suppliers[deal.supplierId].name }}</b>
            </div>

            <div class="deal-details-row px-3">
              <span>Contract Term</span>
              <b>{{ formatMonthsToYears(deal.contractTerm.toFixed(0)) }}</b>
            </div>

            <div v-if="deal.estimatedAnnualCost" class="deal-details-row px-3">
              <span>Estimated Annual Cost</span
              ><b>{{ $currencySymbol }}{{ formatNumberToDisplay(deal.estimatedAnnualCost) }}</b>
            </div>
            <div v-else class="deal-details-row px-3">
              <span>Annual Cost</span
              ><b>{{ $currencySymbol }}{{ formatNumberToDisplay(deal.annualCost) }}</b>
            </div>

            <div class="deal-details-row px-3">
              <span v-if="deal.projectedAnnualCost">Annual Savings vs Renewal</span>
              <span v-else>Annual Savings</span>
              <b>{{ $currencySymbol }}{{ formatNumberToDisplay(deal.saving) }}</b>
            </div>
          </b-col>

          <!-- Deal details columns (md< screens) -->

          <b-col cols="4" md="2" class="deal-col deal-details-col">
            <div>
              <h5 v-if="deal.contractTerm">
                <span>{{ formatMonthsToYears(deal.contractTerm) }}</span>
              </h5>
              <p v-if="deal.contractTerm" class="m-0">Contract</p>
            </div>
          </b-col>

          <b-col cols="4" md="2" class="deal-col deal-details-col">
            <div>
              <div v-if="deal.annualCost">
                <h5>
                  <span>{{ $currencySymbol }}{{ formatNumberToDisplay(deal.annualCost) }}</span>
                </h5>
                <p class="m-0">Annual cost</p>
              </div>
              <div v-else-if="deal.estimatedAnnualCost">
                <h5>
                  <span
                    >{{ $currencySymbol
                    }}{{ formatNumberToDisplay(deal.estimatedAnnualCost) }}</span
                  >
                </h5>
                <p class="m-0">Estimated Annual cost</p>
              </div>
            </div>
          </b-col>

          <b-col cols="4" md="2" class="deal-col deal-details-col">
            <div>
              <h5>
                <span v-if="deal.saving"
                  >{{ $currencySymbol }}{{ formatNumberToDisplay(deal.saving) }}</span
                >
                <span v-else>{{ $currencySymbol }}0</span>
              </h5>
              <p class="m-0">Savings/yr</p>
            </div>
          </b-col>

          <!-- More details, Take Deal columns -->

          <b-col cols="6" md="2" class="deal-col d-none d-md-flex">
            <div>
              <u
                class="pointer"
                @click="
                  dealDetails = deal;
                  $refs.dealDetailModal.show();
                "
                >Offer details</u
              >
              <div class="mt-2" v-if="hasEnoughData(deal)">
                <u class="pointer supplier-link" @click="openSupplierDetailsModal(deal.supplierId)"
                  >Supplier details</u
                >
              </div>
            </div>
          </b-col>

          <b-col cols="6" md="2" class="deal-col" :style="styleObject">
            <b-button
              class="take-deal-btn gtm-takedeal-attempt py-2 px-4 px-md-2 px-xl-4"
              :style="hasActiveContract ? `background: grey !important;` : ``"
              @click="handleTakeDeal(deal)"
              :disabled="hasActiveContract"
            >
              {{ hasActiveContract && contract.dealId == deal.dealId ? "Deal taken" : "Take deal" }}
            </b-button>
          </b-col>

          <!-- Show more details on the right for sm screens -->
          <b-col cols="6" md="2" class="deal-col d-md-none">
            <div>
              <u
                class="pointer"
                @click="
                  dealDetails = deal;
                  $refs.dealDetailModal.show();
                "
                >Offer details</u
              >
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
    <SupplierDetailsModal
      :supplier="supplierDetails"
      @cancelled="supplierDetails = null"
      :primaryColor="primaryColor"
    ></SupplierDetailsModal>
    <DealDetailsModal
      ref="dealDetailModal"
      :suppliers="suppliers"
      :deal="dealDetails"
      :currentDeal="supply.currentDeal"
      :supply="supply"
      :color="primaryColor"
    ></DealDetailsModal>
    <DemoSavingsReportModal :demoModal="demoModal" :color="primaryColor"></DemoSavingsReportModal>
    <TakeDealModal
      :deal="takeDeal"
      :organisationId="organisationId"
      :created="created"
      :primaryColor="primaryColor"
      :secondaryColor="secondaryColor"
      @cancelled="takeDeal = null"
    />
  </div>
</template>

<script>
import { otherSavingOptionsMixin } from "./mixins/otherSavingOptionsMixin";

export default {
  name: "OtherSavingOptionsMobile",
  mixins: [otherSavingOptionsMixin],

  created() {
    //Order Handset numbers by most expensive handset
    if (typeof this.supply.deals != "undefined" && this.supply.deals) {
      this.supply.deals.forEach(dealElem => {
        if (typeof dealElem.numbers !== "undefined" && dealElem.numbers) {
          dealElem.numbers.sort((a, b) => {
            let valueA = 0;
            let valueB = 0;

            if (typeof a.handset === "undefined" || !a.handset) {
              valueA = -1;
            } else if (a.handset.isLocked == false) {
              valueA =
                typeof a.handset.unlockedPrice !== "undefined" && a.handset.unlockedPrice
                  ? a.handset.unlockedPrice
                  : 0;
            } else {
              valueA =
                typeof a.handset.lockedPrice !== "undefined" && a.handset.lockedPrice
                  ? a.handset.lockedPrice
                  : 0;
            }

            if (typeof b.handset === "undefined" || !b.handset) {
              valueB = -1;
            } else if (b.handset.isLocked == false) {
              valueB =
                typeof b.handset.unlockedPrice !== "undefined" && b.handset.unlockedPrice
                  ? b.handset.unlockedPrice
                  : 0;
            } else {
              valueB =
                typeof b.handset.lockedPrice !== "undefined" && b.handset.lockedPrice
                  ? b.handset.lockedPrice
                  : 0;
            }

            if (valueA > valueB) {
              return -1;
            } else {
              return 1;
            }
          });
        }
      });
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/common.scss";
@import "./styles/otherSavingOptions";

.other-savings-option-mobile {
  .deal-col {
    height: 140px !important;
  }
  .handset-image-size {
    // Start required for IE
    flex: 0 0 auto;
    width: auto;
    height: auto;
    // End required for IE
    max-width: 80%;
    max-height: 100%;
    color: $color-grey-lighter2;
    &.missing {
      padding: 1rem;
    }
    &.sim-drop-shadow {
      filter: drop-shadow(2px 3px 2px $color-grey-lighter2) !important;
    }

    @media screen and (max-width: 991px) {
      max-width: 100%;
      &.missing {
        padding: 0.3rem;
      }
    }
  }

  .supplier-image-div {
    position: absolute;
    bottom: 12px;
    right: 12px;
    border-radius: 50%;

    width: 60px;
    height: 60px;
    background-color: white;
    box-shadow: 0px 1px 3px $color-grey-lighter2;
  }

  @media screen and (max-width: 767px) {
    .deal-col {
      height: unset !important;
    }
    .image-col {
      height: 150px !important;
    }
  }
}
</style>
