<template>
  <div class="w-100" id="card-details-container">
    <div
      v-if="currentDeal && currentDeal.terminals && currentDeal.terminals.length > 0"
      class="dropdown-reducer"
      @click="$emit('toggle', 'card-payments-current-terminals-id')"
    >
      <h5>
        Current terminals
        <span class="explanation-header">
          - these are the terminals included in your current contract for reference
        </span>
        <font-awesome-icon
          class="mr-1 mt-1 float-right"
          id="card-payments-current-terminals-id-chevron"
          :icon="['fas', 'chevron-up']"
          v-bind:style="{ color: color }"
        />
      </h5>
    </div>

    <div
      id="card-payments-current-terminals-id"
      class="collapse show w-100 card-payments-style"
      v-if="currentDeal.terminals && currentDeal.terminals.length > 0"
    >
      <div>
        <b-row
          v-for="(terminal, index) in currentDeal.terminals"
          :key="index"
          class="mx-4 my-2 px-3 reducer-box-shadow"
        >
          <b-col lg="5" md="5" sm="5" cols="12" class="px-1 py-2 my-auto">
            <h5 v-if="terminal.terminalType">{{ terminal.terminalType }}</h5>
            <h5 v-else>--</h5>
            <p class="m-0">Type</p>
          </b-col>

          <b-col lg="4" md="4" sm="4" cols="6" class="px-1 py-2 my-auto">
            <h5 v-if="terminal.terminalModel">{{ terminal.terminalModel }}</h5>
            <h5 v-else>--</h5>
            <p class="m-0">Model</p>
          </b-col>

          <b-col lg="3" md="3" sm="3" cols="6" class="px-1 py-2 my-auto">
            <h5 v-if="terminal.terminalCharge">{{ formatPrice(terminal.terminalCharge) }}</h5>
            <h5 v-else>--</h5>
            <p class="m-0">Monthly Cost</p>
          </b-col>
        </b-row>
      </div>
    </div>

    <div
      v-if="deal.terminals && deal.terminals.length > 0"
      class="dropdown-reducer"
      @click="$emit('toggle', 'card-payments-terminals-id')"
    >
      <h5>
        Offer Terminals
        <span class="explanation-header"> - these are the terminals included in the offer </span>
        <font-awesome-icon
          class="mr-1 mt-1 float-right"
          id="card-payments-terminals-id-chevron"
          :icon="['fas', 'chevron-up']"
          v-bind:style="{ color: color }"
        />
      </h5>
    </div>

    <div
      id="card-payments-terminals-id"
      class="collapse show w-100 card-payments-style"
      v-if="deal.terminals && deal.terminals.length > 0"
    >
      <div>
        <b-row
          v-for="(terminal, index) in deal.terminals"
          :key="index"
          class="mx-4 my-2 px-3 reducer-box-shadow"
        >
          <b-col lg="5" md="5" sm="5" cols="12" class="px-1 py-2 my-auto">
            <h5 v-if="terminal.terminalType">{{ terminal.terminalType }}</h5>
            <h5 v-else>--</h5>
            <p class="m-0">Type</p>
          </b-col>

          <b-col lg="4" md="4" sm="4" cols="6" class="px-1 py-2 my-auto">
            <h5 v-if="terminal.terminalModel">{{ terminal.terminalModel }}</h5>
            <h5 v-else>--</h5>
            <p class="m-0">Model</p>
          </b-col>

          <b-col lg="3" md="3" sm="3" cols="6" class="px-1 py-2 my-auto">
            <h5 v-if="terminal.terminalCharge">{{ formatPrice(terminal.terminalCharge) }}</h5>
            <h5 v-else>--</h5>
            <p class="m-0">Monthly Cost</p>
          </b-col>
        </b-row>
      </div>
    </div>

    <!-- CARD TYPE FEES-->
    <div
      class="dropdown-reducer"
      @click="$emit('toggle', 'card-type-fees-id')"
      v-if="deal.type == 'CARD_PAYMENTS' && currentDeal.dealId != deal.dealId"
    >
      <h5>
        See the
        <b v-bind:style="{ color: color }">fees</b>
        <font-awesome-icon
          class="mr-1 float-right"
          :icon="['fas', 'chevron-up']"
          id="card-type-fees-id-chevron"
          v-bind:style="{ color: color }"
        />
      </h5>
    </div>

    <div
      id="card-type-fees-id"
      class="collapse show w-100 card-payments-style"
      v-if="deal.type == 'CARD_PAYMENTS' && currentDeal.dealId != deal.dealId"
    >
      <CardPaymentsFees
        :currentDeal="currentDeal"
        :openedDeal="deal"
        :color="color"
      ></CardPaymentsFees>
    </div>

    <!-- CARD TYPE RATES-->
    <div
      class="dropdown-reducer"
      @click="$emit('toggle', 'card-type-rates-id')"
      v-if="deal.type == 'CARD_PAYMENTS' && currentDeal.dealId != deal.dealId"
    >
      <h5>
        See the
        <b v-bind:style="{ color: color }">rates</b>
        <font-awesome-icon
          class="mr-1 float-right"
          id="card-type-rates-id-chevron"
          :icon="['fas', 'chevron-up']"
          v-bind:style="{ color: color }"
        />
      </h5>
    </div>

    <div
      id="card-type-rates-id"
      class="collapse show w-100 card-payments-style"
      v-if="deal.type == 'CARD_PAYMENTS' && currentDeal.dealId != deal.dealId"
    >
      <CardPaymentsRates
        :currentDeal="currentDeal"
        :openedDeal="deal"
        :color="color"
      ></CardPaymentsRates>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/styles/common.scss";
#card-details-container {
  .explanation-header {
    font-size: 14px;
    font-family: "Roboto", sans-serif;
  }
}
</style>

<script>
import FormatHelper from "@/helper/formathelper";
import CardPaymentsFees from "@/components/savingsreport/CardPaymentsFees";
import CardPaymentsRates from "@/components/savingsreport/CardPaymentsRates";

export default {
  name: "CardDetails",
  components: {
    CardPaymentsFees,
    CardPaymentsRates
  },
  props: {
    deal: Object,
    currentDeal: Object,
    id: {
      type: String
    },
    open: {
      type: Boolean,
      default: true
    },
    title: String,
    color: {
      type: String
    }
  },
  methods: {
    formatPrice: FormatHelper.formatPrice
  }
};
</script>