<template>
  <b-modal v-model="showModal" id="savings-history-modal" lazy hide-footer title="Savings History">
    <b-pagination
      class="float-right"
      size="sm"
      v-show="!loading && savingsHistory && savingsHistory.length > perPage"
      v-model="currentPage"
      :total-rows="savingsHistory.length"
      :per-page="perPage"
      aria-controls="history-savings"
    ></b-pagination>

    <Busy v-if="loading" :margin="0" :size="1.5"> Loading savings history </Busy>
    <div v-else-if="itemsForList && itemsForList.length > 0" id="history-savings">
      <b-row class="w-100 mx-1">
        <b-col>
          <b>Published</b>
        </b-col>
        <b-col class="text-center">
          <b>Published By</b>
        </b-col>
        <b-col class="text-right">
          <b>Commission</b>
        </b-col>
      </b-row>
      <b-row
        :per-page="perPage"
        :current-page="currentPage"
        class="history-line my-2 mx-1 py-2"
        v-for="saving in itemsForList"
        :key="saving.createdDate"
        @click="loadSaving(saving)"
      >
        <b-col>
          <p class="m-0">{{ formatDate(saving.createdDate) }}</p>
        </b-col>
        <b-col class="text-center">
          <p class="m-0">
            {{ saving.createdByEmail }}
          </p>
        </b-col>
        <b-col class="text-right">
          <p class="m-0">
            {{ formatCurrency(saving.totalCommission) }}
          </p>
        </b-col>
      </b-row>
    </div>
    <p v-else>We could not find savings history.</p>
  </b-modal>
</template>

<style lang="scss">
@import "@/styles/common.scss";

#savings-history-modal {
  .history-line {
    align-items: center;
    background-color: $color-grey-lighter5;
    border-radius: 10px;
    color: $color-font-headings;
    &:hover {
      background-color: $color-grey-lighter3;
      cursor: pointer;
    }
  }
}
</style>

<script>
import ApiHelper from "@/helper/apihelper";
import { EventBus } from "@/components/eventbus";
import FormatHelper from "@/helper/formathelper";
import Busy from "@/components/Busy";

export default {
  name: "SavingsHistoryModal",
  props: {
    organisationId: String
  },
  components: { Busy },
  data() {
    return {
      showModal: false,
      loading: false,
      savingsHistory: [],
      perPage: 10,
      currentPage: 1
    };
  },
  computed: {
    itemsForList() {
      let result = [];

      if (typeof this.savingsHistory != "undefined" && this.savingsHistory) {
        result = this.savingsHistory.slice(
          (this.currentPage - 1) * this.perPage,
          this.currentPage * this.perPage
        );
      }

      return result;
    }
  },
  methods: {
    formatDate: FormatHelper.formatDate,
    formatCurrency: FormatHelper.formatPoundToDisplay,

    show() {
      this.showModal = true;
      this.loadSavingsHistory();
    },

    hide() {
      this.showModal = false;
    },

    async loadSavingsHistory() {
      this.loading = true;
      try {
        const client = await ApiHelper.http();
        const response = await client.get(
          `${ApiHelper.endPoint()}savings?organisationId=${this.organisationId}&history=true`
        );
        if (response.data.status === "success") {
          this.savingsHistory = response.data.savings;
          if (this.savingsHistory.length > 0) {
            this.savingsHistory.sort((a, b) => (a.createdDate < b.createdDate ? 1 : -1));
          }
        } else {
          this.showAlert("A problem occured whilst getting the savings history", "warning");
        }
      } catch (error) {
        console.error(error);
        this.showAlert("A problem occured whilst getting the savings history", "warning");
      } finally {
        this.loading = false;
      }
    },

    loadSaving(saving) {
      this.$emit("loadSaving", saving);
      this.hide();
    },

    showAlert(message, variant) {
      EventBus.$emit("show-toast", { message: message, variant: variant });
    }
  }
};
</script>
