/* jsPDF Wrapper class */
import "jspdf-autotable";
import Console from "@/console";
import FormatHelper from "@/helper/formathelper";
import GeomanistRegular from "@/helper/GeomanistRegularVFS";
import GeomanistMedium from "@/helper/GeomanistMediumVFS";
import GeomanistBold from "@/helper/GeomanistBoldVFS";
import { app } from "@/config/reducerproperties";

class ReducerPDF {
  static canvasWidth = 0;
  static canvasHeight = 0;

  static canvasMarginRight = 10; //20
  static canvasMarginLeft = 15; //20

  static lastTopEdge = 35; //45

  static pdfBranding = "Reducer";

  static GREY = "#3C3C3C";
  static GREY_LIGHT = "#85888B";
  static PINK = "#f40c8f";
  static BLUE = "#0e87f2";
  static AW_YELLOW = "#fbb90d";
  static AW_BLUE = "#203468"


  static setFontGeomanistRegular(doc) {
    doc.addFileToVFS(
      "geomanist-regular-webfont.ttf",
      GeomanistRegular.getVFS()
    );

    doc.addFont("geomanist-regular-webfont.ttf", "geomanistRegular", "normal");

    doc.setFont("geomanistRegular");
  }

  static setFontGeomanistMedium(doc) {
    doc.addFileToVFS("geomanist-medium-webfont.ttf", GeomanistMedium.getVFS());

    doc.addFont("geomanist-medium-webfont.ttf", "geomanistMedium", "normal");

    doc.setFont("geomanistMedium");
  }

  static setFontGeomanistBold(doc) {
    doc.addFileToVFS("geomanist-bold-webfont.ttf", GeomanistBold.getVFS());

    doc.addFont("geomanist-bold-webfont.ttf", "geomanistBold", "normal");

    doc.setFont("geomanistBold");
  }

  //ELEMENTS

  /* Indents a list of paragraphs :paragraphList: on a given document :doc: => lastTopEdge of the paragraphs
   * Required: doc, paragraphList
   * Additional: fontSize, paddingTop, paddingLeft, paddingRight
   *
   * Description:
   *   doc: jsPDF ; the document the element should be inserted in
   *   paragraphList: String[] ; list of texts, each representing a whole paragraph
   *   fontSize: Integer > 0; Default: 15 ; the fontSize of the the paragraphs
   *   paddingTop: Integer > 0; Default: 0 ; padding from the last top edge of the document
   *   paddingLeft: Integer > 0; Default: 0 ;  padding from the last left edge of the document
   *   paddingRight: Integer > 0; Default: 0 ;  padding from the last right edge of the document
   *
   * Return: the last top edge of the paragraphs
   */
  static paragraph(
    doc,
    paragraphList,
    fontSize,
    paddingTop,
    paddingLeft,
    paddingRight
  ) {
    if (typeof doc == "undefined")
      return Console.error("Error: document is not identified.");
    if (typeof paragraphList == "undefined")
      return Console.error(
        "Error in creating paragraph out of: " +
        paragraphList +
        " : please insert a valid list of texts."
      );

    if (typeof fontSize == "undefined" || fontSize < 1) {
      fontSize = 15;
    }
    if (typeof paddingTop == "undefined" || paddingTop < 1) {
      paddingTop = 0;
    }
    if (typeof paddingLeft == "undefined" || paddingLeft < 1) {
      paddingLeft = 0;
    }
    if (typeof paddingRight == "undefined" || paddingRight < 1) {
      paddingRight = 0;
    }

    var topEdge = this.lastTopEdge + paddingTop;

    doc.setFontSize(fontSize);

    paragraphList.forEach((p) => {
      var pWidth =
        this.canvasWidth -
        (this.canvasMarginLeft +
          paddingLeft +
          this.canvasMarginRight +
          paddingRight);
      doc.text(p, this.canvasMarginLeft + paddingLeft, topEdge, {
        maxWidth: pWidth,
      });

      var dim = doc.getTextDimensions(p);
      var pRows = Math.floor(dim.w / pWidth + 1);
      var lineHeight = doc.getLineHeight(p) / doc.internal.scaleFactor;

      topEdge = topEdge + pRows * lineHeight + lineHeight * 1.15;
    });

    return topEdge;
  }

  static newLines(
    doc,
    textList,
    fontSize,
    paddingTop,
    paddingLeft,
    paddingRight
  ) {
    if (typeof doc == "undefined")
      return Console.error("Error: document is not identified.");
    if (typeof textList == "undefined")
      return Console.error(
        "Error in creating paragraph out of: " +
        textList +
        " : please insert a valid list of texts."
      );

    if (typeof fontSize == "undefined" || fontSize < 1) {
      fontSize = 15;
    }
    if (typeof paddingTop == "undefined" || paddingTop < 1) {
      paddingTop = 0;
    }
    if (typeof paddingLeft == "undefined" || paddingLeft < 1) {
      paddingLeft = 0;
    }
    if (typeof paddingRight == "undefined" || paddingRight < 1) {
      paddingRight = 0;
    }

    var topEdge = this.lastTopEdge + paddingTop;

    doc.setFontSize(fontSize);

    textList.forEach((p) => {
      var pWidth =
        this.canvasWidth -
        (this.canvasMarginLeft +
          paddingLeft +
          this.canvasMarginRight +
          paddingRight);
      doc.text(p, this.canvasMarginLeft + paddingLeft, topEdge, {
        maxWidth: pWidth,
      });

      var dim = doc.getTextDimensions(p);
      var pRows = Math.floor(dim.w / pWidth + 1);
      var lineHeight = doc.getLineHeight(p) / doc.internal.scaleFactor;

      topEdge = topEdge + pRows * lineHeight + 2.5;
    });

    return topEdge;
  }

  static textBreak(doc, textList, fontSize, paddingTop, paddingLeft, maxWidth) {
    if (typeof doc == "undefined")
      return Console.error("Error: document is not identified.");
    if (typeof textList == "undefined")
      return Console.error(
        "Error in creating text out of: " +
        textList +
        " : please insert a valid list of texts."
      );

    if (typeof fontSize == "undefined" || fontSize < 1) {
      fontSize = 15;
    }
    if (typeof paddingTop == "undefined" || paddingTop < 1) {
      paddingTop = 0;
    }
    if (typeof paddingLeft == "undefined" || paddingLeft < 1) {
      paddingLeft = 0;
    }
    if (typeof maxWidth == "undefined" || maxWidth < 1) {
      maxWidth =
        this.canvasWidth - this.canvasMarginRight - this.canvasMarginLeft;
    }

    var topEdge = this.lastTopEdge + paddingTop;
    doc.setFontSize(fontSize);
    doc.setTextColor(this.GREY);

    textList.forEach((p) => {
      if (typeof p != "undefined") {
        doc.text(p, this.canvasMarginLeft + paddingLeft, topEdge, {
          maxWidth: maxWidth,
        });

        var dim = doc.getTextDimensions(p);
        var pRows = Math.floor(dim.w / maxWidth + 1);
        var lineHeight = doc.getLineHeight(p) / doc.internal.scaleFactor;

        topEdge += pRows * lineHeight + 1;
      }
    });

    return topEdge;
  }

  /* Creates a table with the given rows and header => lastTopEdge of the table
   * Required: doc, header, rows
   * Additional: paddingTop, highlight
   *
   * On page overflow, the table automatically expands on the next page thanks to Autotable jsPDF extention
   *
   * Description:
   *   doc: jsPDF ; the document the element should be inserted in
   *   header: String[] ; list of texts, each representing a table header value in the header row
   *   rows: Object[] ; list of objects, each representing a whole row ; Ex: [{ supplier: "Current", cost: "42,765", contract: "unknown", savings: "" },{...}]
   *   paddingTop: Integer > 0; Default: 0 ; padding from the last top edge of the document
   *   highlight: Integer > 0 ; represents the number of the row which should be highlighted
   *
   * Returns: the last top edge of the created table
   */
  static table(
    doc,
    header,
    rows,
    paddingTop,
    highlight,
    paddingLeft,
    cellHeight
  ) {
    if (typeof doc == "undefined")
      return Console.error("Error: document is not identified.");
    if (typeof header == "undefined")
      return Console.error(
        "Error in creating header of table out of: " +
        header +
        " : please insert a valid list of header names."
      );
    if (typeof rows == "undefined")
      return Console.error(
        "Error in creating rows of table out of: " +
        rows +
        " : please insert a valid list of row values."
      );

    if (typeof paddingTop == "undefined" || paddingTop < 1) {
      paddingTop = 0;
    }
    if (typeof paddingLeft == "undefined" || paddingLeft < 1) {
      paddingLeft = 0;
    }
    if (typeof cellHeight == "undefined" || cellHeight < 1) {
      cellHeight = 18;
    }

    var tableTextColor = this.PINK;
    if (this.pdfBranding != "Reducer") {
      tableTextColor = this.AW_BLUE;
    }

    this.setFontGeomanistRegular(doc);
    doc.autoTable({
      headStyles: {
        textColor: this.GREY,
        fontSize: 16,
        fillColor: [247, 247, 247],
      },
      styles: {
        textColor: this.GREY,
        fontSize: 16,
        minCellHeight: cellHeight,
        valign: "middle",
        cellPadding: [0, 0, 0, 15],
      },
      body: rows,
      columns: header,
      startY: this.lastTopEdge + paddingTop,
      margin: [0, paddingLeft, 0, paddingLeft],
      didParseCell: function (data) {
        if (data.row.index == highlight) {
          data.cell.styles.textColor = tableTextColor;
          data.cell.styles.fontStyle = "bold";
        }
        if (data.column.dataKey == "nRates") {
          data.cell.styles.textColor = tableTextColor;
          data.cell.styles.fontStyle = "bold";
        }

        if (data.section === "head") {
          data.cell.styles.textColor = "#3C3C3C";
        }
      },
    });

    return doc.lastAutoTable.finalY;
  }

  /* Creates a table with the given rows and header, adding a logo from the rows at the beginniong of each row => lastTopEdge of the table
   * Required: doc, header, rows ; rows Objects should have a "logo" field, with an img object in it.
   * Additional: paddingTop, highlight
   *
   * On page overflow, the table automatically expands on the next page thanks to Autotable jsPDF extention
   *
   * Description:
   *   doc: jsPDF ; the document the element should be inserted in
   *   header: String[] ; list of texts, each representing a table header value in the header row
   *   rows: Object[] ; list of objects, each representing a whole row ; Ex: [{ logo: logoObj, supplier: "Current", cost: "42,765" },{...}]
   *   paddingTop: Integer > 0; Default: 0 ; padding from the last top edge of the document
   *   highlight: Integer > 0 ; represents the number of the row which should be highlighted
   *   logoWidth: Integer > 0; Default: 30; represents the witdth of the logos
   *
   * Returns: the last top edge of the created table
   */
  static tableWithLogos(doc, header, rows, paddingTop, highlight, logoWidth) {
    if (typeof doc == "undefined")
      return Console.error("Error: document is not identified.");
    if (typeof header == "undefined")
      return Console.error(
        "Error in creating header of table out of: " +
        header +
        " : please insert a valid list of header names."
      );
    if (typeof rows == "undefined")
      return Console.error(
        "Error in creating rows of table out of: " +
        rows +
        " : please insert a valid list of row values."
      );

    if (typeof paddingTop == "undefined" || paddingTop < 1) {
      paddingTop = 0;
    }
    if (typeof logoWidth == "undefined" || logoWidth < 1) {
      logoWidth = 30;
    }

    doc.autoTable({
      headStyles: { fontSize: 15, fillColor: [247, 247, 247] },
      styles: {
        textColor: "#747783",
        fontSize: 15,
        cellPadding: { right: 0, left: 0, top: 5, bottom: 5 },
      },
      columnStyles: {
        0: { cellPadding: { right: 4 }, minCellWidth: logoWidth },
      },
      body: rows,
      columns: header,
      startY: this.lastTopEdge + paddingTop,
      margin: 5,
      didParseCell: function (data) {
        if (data.row.index === highlight) {
          data.cell.styles.textColor = [244, 12, 143];
          data.cell.styles.fontStyle = "bold";
        }
      },
      didDrawCell: function (data) {
        if (data.column.index === 0 && data.cell.section === "body") {
          if (typeof rows[data.row.index].logo != "undefined") {
            var img = rows[data.row.index].logo;
            var height = data.cell.height - data.cell.padding("vertical");
            var width = data.cell.width - data.cell.padding("horizontal");
            var textPos = data.cell.textPos;
            doc.addImage(img, "IMG", textPos.x, textPos.y, width, height);
          }
        }
      },
    });

    return doc.lastAutoTable.finalY;
  }

  static centraliseTextUnderLogo(doc, logoWidth, text) {
    var textDimentions = doc.getTextDimensions(text);
    return -1 * (textDimentions.w / 2 - logoWidth / 2);
  }

  /* Generates a line with logos on top and text written under them;
   * returns the new lastTopEdge from the line;
   *   map: Object[] ; list of objects, each representing an item in the list ; Ex: [{ logo: imgObj, text: "42,765", text2: "unknown"},{...}]
   */
  static logoKeyListLine(doc, map, paddingLeft, paddingTop, fontSize) {
    var logoWidth = 18;
    var logoHeight = 18;
    var logoBottomSpace = 13;

    if (typeof doc == "undefined")
      return Console.error("Error: document not identified.");
    if (typeof map == "undefined")
      return Console.error(
        "Error in creating the item list out of: " +
        map +
        " : please insert a valid list of logos and texts of form: [{logo: .. , main: ... , secondary: ... }]."
      );

    if (typeof fontSize == "undefined" || fontSize < 1) {
      fontSize = 15;
    }
    if (typeof paddingTop == "undefined" || paddingTop < 1) {
      paddingTop = 0;
    }
    if (typeof paddingLeft == "undefined" || paddingLeft < 1) {
      paddingLeft = 0;
    }

    doc.setFontSize(fontSize);
    doc.setTextColor(this.GREY);

    var topLogo = this.lastTopEdge + paddingTop;
    var width =
      this.canvasWidth -
      (this.canvasMarginRight + paddingLeft + this.canvasMarginLeft);

    var topText = topLogo;
    map.forEach((element) => {
      var left = this.canvasMarginLeft + paddingLeft;
      var dim = doc.getTextDimensions(element.main);
      var lineHeight =
        doc.getLineHeight(element.main) / doc.internal.scaleFactor;
      var pRows = Math.floor(dim.w / width + 1);

      doc.addImage(
        element["logo"],
        "PNG",
        left,
        topLogo,
        logoWidth,
        logoHeight
      );

      var mainColor = this.GREY;
      var secondaryColor;
      if (this.pdfBranding == "Reducer") {
        secondaryColor = this.PINK;
      } else {
        secondaryColor = this.AW_YELLOW;
      }

      this.setFontGeomanistBold(doc);

      topText = topLogo + logoHeight + 2 + dim.h;
      if (typeof element.secondary != "undefined") {
        if (element.secondary.charAt(0) != app.currencySymbol) {
          mainColor = this.GREY_LIGHT;
          secondaryColor = this.GREY_LIGHT;
          this.setFontGeomanistMedium(doc);
        }
      } else {
        this.setFontGeomanistMedium(doc);
      }

      doc.setTextColor(mainColor);
      left =
        this.canvasMarginLeft +
        paddingLeft +
        this.centraliseTextUnderLogo(doc, logoWidth, element.main);

      doc.text(element.main, left, topText);

      topText += pRows * lineHeight;

      if (typeof element.secondary != "undefined") {
        doc.setTextColor(secondaryColor);
        left =
          this.canvasMarginLeft +
          paddingLeft +
          this.centraliseTextUnderLogo(doc, logoWidth, element.secondary);

        doc.text(element.secondary, left, topText);
      }

      topLogo += logoHeight + logoBottomSpace + pRows * lineHeight + 5;
      topText +=
        doc.getLineHeight(element.secondary) / doc.internal.scaleFactor + 5;
      doc.setTextColor(this.GREY);
    });

    return topLogo >= topText ? topLogo : topText;
  }

  /* Generated an items list, with images as bulletpoints => the last top edge of the list
   * Required: doc, map ; map should be of form [{logo: .. , text1: ... , text2: ... }]
   * Additional: paddingLeft, paddingTop, fontSize; text2 field in map is additional
   *
   * text2 in Map: is an additional highlighted text, placed in a new line
   *
   * Description:
   *   doc: jsPDF ; the document the element should be inserted in
   *   map: Object[] ; list of objects, each representing an item in the list ; Ex: [{ logo: imgObj, text: "42,765", text2: "unknown"},{...}]
   *   paddingLeft: Integer > 0; Default: 0 ; padding from the last left edge of the document
   *   paddingTop: Integer > 0; Default: 0 ; padding from the last top edge of the document
   *   fontSize: Integer > 0; Default: 15 ; the font size of the text1 and text2 field in the map
   *
   * Returns:
   *   the greatest out of the last top edge of the texts OR the image
   */
  static listWithLogos(doc, map, paddingLeft, paddingTop, fontSize) {
    var logoWidth = 15;
    var logoHeight = 15;
    var logoTextSpace = 25;
    var logoLogoSpace = 5;

    if (typeof doc == "undefined")
      return Console.error("Error: document not identified.");
    if (typeof map == "undefined")
      return Console.error(
        "Error in creating the item list out of: " +
        map +
        " : please insert a valid list of logos and texts of form: [{logo: .. , main: ... , secondary: ... }]."
      );

    if (typeof fontSize == "undefined" || fontSize < 1) {
      fontSize = 15;
    }
    if (typeof paddingTop == "undefined" || paddingTop < 1) {
      paddingTop = 0;
    }
    if (typeof paddingLeft == "undefined" || paddingLeft < 1) {
      paddingLeft = 0;
    }

    doc.setFontSize(fontSize);
    doc.setTextColor(this.GREY);

    var left = this.canvasMarginLeft + paddingLeft;
    var topLogo = this.lastTopEdge + paddingTop;
    var width =
      this.canvasWidth -
      (this.canvasMarginRight +
        paddingLeft +
        this.canvasMarginLeft +
        logoWidth +
        logoTextSpace);
    var textLeft = left + logoTextSpace;

    var topText = topLogo;
    map.forEach((element) => {
      var dim = doc.getTextDimensions(element.main);
      var lineHeight =
        doc.getLineHeight(element.main) / doc.internal.scaleFactor;
      var pRows = Math.floor(dim.w / width + 1);

      doc.addImage(
        element["logo"],
        "PNG",
        left,
        topLogo,
        logoWidth,
        logoHeight
      );

      var mainColor = this.GREY;
      var secondaryColor;
      if (this.pdfBranding == "Reducer") {
        secondaryColor = this.PINK;
      } else {
        secondaryColor = this.AW_YELLOW;
      }

      this.setFontGeomanistBold(doc);

      if (typeof element.secondary != "undefined") {
        if (element.secondary.charAt(0) != app.currencySymbol) {
          mainColor = this.GREY_LIGHT;
          secondaryColor = this.GREY_LIGHT;
          this.setFontGeomanistMedium(doc);
        }
        topText = topLogo - 1 + dim.h;
      } else {
        topText = topLogo - 1 + logoHeight / 2;
        this.setFontGeomanistRegular(doc);
      }

      doc.setTextColor(mainColor);
      doc.text(element.main, textLeft, topText, { maxWidth: width });

      topText += pRows * lineHeight;

      if (typeof element.secondary != "undefined") {
        doc.setTextColor(secondaryColor);
        doc.text(element.secondary, textLeft, topText);
      }

      topLogo += logoHeight + logoLogoSpace;
      topText +=
        doc.getLineHeight(element.secondary) / doc.internal.scaleFactor;
      doc.setTextColor(this.GREY);
    });

    return topLogo >= topText ? topLogo : topText;
  }

  static ticket(
    doc,
    logo,
    title,
    paragraphList,
    status,
    paddingLeft,
    paddingTop,
    width,
    height
  ) {
    if (typeof doc == "undefined")
      return Console.error("Error: document is not identified.");

    if (typeof paddingTop == "undefined" || paddingTop < 1) {
      paddingTop = 0;
    }
    if (typeof paddingLeft == "undefined" || paddingLeft < 1) {
      paddingLeft = 0;
    }
    if (typeof width == "undefined" || width < 1) {
      width = 80;
    }
    if (typeof height == "undefined" || height < 1) {
      height = 100;
    }
    if (typeof status == "undefined") {
      status = "third";
    }

    var rectMargin = 2;
    var insidePadding = 2;

    var insideTop = this.lastTopEdge + paddingTop + rectMargin + insidePadding;
    var insideLeft =
      this.canvasMarginLeft + paddingLeft + rectMargin + insidePadding;
    var insideWidth = width - 2 * (rectMargin + 2 * insidePadding);

    //CALCULATING LOGO SIZE
    var logoMaxWidth = width <= 85 ? insideWidth - 15 - 2 * insidePadding : 50;
    var logoMaxHeight = width <= 85 ? 23 : 30;

    var aspectRatio = logo ? logo.width / logo.height : 1;
    var logoWidth = logoMaxWidth;
    var logoHeight = logoWidth / aspectRatio;

    if (logoHeight > logoMaxHeight) {
      logoHeight = logoMaxHeight;
      logoWidth = logoHeight * aspectRatio;
      if (logoWidth > logoMaxWidth) {
        logoWidth = logoMaxWidth;
      }
    }

    var centreliseTopPadding = 0;
    if (logoHeight < logoMaxHeight) {
      centreliseTopPadding = (logoMaxHeight - logoHeight) / 2;
    }

    var logoMiddleLeftEdge =
      insideLeft + (width / 2 - logoWidth / 2) - 2 * insidePadding;

    doc.setLineWidth(rectMargin);
    if (status == "primary") {
      if (this.pdfBranding == "Reducer") {
        doc.setDrawColor(244, 207, 214);
      } else {
        doc.setDrawColor(249, 232, 187)
      }
    }
    else { doc.setDrawColor(239, 239, 239); }

    doc.rect(
      this.canvasMarginLeft + paddingLeft,
      this.lastTopEdge + paddingTop,
      width,
      height,
      "S"
    );

    if (logo) {
      doc.addImage(
        logo,
        "PNG",
        logoMiddleLeftEdge,
        insideTop + 3 + centreliseTopPadding,
        logoWidth,
        logoHeight
      );

      insideTop += logoHeight + 2 * (rectMargin + insidePadding + 4);
    } else {
      this.setFontGeomanistMedium(doc);

      this.lastTopEdge = this.newLines(
        doc,
        [paragraphList.supplierName],
        16,
        insideTop - this.lastTopEdge + 5,
        paddingLeft + rectMargin + insidePadding + 5,
        2 * rectMargin + 4 * insidePadding
      );
      this.setFontGeomanistRegular(doc);
    }


    this.setFontGeomanistBold(doc);
    if (typeof title != "undefined" && title != "") {

      if (this.pdfBranding == "Reducer") {
        doc.setTextColor(this.PINK);
      } else {
        doc.setTextColor(this.AW_BLUE);
      }

      doc.setFontSize(20);
      doc.text(title, insideLeft + 3, insideTop);

      if (status == "third") {
        insideTop = insideTop - this.lastTopEdge + 15;
      } else {
        insideTop = insideTop - this.lastTopEdge + 7.5;
      }
      insideTop += 1;
    } else {
      insideTop -= this.lastTopEdge;
    }

    this.setFontGeomanistRegular(doc);
    doc.setTextColor(this.GREY);
    if (typeof paragraphList != "undefined") {
      if (status == "third") {
        this.setFontGeomanistRegular(doc);
        this.lastTopEdge = this.paragraph(
          doc,
          [paragraphList.description],
          16,
          insideTop + 5,
          paddingLeft + rectMargin + insidePadding + 3,
          2 * rectMargin + 4 * insidePadding
        );

        if (paragraphList.keyPoints) {
          this.setFontGeomanistMedium(doc);
          this.lastTopEdge = this.newLines(
            doc,
            ["Key Points: "],
            16,
            0,
            paddingLeft + rectMargin + insidePadding + 3,
            2 * rectMargin + 4 * insidePadding
          );

          this.setFontGeomanistRegular(doc);

          this.newLines(
            doc,
            paragraphList.keyPoints,
            16,
            3,
            paddingLeft + rectMargin + insidePadding + 10,
            2 * rectMargin + 4 * insidePadding
          );
        }
      } else {
        this.textBreak(
          doc,
          paragraphList,
          18,
          insideTop,
          paddingLeft + rectMargin + insidePadding + 3,
          insideWidth
        );
      }
    }

    return this.lastTopEdge + paddingTop + height + rectMargin;
  }

  /* Generates a blue edged bubble, with texts in it => last top edge of bubble
   * Required: doc
   * Additional: text, highlighted_text
   *
   * text font size will be fontSize-3 by default
   *
   * Description:
   *   doc: jsPDF ; the document the element should be inserted in
   *   left: Integer > 0 ; Default: 0 ; padding left for the bubble starting point, considered the last left edge of the page
   *   top: Integer > 0 ; Default: 0 ; padding top for the bubble starting point, considered the last top edge of the page
   *   radius: Integer >0 ; Default: 25 ; radius of the bubble
   *   highlighted_text: String, text inserted into the bubble and highlighted PINK
   *   text: String ,  text inserted into the bubble under the highlighted text
   *   fontSize: Integer > 0; Default: 15 ; the font size of the text in the circle
   *
   * Returns: last top edge of the bubble
   */
  static bubble(
    doc,
    highlighted_text,
    text,
    radius,
    paddingLeft,
    paddingTop,
    fontSize
  ) {
    if (typeof doc == "undefined")
      return Console.error("Error: document is not identified.");

    if (typeof fontSize == "undefined" || fontSize < 1) {
      fontSize = 35;
    }
    if (typeof radius == "undefined" || radius < 1) {
      radius = 25;
    }
    if (typeof paddingTop == "undefined" || paddingTop < 1) {
      paddingTop = 0;
    }
    if (typeof paddingLeft == "undefined" || paddingLeft < 1) {
      paddingLeft = 0;
    }

    var left = this.canvasMarginLeft + paddingLeft;
    var top = this.lastTopEdge + paddingTop;

    doc.setLineWidth(1.7);
    if (this.pdfBranding == "Reducer") { doc.setDrawColor(this.PINK); }
    else {
      doc.setDrawColor(this.AW_BLUE)
    }
    doc.circle(left, top, radius);
    this.setFontGeomanistBold(doc);
    doc.setFontSize(fontSize);

    if (this.pdfBranding == "Reducer") { doc.setTextColor(this.PINK); }
    else {
      doc.setTextColor(this.AW_YELLOW)
    }

    var txtWidth =
      (doc.getStringUnitWidth(highlighted_text) * fontSize) /
      doc.internal.scaleFactor;
    var txtOffset = (2 * left - txtWidth) / 2;

    doc.text(highlighted_text, txtOffset, top - 5);

    doc.setFontSize(fontSize - 9);
    doc.setTextColor(this.GREY);
    txtWidth =
      (doc.getStringUnitWidth(text) * (fontSize - 10)) /
      doc.internal.scaleFactor;
    txtOffset = (2 * left - txtWidth / 2) / 2;

    doc.text(text, txtOffset, top + 7, { maxWidth: radius * 2 - radius * 0.5 });

    this.setFontGeomanistMedium(doc);
    return top + radius;
  }

  /* Generated one of the two types of header, depending on the input. Meant for every page.
   * Sets up the canvas width and height, and the last top edge of the canvas to 45,  in order to navigate that page
   *
   * Required: doc, title_primary
   * Additional: template, , title_secondary, logo
   *
   * Description:
   *   doc: jsPDF ; the document the element should be inserted in
   *   template: String; "primary" OR "secondary" ; Default: "secondary"
   *   title_primary: String ; Default: "" ; primary title of the page
   *   title_secondary: String ; secondary title of the page
   *   logo: an image inserted in the right top corner of the header
   *
   */
  static header(doc, title_primary, template, title_secondary, logo) {

    this.canvasWidth = doc.internal.pageSize.getWidth();
    this.canvasHeight = doc.internal.pageSize.getHeight();
    this.lastTopEdge = 35;
    this.canvasMarginLeft = 15;
    this.canvasMarginRight = 10;

    if (typeof title_primary == "undefined")
      return Console.error(
        "Primary title " + title_primary + " undefined. Please specify a title."
      );

    if (template == "primary") {
      doc.setLineWidth(3);
      if (this.pdfBranding == "Reducer") {
        doc.setDrawColor(244, 12, 143);
      } else {
        doc.setDrawColor(251, 185, 13);
      }

      doc.line(0, this.lastTopEdge, this.canvasWidth, this.lastTopEdge);
      doc.setTextColor(this.GREY);
      this.setFontGeomanistBold(doc);
    } else {

      if (this.pdfBranding == "Reducer") {
        doc.setDrawColor(this.PINK);
        doc.setFillColor(this.PINK);
      } else {
        doc.setDrawColor(this.AW_YELLOW);
        doc.setFillColor(this.AW_YELLOW);
      }
      doc.rect(0, 0, this.canvasWidth, this.lastTopEdge, "FD");
      doc.setTextColor(255, 255, 255);
      this.setFontGeomanistBold(doc);
    }

    doc.setFontSize(30);

    if (typeof title_primary != "undefined" && title_primary)
      if (typeof title_secondary != "undefined" && title_secondary != "") {
        doc.text(title_primary, this.canvasMarginLeft, 16);
        doc.setFontSize(22);
        doc.text(title_secondary, this.canvasMarginLeft, 28);
      } else {
        doc.text(title_primary, this.canvasMarginLeft, 23);
      }

    if (logo) {
      var imageWidth = 55;
      var imageHeight = 25;
      doc.addImage(
        logo,
        "PNG",
        this.canvasWidth - imageWidth - 5,
        5,
        imageWidth,
        imageHeight
      );
    }

    this.lastTopEdge = 45;
    this.setFontGeomanistMedium(doc);
  }

  /* Generated a footer for the page
   *
   * Required: doc, logo
   *
   * Description:
   *   doc: jsPDF ; the document the element should be inserted in
   *   logo: an image inserted in the right bottom corner of the footer
   */
  static footer(doc, logo, aw_logo) {
    var imageWidth = 32;
    var imageHeight = 17;

    doc.addImage(
      logo,
      "PNG",
      this.canvasWidth - imageWidth - 3,
      this.canvasHeight - imageHeight + 1,
      imageWidth,
      imageHeight
    );

    if (aw_logo) {
      doc.addImage(
        aw_logo,
        "PNG",
        1,
        this.canvasHeight - imageHeight + 1,
        imageWidth + 20,
        imageHeight
      );
    }
  }

  /* Generates a sub-title with PINK delimiter for the page
   * Required: jsPdf doc
   * Additional: String title
   */
  static title(doc, title, paddingTop, width) {
    if (typeof paddingTop == "undefined" || paddingTop < 1) {
      paddingTop = 0;
    }
    if (typeof width == "undefined" || width < 1) {
      width = 80;
    }

    doc.setLineWidth(1);
    doc.setDrawColor(244, 12, 143);
    doc.line(
      0,
      this.lastTopEdge + paddingTop,
      width,
      this.lastTopEdge + paddingTop
    ); // horizontal line

    if (title) {
      doc.setFontSize(20);
      doc.text(title, 4, this.lastTopEdge + paddingTop - 4);
    }

    return this.lastTopEdge + 1;
  }

  //PAGES
  static supplierSwitchPage(type,
    doc,
    images,
    supplyType,
    meter,
    current,
    recommended,
    optionsNumber
  ) {
    this.pdfBranding = type;

    if (typeof meter == "undefined" || meter == "") meter = "";

    this.header(doc, "Switch to save", "primary", supplyType + ": " + meter);

    var ticketWidth = 82.5;
    var bubbleRadius = 35;

    var contractTermFormatted = null;
    if (
      typeof recommended.contractTerm != "undefined" &&
      recommended.contractTerm > 0
    )
      contractTermFormatted = FormatHelper.formatMonthsToYears(
        recommended.contractTerm
      );

    var descripionMaxWidth =
      this.canvasWidth -
      (this.canvasMarginLeft + this.canvasMarginRight + 10 + 2 * bubbleRadius);
    var descripionLeftPadding = this.canvasMarginLeft + 2 * bubbleRadius + 10;
    var descriptionTopPadding = 2 * this.lastTopEdge + 5 - bubbleRadius;

    //DESCRIPTION
    var textColor = this.PINK;
    if (this.pdfBranding != "Reducer") {
      textColor = this.AW_BLUE;
    }

    var text =
      "<p style='font-size:25px; color:#3C3C3C; font-weight: bold; font-family: Arial, Helvetica, sans-serif;'> Recommended Option: </p>";
    var text1 = "";
    var text2 = "";
    var text0 = "Switch to";
    if (typeof recommended != "undefined") {
      if (
        recommended.supplierId == current.supplierId ||
        recommended.supplierName == current.supplierName
      ) {
        text0 = "Renew with";
      }

      text1 =
        "<p style='font-size:25px; color:" + textColor + "; font-weight: bold; font-family: Arial, Helvetica, sans-serif;'>" +
        recommended.supplierName +
        "</p>";

      var switchToName = typeof recommended.supplierName == "undefined" ? "our recommended option" : recommended.supplierName;
      text2 =
        "<p style='font-size:22px; color:#3C3C3C; font-family: Arial, Helvetica, sans-serif;'> " +
        text0 +
        " <b>" +
        switchToName +
        "</b> to save <span style='color:" + textColor + "; font-weight: bold; font-family: Arial, Helvetica, sans-serif;'>" +
        app.currencySymbol + FormatHelper.formatNumberToDisplay(recommended.saving) +
        "</span> a year.</p>";
    }

    var text3 = "";
    if (contractTermFormatted != null && recommended.contractTerm > 12 &&
      (FormatHelper.formatNumberToDisplay((recommended.contractTerm / 12) * recommended.saving) !== FormatHelper.formatNumberToDisplay(recommended.saving))
    ) {
      if (
        recommended.supplierId == current.supplierId ||
        recommended.supplierName == current.supplierName
      ) {
        text0 = "By renewing with";
      } else {
        text0 = "By switching to";
      }
      text3 =
        "<p style='font-size:22px; color:#3C3C3C; font-family: Arial, Helvetica, sans-serif;'> " +
        text0 +
        " our recommended option, you will save <span style='color:#ed1290; font-weight: bold; font-family: Arial, Helvetica, sans-serif;'>" +
        app.currencySymbol + FormatHelper.formatNumberToDisplay(
          (recommended.contractTerm / 12) * recommended.saving
        ) +
        "</span> over the next " +
        contractTermFormatted +
        ". <p>";
    }

    //TICKETS
    var currentLogo =
      typeof images[current.supplierId] == "undefined"
        ? images.reducer
        : images[current.supplierId];
    var recommendedLogo =
      typeof images[recommended.supplierId] == "undefined"
        ? images.reducer
        : images[recommended.supplierId];

    var recommendedInfo = [];
    var annualCostRecc = FormatHelper.formatNumberToDisplay(recommended.annualCost) == "" ? 0 : FormatHelper.formatNumberToDisplay(recommended.annualCost);
    var annualCostCurrent = FormatHelper.formatNumberToDisplay(current.annualCost) == "" ? 0 : FormatHelper.formatNumberToDisplay(current.annualCost);
    if (typeof recommended.supplierName == "undefined")
      recommended.supplierName = "Not specified";
    recommendedInfo.push(recommended.supplierName);
    recommendedInfo.push(
      "Annual cost: " + app.currencySymbol +
      annualCostRecc
    );
    if (contractTermFormatted != null)
      recommendedInfo.push("Contract: " + contractTermFormatted);
    recommendedInfo.push(
      "Savings: " + app.currencySymbol + FormatHelper.formatNumberToDisplay(recommended.saving)
    );

    var currentInfo = [];
    currentInfo.push("Current");
    if (typeof current.supplierName == "undefined")
      current.supplierName = "Not specified";
    currentInfo.push(current.supplierName);
    currentInfo.push(
      "Annual cost: " + app.currencySymbol + annualCostCurrent
    );
    if (typeof current.contractExpiry != "undefined")
      currentInfo.push(
        "Expiry date: " + FormatHelper.formatDate(current.contractExpiry)
      );

    //RENDER ELEMENTS
    var lastTop = this.bubble(
      doc,
      app.currencySymbol + FormatHelper.formatNumberToDisplay(recommended.saving),
      " Annual Savings",
      bubbleRadius,
      bubbleRadius,
      this.lastTopEdge
    );

    doc.fromHTML(text, descripionLeftPadding, descriptionTopPadding, {
      pagesplit: true,
      width: descripionMaxWidth,
    });
    this.lastTopEdge += 4;
    descriptionTopPadding = 2 * this.lastTopEdge + 5 - bubbleRadius;
    doc.fromHTML(text1, descripionLeftPadding, descriptionTopPadding, {
      pagesplit: true,
      width: descripionMaxWidth,
    });

    this.lastTopEdge += 8;
    descriptionTopPadding = 2 * this.lastTopEdge + 5 - bubbleRadius;
    doc.fromHTML(text2, descripionLeftPadding, descriptionTopPadding, {
      pagesplit: true,
      width: descripionMaxWidth,
    });

    if (recommended.contractTerm > 1) {
      this.lastTopEdge += 8;
      descriptionTopPadding = 2 * this.lastTopEdge + 5 - bubbleRadius;
      doc.fromHTML(text3, descripionLeftPadding, descriptionTopPadding, {
        pagesplit: true,
        width: descripionMaxWidth,
      });
    }

    this.lastTopEdge = lastTop + 10;

    this.ticket(
      doc,
      currentLogo,
      "",
      currentInfo,
      "secondary",
      0,
      15,
      ticketWidth,
      90
    );
    this.ticket(
      doc,
      recommendedLogo,
      "Recommended",
      recommendedInfo,
      "primary",
      ticketWidth + 15,
      15,
      ticketWidth,
      90
    );

    if (optionsNumber > 2) {
      doc.fromHTML(
        "<p style='font-size:18px; color:#3C3C3C; font-family: Arial, Helvetica, sans-serif;' > We have <span style='color:#ed1290; font-weight: bold; font-family: Arial, Helvetica, sans-serif;'>" +
        (optionsNumber - 2) +
        "</span> more option(s) for you</p>",
        123,
        this.lastTopEdge + 15 + 90 + 5
      );
    }

    this.footer(doc, images.reducer);
  }

  static otherOptionsPage(type, doc, logo, title, table, observations, highlightRow) {
    this.pdfBranding = type;

    var maintitle = typeof table.rows == "undefined" || table.rows.length == 0 ? "More details" : "All Options";
    this.header(doc, maintitle, "secondary", title);

    doc.setTextColor(this.GREY);

    if (observations.length > 0) {
      if (maintitle == "All Options") {
        doc.setFontSize(18);
        doc.text(
          "Observations for your recommended supplier: ",
          5,
          this.lastTopEdge + 2
        );

        this.lastTopEdge += 10;
      }

      this.lastTopEdge = this.listWithLogos(doc, observations, 0, 0, 18);

      if (typeof table.rows != "undefined" && table.rows.length > 0) {
        this.lastTopEdge += 10;
        this.setFontGeomanistMedium(doc);
        doc.text("Other deal options: ", 5, this.lastTopEdge);
        this.lastTopEdge += 5;
      }
    }

    if (typeof table.rows != "undefined" && table.rows.length > 0) {
      this.lastTopEdge = this.table(
        doc,
        table.header,
        table.rows,
        3,
        highlightRow
      );
    }

    this.footer(doc, logo);
  }

  static savingsPage(type,
    doc,
    logo,
    supplier_name,
    suggested_switches,
    anual_savings,
    savings,
    aw_logo
  ) {
    this.pdfBranding = type;

    if (typeof supplier_name != "undefined" && supplier_name.length > 0) {
      this.header(doc, supplier_name, "primary");
    } else {
      this.header(doc, "Your savings", "primary");
    }

    var radius = 35;
    this.bubble(
      doc,
      suggested_switches,
      "Suggested       Switches",
      radius,
      radius + 5,
      this.lastTopEdge
    );
    this.lastTopEdge = this.bubble(
      doc,
      anual_savings,
      " Annual Savings",
      radius,
      4 * radius,
      this.lastTopEdge
    );

    var listSplitInThree = [];
    let listPaddingLeft = 12;
    for (var i = 0; i < savings.length; i++) {
      if (i % 3 == 0 && i != 0) {
        this.logoKeyListLine(doc, listSplitInThree, listPaddingLeft, 20, 17);
        listSplitInThree = [];
        listPaddingLeft += 69;
      }

      listSplitInThree.push(savings[i]);
    }

    this.logoKeyListLine(doc, listSplitInThree, listPaddingLeft, 20, 17);

    if (this.pdfBranding == "Reducer") {
      this.footer(doc, logo);
    } else {
      this.footer(doc, logo, aw_logo);
    }

  }

  static keyInformationPage(type, doc, logos, reducerLogo, savings) {
    this.pdfBranding = type;
    this.header(doc, "Key Information", "primary");

    let iconWidth = 23;
    let iconHeight = 16;

    var textPadding = iconWidth + this.canvasMarginLeft + 23;
    this.lastTopEdge += 10;

    var topEdgeIcons = this.lastTopEdge;
    for (var i = 0; i < 2; i++) {
      doc.addImage(
        logos[i],
        "PNG",
        this.canvasMarginLeft + 8,
        topEdgeIcons,
        iconWidth,
        iconHeight
      );
      topEdgeIcons += 50;
    }

    var textColor = this.PINK;
    if (this.pdfBranding != "Reducer") {
      textColor = this.AW_BLUE;
    }

    var maxWidth =
      this.canvasWidth - (textPadding + this.canvasMarginRight + 9);
    var text1 =
      "<p style='font-size:24px; color:#747783; font-family: Arial, Helvetica, sans-serif;'> To take one of the deals in the report or to discuss the recommended deals with a savings analyst, email us at <span style='color:" + textColor + "; font-weight: bold;'>support@reducer.co.uk</span>, or call us on<span style='color:" + textColor + "; font-weight: bold;'> 020-3970-4686</span>.</p>";
    var text2 =
      "<p style='font-size:24px; color:#747783; font-family: Arial, Helvetica, sans-serif;'> Do nothing and miss out on <span style='color:" + textColor + "; font-weight: bold;'>" +
      app.currencySymbol + FormatHelper.formatNumberToDisplay(savings) +
      "</span> worth of savings per year! </p>";

    doc.fromHTML(text1, textPadding, this.lastTopEdge - 2, {
      pagesplit: true,
      width: maxWidth,
    });
    this.lastTopEdge += 50;
    doc.fromHTML(text2, textPadding, this.lastTopEdge, {
      pagesplit: true,
      width: maxWidth,
    });

    this.lastTopEdge += 35;

    var textList = new Array();
    textList.push("At Reducer, we love saving businesses money.");
    textList.push(
      "We have already helped thousands of British businesses discover millions of pounds of savings. "
    );
    textList.push(
      "We pride ourselves on making switching suppliers effortless. We manage the whole process, prepare contracts, talk to new and old suppliers and make every switchover as smooth as possible."
    );
    textList.push(
      "Our customer service team is UK based and available 9am-5pm Monday to Friday. "
    );

    doc.setTextColor("#747783");
    this.setFontGeomanistRegular(doc);
    this.paragraph(doc, textList, 18, 10, 5, 7);

    this.footer(doc, reducerLogo);
  }

  static supplierDescriptionPage(type, doc, images, supplier) {
    this.pdfBranding = type;
    this.header(doc, "About Supplier", "primary", supplier.name);

    let supplierText = new Map();

    if (supplier.shortDescription) {
      supplierText["description"] = supplier.shortDescription;
    }

    if (supplier.keyPoints.length > 0) {
      let keyPoints = [];
      supplier.keyPoints.forEach((keyPoint) => {
        keyPoints.push(" • " + keyPoint);
      });
      supplierText["keyPoints"] = keyPoints;
    }

    supplierText["supplierName"] = supplier.name;

    this.ticket(
      doc,
      images[supplier.supplierId],
      "",
      supplierText,
      "third",
      0,
      10,
      this.canvasWidth - 2 * this.canvasMarginLeft,
      this.canvasHeight - 90
    );

    this.footer(doc, images.reducer);
  }

  static supplierNoSwitchPage(type,
    doc,
    logo,
    title,
    subtitle,
    observations,
    supplierLogo
  ) {
    this.pdfBranding = type;

    this.header(doc, title, "secondary", subtitle);

    doc.setTextColor(this.GREY);
    this.setFontGeomanistMedium(doc);

    if (typeof supplierLogo != "undefined") {
      var imgWidth = 70;
      var imgHeight = (supplierLogo.height * imgWidth) / supplierLogo.width;
      var middle = this.canvasWidth / 2 - imgWidth / 2;

      this.lastTopEdge += 10;
      doc.addImage(
        supplierLogo,
        "PNG",
        middle,
        this.lastTopEdge,
        imgWidth,
        imgHeight
      );

      this.lastTopEdge += imgHeight + 10;
      this.paragraph(doc, observations, 18, 20);
    } else {
      this.paragraph(doc, observations, 18, 20);
    }

    this.footer(doc, logo);
  }

  static cardPaymentsSwitchPage(type, doc, logo, title, table) {
    this.pdfBranding = type;

    this.header(doc, "Switch to Save", "secondary", title);

    this.table(doc, table.header, table.rows, 0, -1, -1, 15);

    this.footer(doc, logo);
  }
}
export default ReducerPDF;
