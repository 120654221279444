<template>
  <b-modal ref="bookConsultationModal" hide-footer hide-header @hidden="$emit('cancelled')">
    <div class="m-3">
      <h5>We're available to talk you through your savings.</h5>
      <h5>
        Book a callback for a consultation with one of our savings analysts at a time that suits
        you.
      </h5>
    </div>

    <div class="float-right mx-2 mt-3 mb-2">
      <b-button class="mr-2" @click="$emit('cancelled')">Cancel</b-button>
      <b-button
        class="gtm-bookconsuntation-button"
        variant="primary"
        href="https://calendly.com/reducer-rishi-sharma/meeting"
        target="_blank"
        >Proceed</b-button
      >
    </div>
  </b-modal>
</template>

<style lang="scss">
@import "@/styles/common.scss";
</style>

<script>
export default {
  name: "BookConsultationModal",
  props: {
    showBookConsultationModal: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    showBookConsultationModal: function(value) {
      if (value) {
        this.$refs.bookConsultationModal.show();
      } else {
        this.$refs.bookConsultationModal.hide();
      }
    }
  }
};
</script>
