<template>
  <b-modal v-model="showModal" id="branding-editor-modal" lazy hide-footer title="Branding Editor">
    <div>
      <b-tabs fill>
        <b-tab
          title="Create new branding"
          @click="switchMode('new')"
          :active="mode == 'new'"
        ></b-tab>
        <b-tab
          title="Edit existing branding"
          @click="switchMode('edit')"
          :active="mode == 'edit'"
        ></b-tab>
      </b-tabs>

      <div class="m-3">
        <b-button disabled v-if="errorMessage" pill variant="danger" class="w-100 mb-2">{{
          errorMessage
        }}</b-button>

        <b-button disabled v-if="successMessage" pill variant="primary" class="w-100 mb-2">{{
          successMessage
        }}</b-button>

        <div v-if="loadingBrandings">
          <Busy primary :text="'Loading brandings'" :size="1" />
        </div>
        <div v-else>
          <div v-if="mode == 'new' || (mode == 'edit' && brandings && brandings.length > 0)">
            <b-form-group v-if="mode == 'edit'" label="Select Branding" label-cols="5">
              <b-select v-model="branding" :options="brandings"></b-select>
            </b-form-group>

            <b-form-group label="Branding name *" label-cols="5">
              <b-form-input
                v-model="branding.brandingName"
                :placeholder="'Ex. ' + organisationName"
              />
            </b-form-group>

            <b-form-group label="Friendly name" label-cols="5">
              <b-form-input
                v-model="branding.friendlyName"
                :placeholder="'Ex. organisation-name'"
                :formatter="lowercaseformatter"
              />
            </b-form-group>

            <b-form-group label="Primary color *" label-cols="5">
              <b-input-group>
                <b-input v-model="branding.primaryColor" />
                <div class="color-example p-1">
                  <div
                    class="h-100"
                    v-bind:style="{ 'background-color': branding.primaryColor }"
                  ></div>
                </div>
              </b-input-group>
            </b-form-group>

            <b-form-group label="Secondary color *" label-cols="5">
              <b-input-group>
                <b-input v-model="branding.secondaryColor" />
                <div class="color-example p-1">
                  <div
                    class="h-100"
                    v-bind:style="{ 'background-color': branding.secondaryColor }"
                  ></div>
                </div>
              </b-input-group>
            </b-form-group>

            <b-form-group label="Gradient top color" label-cols="5">
              <b-input-group>
                <b-input v-model="branding.gradientTopColor" />
                <div class="color-example p-1">
                  <div
                    class="h-100"
                    v-bind:style="{ 'background-color': branding.gradientTopColor }"
                  ></div>
                </div>
              </b-input-group>
            </b-form-group>

            <b-form-group label="Gradient bottom color" label-cols="5">
              <b-input-group>
                <b-input v-model="branding.gradientBottomColor" />
                <div class="color-example p-1">
                  <div
                    class="h-100"
                    v-bind:style="{ 'background-color': branding.gradientBottomColor }"
                  ></div>
                </div>
              </b-input-group>
            </b-form-group>

            <b-row>
              <b-col cols="8">
                <b-form-checkbox v-model="branding.solidTakeDeal" class="my-2">
                  Solid
                  <b>Take deal</b> button
                </b-form-checkbox>

                <b-form-group label="Branding logo" label-cols="12">
                  <b-form-file
                    class="float-right d-inline"
                    v-model="logoS3FileUrl"
                    accept="image/png"
                  ></b-form-file>
                </b-form-group>
              </b-col>

              <b-col cols="4" class="d-flex justify-content-center">
                <div class="branding-logo p-2">
                  <b-img
                    v-if="branding.logoS3FileUrl"
                    id="logoS3FileUrl"
                    :src="branding.logoS3FileUrl"
                    fluid
                  ></b-img>
                  <div class="h-100 d-flex justify-content-center align-items-center" v-else>
                    <p>No logo</p>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>

          <!-- MISSING DATA FOR EDIT MODE -->
          <div v-else-if="mode == 'edit'">
            <p>No brandings could be found in the database.</p>
          </div>

          <!-- ACTION BUTTONSS -->
          <div class="mt-4">
            <b-button variant="outline-secondary" class="float-left" @click="hide()"
              >Close</b-button
            >
            <div class="float-right">
              <b-button
                v-if="mode == 'edit'"
                variant="danger"
                class="mr-2"
                @click="deleteBranding()"
                >Delete</b-button
              >

              <b-button
                variant="primary"
                v-if="mode == 'new' || (mode == 'edit' && brandings && brandings.length > 0)"
                @click="saveBranding()"
                >Save</b-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<style lang="scss">
@import "@/styles/common.scss";
#branding-editor-modal {
  .brandings-dropdown .dropdown-menu {
    max-height: 150px;
    overflow-y: auto;
  }

  .color-example {
    width: 40px;
    border: 1px solid $color-grey-lighter2;
    border-left: 0;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  .branding-logo {
    width: 130px;
    height: 130px;
    border: 1px solid $color-grey-lighter2;
  }

  .error-div {
    background-color: $color-red-light3;
    color: $color-red;
    border-radius: 50%;
  }
}
</style>

<script>
import ApiHelper from "@/helper/apihelper";
import Console from "@/console";
import FormatHelper from "@/helper/formathelper";
import Busy from "@/components/Busy";

export default {
  name: "BrandingEditor",
  props: {
    organisationName: {
      type: String,
      default: "Organisation Name"
    }
  },
  components: { Busy },
  data() {
    return {
      showModal: false,
      brandingIdSelected: null,
      mode: "new",
      errorMessage: null,
      successMessage: null,
      loadingBrandings: true,

      logoS3FileUrl: null,

      branding: {
        brandingId: null,
        brandingName: null,
        friendlyName: null,
        logoS3FileUrl: null,
        logoS3Filename: null,
        primaryColor: null,
        secondaryColor: null,
        gradientTopColor: null,
        gradientBottomColor: null,
        solidTakeDeal: false
      },

      brandings: []
    };
  },
  watch: {
    //Preview the selected logo
    logoS3FileUrl() {
      if (this.logoS3FileUrl != null) {
        this.branding.logoS3FileUrl = URL.createObjectURL(this.logoS3FileUrl);
      }
    }
  },
  created() {
    this.fetchBrandings();
  },
  methods: {
    formatDate: FormatHelper.formatDate,

    show() {
      this.showModal = true;
    },

    hide() {
      this.showModal = false;
    },

    switchMode(switchMode) {
      this.successMessage = null;
      this.errorMessage = null;

      if (switchMode == "new") {
        this.branding["brandingId"] = null;
        this.branding["brandingName"] = null;
        this.branding["friendlyName"] = null;
        this.branding["logoS3Filename"] = null;
        this.branding["logoS3FileUrl"] = null;
        this.branding["primaryColor"] = null;
        this.branding["secondaryColor"] = null;
        this.branding["gradientTopColor"] = null;
        this.branding["gradientBottomColor"] = null;
        this.branding["solidTakeDeal"] = false;
        this.logoS3FileUrl = null;
      } else {
        if (!this.branding.brandingId) {
          this.branding = Object.assign({}, this.brandings[0].value);
        }
      }

      this.mode = switchMode;
    },

    async fetchBrandings() {
      this.loadingBrandings = true;
      try {
        const client = await ApiHelper.http();
        const response = await client.get(`${ApiHelper.endPoint()}brandings`);
        if (response.data.status === "success") {
          this.brandings = [];
          for (var i = 0; i < response.data.brandings.length; i++) {
            let obj = {};
            obj.value = response.data.brandings[i];
            obj.text = response.data.brandings[i].brandingName;

            this.brandings.push(obj);
          }

          if (this.brandings.length > 0) {
            this.brandings.sort((a, b) => (a.text.toUpperCase() > b.text.toUpperCase() ? 1 : -1));
          }
        } else {
          this.errorMessage = "A problem occured in searching for data.";
        }
      } catch (error) {
        this.errorMessage = "A problem occured in searching for data.";
      } finally {
        this.loadingBrandings = false;
      }
    },

    isWhite(color) {
      if (color == "#ffffff" || color == "#fff") {
        return true;
      } else {
        return false;
      }
    },

    isDuplicateName(brandingName) {
      let isDuplicate = false;
      if (this.brandings && typeof this.brandings != "undefined" && this.brandings.length > 0) {
        this.brandings.forEach((branding) => {
          if (
            !isDuplicate &&
            branding.value.brandingName == brandingName &&
            branding.value.brandingId != this.branding.brandingId
          ) {
            isDuplicate = true;
          }
        });
      }
      return isDuplicate;
    },

    isDuplicateFriendlyName(friendlyName) {
      let isDuplicate = false;
      if (this.brandings && typeof this.brandings != "undefined" && this.brandings.length > 0) {
        this.brandings.forEach((branding) => {
          if (
            !isDuplicate &&
            branding.friendlyName &&
            typeof branding.friendlyName != "undefined" &&
            branding.value.friendlyName == friendlyName &&
            branding.value.brandingId != this.branding.brandingId
          ) {
            isDuplicate = true;
          }
        });
      }
      return isDuplicate;
    },

    async saveBranding() {
      this.successMessage = null;
      this.errorMessage = null;

      if (!this.branding.brandingName || this.branding.brandingName.length < 2) {
        this.errorMessage = "Branding name has to be at least two characters long";
      } else if (this.isDuplicateName(this.branding.brandingName)) {
        this.errorMessage = "Branding name already exists. ";
      } else if (
        this.branding.friendlyName &&
        typeof this.branding.friendlyName != undefined &&
        !FormatHelper.validFriendlyName(this.branding.friendlyName)
      ) {
        this.errorMessage =
          "Friendly name can only be letters and/or hyphens in between, lowercase and no. of characters less than 50.";
      } else if (
        this.branding.friendlyName &&
        typeof this.branding.friendlyName != undefined &&
        this.isDuplicateFriendlyName(this.branding.friendlyName)
      ) {
        this.errorMessage = "Friendly name already exists. ";
      } else if (
        !this.branding.primaryColor ||
        !FormatHelper.isValidHexColor(this.branding.primaryColor) ||
        this.isWhite(this.branding.primaryColor)
      ) {
        this.errorMessage = "Primary color should be a non-white valid color";
      } else if (
        !this.branding.secondaryColor ||
        !FormatHelper.isValidHexColor(this.branding.secondaryColor) ||
        this.isWhite(this.branding.secondaryColor)
      ) {
        this.errorMessage = "Secondary color should be a non-white valid color";
      } else if (
        (this.branding.gradientTopColor &&
          typeof this.branding.gradientTopColor != "undefined" &&
          (!FormatHelper.isValidHexColor(this.branding.gradientTopColor) ||
            this.isWhite(this.branding.gradientTopColor))) ||
        (this.branding.gradientBottomColor &&
          typeof this.branding.gradientBottomColor != "undefined" &&
          (!FormatHelper.isValidHexColor(this.branding.gradientBottomColor) ||
            this.isWhite(this.branding.gradientBottomColor)))
      ) {
        this.errorMessage = "Gradient color should be a non-white valid color";
      } else {
        var formData = new FormData();
        formData = this.createFormData();

        try {
          const client = await ApiHelper.http();
          const response = await client.post(`${ApiHelper.endPoint()}brandings`, formData);

          if (response.data.status === "success") {
            this.successMessage = "Branding has been saved";
            this.$emit("change-detected");

            this.fetchBrandings();
            this.branding = response.data.branding;
            this.switchMode("edit");
          } else {
            this.errorMessage = "There was a problem saving supplier details.";
          }
        } catch (error) {
          this.errorMessage = "There was a problem saving supplier details.";
        }
      }
    },

    createFormData() {
      const formData = new FormData();

      formData.append("branding.brandingName", this.branding.brandingName);
      if (this.branding.friendlyName) {
        formData.append("branding.friendlyName", this.branding.friendlyName.toLowerCase());
      }
      formData.append("branding.primaryColor", this.branding.primaryColor);
      formData.append("branding.secondaryColor", this.branding.secondaryColor);

      if (this.branding.brandingId) {
        formData.append("branding.brandingId", this.branding.brandingId);
      }

      if (this.branding.gradientTopColor) {
        formData.append("branding.gradientTopColor", this.branding.gradientTopColor);
      } else {
        formData.append("branding.gradientTopColor", this.branding.primaryColor);
      }

      if (this.branding.gradientBottomColor) {
        formData.append("branding.gradientBottomColor", this.branding.gradientBottomColor);
      } else {
        formData.append("branding.gradientBottomColor", this.branding.secondaryColor);
      }

      if (this.branding.solidTakeDeal)
        formData.append("branding.solidTakeDeal", this.branding.solidTakeDeal);

      //APPEND OLD LOGO
      if (this.branding.logoS3FileUrl) {
        formData.append("branding.logoS3FileUrl", this.branding.logoS3FileUrl);
      }

      //APPEND NEW LOGOS
      if (this.logoS3FileUrl) {
        formData.append("logo", this.logoS3FileUrl, this.logoS3FileUrl.name);
      }

      return formData;
    },

    async deleteBranding() {
      try {
        const client = await ApiHelper.http();
        let response = await client.delete(
          `${ApiHelper.endPoint()}brandings/${this.branding.brandingId}`
        );
        if (response.data.status === "success") {
          this.successMessage = "Branding deleted successfully";
          this.branding.brandingId = null;
          await this.fetchBrandings();
          this.switchMode("edit");
        } else {
          this.errorMessage = "There was a problem deleting the branding.";
        }
      } catch (error) {
        Console.error(error);
        this.errorMessage = "There was a problem deleting the branding.";
      }
    },

    lowercaseformatter(value) {
      return value.toLowerCase().trim();
    }
  }
};
</script>