<template>
  <b-modal
    ref="linkVideoModal"
    lazy
    title="Save Organisation"
    ok-only
    ok-title="Close"
    ok-variant="secondary"
    @hidden="$emit('cancelled')"
  >
    <div v-if="organisation" class="m-3">
      <div>
        <b-input-group prepend="Video ID">
          <b-form-input v-model="organisation.savingsVideoId" placeholder="12354678946789"></b-form-input>
        </b-input-group>
      </div>
      <div v-if="!videoOnly" class="full-update">
        <!-- this section is subject to future expansion -->
      </div>
    </div>

    <b-button class="d-inline-block float-right" variant="primary" @click="updateOrganisation">
      <span v-if="!saveBusy">Save</span>
      <Busy v-else class="p-0" :margin="0" :size="1.5" />
    </b-button>
  </b-modal>
</template>

<style lang="scss">
@import "@/styles/common.scss";
</style>

<script>
import ApiHelper from "@/helper/apihelper";
import Console from "@/console";
import Busy from "@/components/Busy";

export default {
  name: "UpdateOrganisationModal",
  props: {
    organisation: {
      type: Object,
      default: null
    },
    videoOnly: {
      type: Boolean,
      default: true
    },
    showUpdateOrgModal: Boolean
  },
  components: {
    Busy
  },
  data() {
    return {
      saveBusy: false
    };
  },
  watch: {
    showUpdateOrgModal: function (value) {
      if (value) {
        this.$refs.linkVideoModal.show();
      } else {
        this.$refs.linkVideoModal.hide();
      }
    }
  },
  methods: {
    async updateOrganisation() {
      this.saveBusy = true;
      try {
        let client = await ApiHelper.http();
        var response = await client.post(`${ApiHelper.endPoint()}organisations`, {
          organisation: this.organisation
        });

        if (response.data.status === "success") {
          //this.$emit("updateResult", response.data.organisation);
        } else {
          Console.log("Organisation API call was not successfull", response);
        }
      } catch (err) {
        Console.log("Error loading organisation data", err);
      } finally {
        this.saveBusy = false;
      }
    }
  }
};
</script>