<template>
  <div>
    <b-row
      class="mx-4 my-2 px-3 reducer-box-shadow"
      v-if="openedDeal.cnpCredit || currentDeal.cnpCredit"
    >
      <b-col lg="5" md="5" sm="5" cols="12" class="px-1 py-2 my-auto">
        <h5>CNP Credit</h5>
      </b-col>
      <b-col lg="4" md="4" sm="4" cols="6" class="px-1 py-2 my-auto">
        <h5 v-if="currentDeal.cnpCredit">{{ currentDeal.cnpCredit.toFixed(2) }}%</h5>
        <h5 v-else>--</h5>
        <p class="m-0">Current Rate</p>
      </b-col>
      <b-col lg="3" md="3" sm="3" cols="6" class="px-1 py-2 my-auto">
        <h5
          v-if="openedDeal.cnpCredit"
          v-bind:style="{ color: color }"
        >{{ openedDeal.cnpCredit.toFixed(2) }}%</h5>
        <h5 v-else v-bind:style="{ color: color }">--</h5>
        <p class="m-0">New Rate</p>
      </b-col>
    </b-row>

    <b-row
      class="mx-4 my-2 px-3 reducer-box-shadow"
      v-if="openedDeal.cnpDebit || currentDeal.cnpDebit"
    >
      <b-col lg="5" md="5" sm="5" cols="12" class="px-1 py-2 my-auto">
        <h5>CNP Debit</h5>
      </b-col>
      <b-col lg="4" md="4" sm="4" cols="6" class="px-1 py-2 my-auto">
        <h5 v-if="currentDeal.cnpDebit">{{ currentDeal.cnpDebit.toFixed(2) }}%</h5>
        <h5 v-else>--</h5>
        <p class="m-0">Current Rate</p>
      </b-col>
      <b-col lg="3" md="3" sm="3" cols="6" class="px-1 py-2 my-auto">
        <h5
          v-if="openedDeal.cnpDebit"
          v-bind:style="{ color: color }"
        >{{ openedDeal.cnpDebit.toFixed(2) }}%</h5>
        <h5 v-else v-bind:style="{ color: color }">--</h5>
        <p class="m-0">New Rate</p>
      </b-col>
    </b-row>

    <b-row
      class="mx-4 my-2 px-3 reducer-box-shadow"
      v-if="
        openedDeal.cnpCreditInternational || currentDeal.cnpCreditInternational
      "
    >
      <b-col lg="5" md="5" sm="5" cols="12" class="px-1 py-2 my-auto">
        <h5>CNP Credit International</h5>
      </b-col>
      <b-col lg="4" md="4" sm="4" cols="6" class="px-1 py-2 my-auto">
        <h5
          v-if="currentDeal.cnpCreditInternational"
        >{{ currentDeal.cnpCreditInternational.toFixed(2) }}%</h5>
        <h5 v-else>--</h5>
        <p class="m-0">Current Rate</p>
      </b-col>
      <b-col lg="3" md="3" sm="3" cols="6" class="px-1 py-2 my-auto">
        <h5
          v-if="openedDeal.cnpCreditInternational"
          v-bind:style="{ color: color }"
        >{{ openedDeal.cnpCreditInternational.toFixed(2) }}%</h5>
        <h5 v-else v-bind:style="{ color: color }">--</h5>
        <p class="m-0">New Rate</p>
      </b-col>
    </b-row>

    <b-row
      class="mx-4 my-2 px-3 reducer-box-shadow"
      v-if="
        openedDeal.cnpDebitInternational || currentDeal.cnpDebitInternational
      "
    >
      <b-col lg="5" md="5" sm="5" cols="12" class="px-1 py-2 my-auto">
        <h5>CNP Debit International</h5>
      </b-col>
      <b-col lg="4" md="4" sm="4" cols="6" class="px-1 py-2 my-auto">
        <h5
          v-if="currentDeal.cnpDebitInternational"
        >{{ currentDeal.cnpDebitInternational.toFixed(2) }}%</h5>
        <h5 v-else>--</h5>
        <p class="m-0">Current Rate</p>
      </b-col>
      <b-col lg="3" md="3" sm="3" cols="6" class="px-1 py-2 my-auto">
        <h5
          v-if="openedDeal.cnpDebitInternational"
          v-bind:style="{ color: color }"
        >{{ openedDeal.cnpDebitInternational.toFixed(2) }}%</h5>
        <h5 v-else v-bind:style="{ color: color }">--</h5>
        <p class="m-0">New Rate</p>
      </b-col>
    </b-row>

    <b-row
      class="mx-4 my-2 px-3 reducer-box-shadow"
      v-if="openedDeal.creditPersonal || currentDeal.creditPersonal"
    >
      <b-col lg="5" md="5" sm="5" cols="12" class="px-1 py-2 my-auto">
        <h5>Personal Credit</h5>
      </b-col>
      <b-col lg="4" md="4" sm="4" cols="6" class="px-1 py-2 my-auto">
        <h5 v-if="currentDeal.creditPersonal">{{ currentDeal.creditPersonal.toFixed(2) }}%</h5>
        <h5 v-else>--</h5>
        <p class="m-0">Current Rate</p>
      </b-col>
      <b-col lg="3" md="3" sm="3" cols="6" class="px-1 py-2 my-auto">
        <h5
          v-if="openedDeal.creditPersonal"
          v-bind:style="{ color: color }"
        >{{ openedDeal.creditPersonal.toFixed(2) }}%</h5>
        <h5 v-else v-bind:style="{ color: color }">--</h5>
        <p class="m-0">New Rate</p>
      </b-col>
    </b-row>

    <b-row
      class="mx-4 my-2 px-3 reducer-box-shadow"
      v-if="openedDeal.debitPersonal || currentDeal.debitPersonal"
    >
      <b-col lg="5" md="5" sm="5" cols="12" class="px-1 py-2 my-auto">
        <h5>Personal Debit</h5>
      </b-col>
      <b-col lg="4" md="4" sm="4" cols="6" class="px-1 py-2 my-auto">
        <h5 v-if="currentDeal.debitPersonal">{{ currentDeal.debitPersonal.toFixed(2) }}%</h5>
        <h5 v-else>--</h5>
        <p class="m-0">Current Rate</p>
      </b-col>
      <b-col lg="3" md="3" sm="3" cols="6" class="px-1 py-2 my-auto">
        <h5
          v-if="openedDeal.debitPersonal"
          v-bind:style="{ color: color }"
        >{{ openedDeal.debitPersonal.toFixed(2) }}%</h5>
        <h5 v-else v-bind:style="{ color: color }">--</h5>
        <p class="m-0">New Rate</p>
      </b-col>
    </b-row>

    <b-row
      class="mx-4 my-2 px-3 reducer-box-shadow"
      v-if="openedDeal.creditBusiness || currentDeal.creditBusiness"
    >
      <b-col lg="5" md="5" sm="5" cols="12" class="px-1 py-2 my-auto">
        <h5>Business Credit</h5>
      </b-col>
      <b-col lg="4" md="4" sm="4" cols="6" class="px-1 py-2 my-auto">
        <h5 v-if="currentDeal.creditBusiness">{{ currentDeal.creditBusiness.toFixed(2) }}%</h5>
        <h5 v-else>--</h5>
        <p class="m-0">Current Rate</p>
      </b-col>
      <b-col lg="3" md="3" sm="3" cols="6" class="px-1 py-2 my-auto">
        <h5
          v-if="openedDeal.creditBusiness"
          v-bind:style="{ color: color }"
        >{{ openedDeal.creditBusiness.toFixed(2) }}%</h5>
        <h5 v-else v-bind:style="{ color: color }">--</h5>
        <p class="m-0">New Rate</p>
      </b-col>
    </b-row>

    <b-row
      class="mx-4 my-2 px-3 reducer-box-shadow"
      v-if="openedDeal.debitBusiness || currentDeal.debitBusiness"
    >
      <b-col lg="5" md="5" sm="5" cols="12" class="px-1 py-2 my-auto">
        <h5>Business Debit</h5>
      </b-col>
      <b-col lg="4" md="4" sm="4" cols="6" class="px-1 py-2 my-auto">
        <h5 v-if="currentDeal.debitBusiness">{{ currentDeal.debitBusiness.toFixed(2) }}%</h5>
        <h5 v-else>--</h5>
        <p class="m-0">Current Rate</p>
      </b-col>
      <b-col lg="3" md="3" sm="3" cols="6" class="px-1 py-2 my-auto">
        <h5
          v-if="openedDeal.debitBusiness"
          v-bind:style="{ color: color }"
        >{{ openedDeal.debitBusiness.toFixed(2) }}%</h5>
        <h5 v-else v-bind:style="{ color: color }">--</h5>
        <p class="m-0">New Rate</p>
      </b-col>
    </b-row>

    <b-row
      class="mx-4 my-2 px-3 reducer-box-shadow"
      v-if="openedDeal.creditInternational || currentDeal.creditInternational"
    >
      <b-col lg="5" md="5" sm="5" cols="12" class="px-1 py-2 my-auto">
        <h5>International Credit</h5>
      </b-col>
      <b-col lg="4" md="4" sm="4" cols="6" class="px-1 py-2 my-auto">
        <h5 v-if="currentDeal.creditInternational">{{ currentDeal.creditInternational.toFixed(2) }}%</h5>
        <h5 v-else>--</h5>
        <p class="m-0">Current Rate</p>
      </b-col>
      <b-col lg="3" md="3" sm="3" cols="6" class="px-1 py-2 my-auto">
        <h5
          v-if="openedDeal.creditInternational"
          v-bind:style="{ color: color }"
        >{{ openedDeal.creditInternational.toFixed(2) }}%</h5>
        <h5 v-else v-bind:style="{ color: color }">--</h5>
        <p class="m-0">New Rate</p>
      </b-col>
    </b-row>

    <b-row
      class="mx-4 my-2 px-3 reducer-box-shadow"
      v-if="openedDeal.debitInternational || currentDeal.debitInternational"
    >
      <b-col lg="5" md="5" sm="5" cols="12" class="px-1 py-2 my-auto">
        <h5>International Debit</h5>
      </b-col>
      <b-col lg="4" md="4" sm="4" cols="6" class="px-1 py-2 my-auto">
        <h5 v-if="currentDeal.debitInternational">{{ currentDeal.debitInternational.toFixed(2) }}%</h5>
        <h5 v-else>--</h5>
        <p class="m-0">Current Rate</p>
      </b-col>
      <b-col lg="3" md="3" sm="3" cols="6" class="px-1 py-2 my-auto">
        <h5
          v-if="openedDeal.debitInternational"
          v-bind:style="{ color: color }"
        >{{ openedDeal.debitInternational.toFixed(2) }}%</h5>
        <h5 v-else v-bind:style="{ color: color }">--</h5>
        <p class="m-0">New Rate</p>
      </b-col>
    </b-row>

    <b-row
      class="mx-4 my-2 px-3 reducer-box-shadow"
      v-if="openedDeal.mcPersonalCredit || currentDeal.mcPersonalCredit"
    >
      <b-col lg="5" md="5" sm="5" cols="12" class="px-1 py-2 my-auto">
        <h5>MC Personal Credit</h5>
      </b-col>
      <b-col lg="4" md="4" sm="4" cols="6" class="px-1 py-2 my-auto">
        <h5 v-if="currentDeal.mcPersonalCredit">{{ currentDeal.mcPersonalCredit.toFixed(2) }}%</h5>
        <h5 v-else>--</h5>
        <p class="m-0">Current Rate</p>
      </b-col>
      <b-col lg="3" md="3" sm="3" cols="6" class="px-1 py-2 my-auto">
        <h5
          v-if="openedDeal.mcPersonalCredit"
          v-bind:style="{ color: color }"
        >{{ openedDeal.mcPersonalCredit.toFixed(2) }}%</h5>
        <h5 v-else v-bind:style="{ color: color }">--</h5>
        <p class="m-0">New Rate</p>
      </b-col>
    </b-row>

    <b-row
      class="mx-4 my-2 px-3 reducer-box-shadow"
      v-if="openedDeal.mcPersonalDebit || currentDeal.mcPersonalDebit"
    >
      <b-col lg="5" md="5" sm="5" cols="12" class="px-1 py-2 my-auto">
        <h5>MC Personal Debit</h5>
      </b-col>
      <b-col lg="4" md="4" sm="4" cols="6" class="px-1 py-2 my-auto">
        <h5 v-if="currentDeal.mcPersonalDebit">{{ currentDeal.mcPersonalDebit.toFixed(2) }}%</h5>
        <h5 v-else>--</h5>
        <p class="m-0">Current Rate</p>
      </b-col>
      <b-col lg="3" md="3" sm="3" cols="6" class="px-1 py-2 my-auto">
        <h5
          v-if="openedDeal.mcPersonalDebit"
          v-bind:style="{ color: color }"
        >{{ openedDeal.mcPersonalDebit.toFixed(2) }}%</h5>
        <h5 v-else v-bind:style="{ color: color }">--</h5>
        <p class="m-0">New Rate</p>
      </b-col>
    </b-row>

    <b-row
      class="mx-4 my-2 px-3 reducer-box-shadow"
      v-if="openedDeal.mcBusinessCredit || currentDeal.mcBusinessCredit"
    >
      <b-col lg="5" md="5" sm="5" cols="12" class="px-1 py-2 my-auto">
        <h5>MC Business Credit</h5>
      </b-col>
      <b-col lg="4" md="4" sm="4" cols="6" class="px-1 py-2 my-auto">
        <h5 v-if="currentDeal.mcBusinessCredit">{{ currentDeal.mcBusinessCredit.toFixed(2) }}%</h5>
        <h5 v-else>--</h5>
        <p class="m-0">Current Rate</p>
      </b-col>
      <b-col lg="3" md="3" sm="3" cols="6" class="px-1 py-2 my-auto">
        <h5
          v-if="openedDeal.mcBusinessCredit"
          v-bind:style="{ color: color }"
        >{{ openedDeal.mcBusinessCredit.toFixed(2) }}%</h5>
        <h5 v-else v-bind:style="{ color: color }">--</h5>
        <p class="m-0">New Rate</p>
      </b-col>
    </b-row>

    <b-row
      class="mx-4 my-2 px-3 reducer-box-shadow"
      v-if="openedDeal.mcBusinessDebit || currentDeal.mcBusinessDebit"
    >
      <b-col lg="5" md="5" sm="5" cols="12" class="px-1 py-2 my-auto">
        <h5>MC Business Debit</h5>
      </b-col>
      <b-col lg="4" md="4" sm="4" cols="6" class="px-1 py-2 my-auto">
        <h5 v-if="currentDeal.mcBusinessDebit">{{ currentDeal.mcBusinessDebit.toFixed(2) }}%</h5>
        <h5 v-else>--</h5>
        <p class="m-0">Current Rate</p>
      </b-col>
      <b-col lg="3" md="3" sm="3" cols="6" class="px-1 py-2 my-auto">
        <h5
          v-if="openedDeal.mcBusinessDebit"
          v-bind:style="{ color: color }"
        >{{ openedDeal.mcBusinessDebit.toFixed(2) }}%</h5>
        <h5 v-else v-bind:style="{ color: color }">--</h5>
        <p class="m-0">New Rate</p>
      </b-col>
    </b-row>

    <b-row
      class="mx-4 my-2 px-3 reducer-box-shadow"
      v-if="openedDeal.mcPremiumCredit || currentDeal.mcPremiumCredit"
    >
      <b-col lg="5" md="5" sm="5" cols="12" class="px-1 py-2 my-auto">
        <h5>MC Premium Credit</h5>
      </b-col>
      <b-col lg="4" md="4" sm="4" cols="6" class="px-1 py-2 my-auto">
        <h5 v-if="currentDeal.mcPremiumCredit">{{ currentDeal.mcPremiumCredit.toFixed(2) }}%</h5>
        <h5 v-else>--</h5>
        <p class="m-0">Current Rate</p>
      </b-col>
      <b-col lg="3" md="3" sm="3" cols="6" class="px-1 py-2 my-auto">
        <h5
          v-if="openedDeal.mcPremiumCredit"
          v-bind:style="{ color: color }"
        >{{ openedDeal.mcPremiumCredit.toFixed(2) }}%</h5>
        <h5 v-else v-bind:style="{ color: color }">--</h5>
        <p class="m-0">New Rate</p>
      </b-col>
    </b-row>
  </div>
</template>

<style lang="scss">
@import "@/styles/common.scss";
</style>

<script>
import FormatHelper from "@/helper/formathelper";

export default {
  name: "CardPaymentsRates",
  props: {
    currentDeal: {
      type: Object,
      default: null
    },
    openedDeal: {
      type: Object,
      default: null
    },
    color: {
      type: String,
      default: "#F40C8F"
    }
  },
  methods: {
    formatNumberToDisplay: FormatHelper.formatNumberToDisplay,
    formatMonthsToYears: FormatHelper.formatMonthsToYears
  }
};
</script>