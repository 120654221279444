<template>
  <font-awesome-icon
    v-b-popover.hover="supplyTypesDisplay(supplyType)"
    v-bind:style="{ color: color }"
    v-if="supplyType == 'TELECOMS'"
    :icon="['fal', 'router']"
    :id="supplyType + '_icon'"
  />
  <font-awesome-icon
    v-b-popover.hover="supplyTypesDisplay(supplyType)"
    v-bind:style="{ color: color }"
    v-else-if="supplyType == 'WASTE'"
    :icon="['fal', 'recycle']"
    :id="supplyType + '_icon'"
  />
  <font-awesome-icon
    v-b-popover.hover="supplyTypesDisplay(supplyType)"
    v-bind:style="{ color: color }"
    v-else-if="supplyType == 'FUEL'"
    :icon="['fal', 'gas-pump']"
    :id="supplyType + '_icon'"
  />
  <font-awesome-icon
    v-b-popover.hover="supplyTypesDisplay(supplyType)"
    v-bind:style="{ color: color }"
    v-else-if="supplyType == 'ELECTRICITY'"
    :icon="['fal', 'lightbulb-on']"
    :id="supplyType + '_icon'"
  />
  <font-awesome-icon
    v-b-popover.hover="supplyTypesDisplay(supplyType)"
    v-bind:style="{ color: color }"
    v-else-if="supplyType == 'GAS'"
    :icon="['fal', 'burn']"
    :id="supplyType + '_icon'"
  />
  <font-awesome-icon
    v-b-popover.hover="supplyTypesDisplay(supplyType)"
    v-bind:style="{ color: color }"
    v-else-if="supplyType == 'WATER'"
    :icon="['fal', 'tint']"
    :id="supplyType + '_icon'"
  />
  <font-awesome-icon
    v-b-popover.hover="supplyTypesDisplay(supplyType)"
    v-bind:style="{ color: color }"
    v-else-if="supplyType == 'CARD_PAYMENTS'"
    :icon="['fal', 'credit-card']"
    :id="supplyType + '_icon'"
  />
  <font-awesome-icon
    v-b-popover.hover="supplyTypesDisplay(supplyType)"
    v-bind:style="{ color: color }"
    v-else-if="supplyType == 'MOBILE'"
    :icon="[light ? 'fal' : 'fas', 'mobile-alt']"
    :id="supplyType + '_icon'"
  />
  <font-awesome-icon
    v-b-popover.hover="'Other'"
    v-bind:style="{ color: color }"
    v-else-if="supplyType == 'CUSTOM'"
    :icon="['fas', 'cubes']"
    :id="supplyType + '_icon'"
  />
  <font-awesome-icon
    v-b-popover.hover="supplyTypesDisplay(supplyType)"
    v-bind:style="{ color: color }"
    v-else-if="supplyType"
    :icon="['fas', 'hand-spock']"
    :id="supplyType + '_icon'"
  />
</template>

<script>
import SupplyTypes from "@/helper/supplytypehelper";

export default {
  name: "SavingsIcon",
  props: {
    supplyType: String,
    color: {
      type: String,
      default: "#999999"
    },
    light: Boolean
  },
  methods: {
    supplyTypesDisplay: SupplyTypes.toDisplay
  }
};
</script>

<style lang="scss">
@import "@/styles/common.scss";
</style>