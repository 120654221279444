class StaticRates {
  // Climate Change Levy rate, last updated 2022
  static cclRates = {
    ELECTRICITY: 0.775,
    GAS: 0.568
  };

  // Max uplifts used for automated pricing
  static maxUplifts = {
    ELECTRICITY: {
      acquisition: {
        "British Gas": 2,
        "British Gas Lite": 1.5,
        SmartestEnergy: {
          smartfix: 2,
          smartpay: 1
        },
        SSE: 2.5,
        "Scottish Power": 2,
        Total: 3,
        Opus: 2,
        "Corona Energy": 3,
        EDF: 1.5
      },
      renewal: {
        "British Gas": 2,
        "British Gas Lite": 1.5,
        SmartestEnergy: {
          smartfix: 2,
          smartpay: 1
        },
        SSE: 2.5,
        "Scottish Power": 2,
        Total: 3,
        Opus: 2,
        "Corona Energy": 3,
        EDF: 1.5
      }
    },
    GAS: {
      acquisition: {
        "British Gas": 1.5,
        "British Gas Lite": 1,
        SmartestEnergy: {
          smartfix: 1.5,
          smartpay: 1
        },
        SSE: 2.5,
        "Scottish Power": 1.5,
        Total: 2,
        Opus: 1.5,
        "Corona Energy": 2,
        EDF: 1.5
      },
      renewal: {
        "British Gas": 1.5,
        "British Gas Lite": 1,
        SmartestEnergy: {
          smartfix: 1.5,
          smartpay: 1
        },
        SSE: 2.5,
        "Scottish Power": 1.5,
        Total: 2,
        Opus: 1,
        "Corona Energy": 2,
        EDF: 1.5
      }
    }
  };
}
export default StaticRates;
