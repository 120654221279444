<template>
  <div
    id="savings-report-video-opened-id"
    v-if="videoId && !minimized"
    v-bind:style="{ width: width + 'px' }"
    class="reducer-box-shadow m-0"
  >
    <font-awesome-icon
      class="float-right minimize"
      @click="toggleMinimize"
      :icon="['far', 'minus-circle']"
    />

    <b-embed
      v-if="!minimized"
      type="iframe"
      :src="'https://www.loom.com/embed/' + videoId"
      controls
      aspect="16by9"
      allowfullscreen
    ></b-embed>
    <p id="videoSubTitle" class="my-2 mx-0">{{ message }}</p>
  </div>

  <div
    id="savings-report-video-closed-id"
    class="d-flex align-items-center justify-content-center"
    v-else-if="videoId && minimized"
    @click="toggleMinimize"
  >
    <font-awesome-icon class="minimize" :icon="['fas', 'video']" />
  </div>
</template>

<style lang="scss">
@import "@/styles/common.scss";
#videoSubTitle {
  font-size: 15px;
  font-weight: 300;
  text-align: center;
  font-family: "poppins";
  color: white;
}
#savings-report-video-opened-id {
  position: fixed;

  top: 60px;
  right: 0%;

  background-color: $color-font-para;
  border-radius: 5px;

  .minimize {
    margin: 5px;
    color: white;
    cursor: pointer;
  }
}

//Any narrower than 1780 and the video overlaps content
@media only screen and (max-width: 1780px) {
  #savings-report-video-opened-id {
    position: static;
    width: 700px !important;
    .minimize {
      display: none;
    }
  }
  #savings-report-video-closed-id {
    display: none;
  }
}

#savings-report-video-closed-id {
  z-index: 900;
  top: auto !important;
  bottom: 0 !important;
  left: 3% !important;
  margin-bottom: 28px;
  position: fixed;

  height: 45px;
  width: 45px;
  border-radius: 50%;

  box-shadow: 2px 2px 6px rgba($color: $color-font-para, $alpha: 0.5);
  background-color: $color-font-para;
  cursor: pointer;
  padding: 0.2rem;

  .minimize {
    color: white;
    cursor: pointer;
    font-size: 1.4rem;
  }

  //SIDE-NAV WHEN THE SCREEN IS SMALL
  @media only screen and (max-width: 1499px), (max-height: 799px) {
    margin-left: 58px;
  }

  @media only screen and (max-width: 767px) {
    margin-bottom: 28px;
    margin-left: 60px;
    left: 4% !important;
  }
}
</style>

<script>
export default {
  name: "SavingsReportVideo",
  props: {
    videoId: {
      type: String,
      default: null
    },
    message: {
      type: String,
      default: "Watch your personal savings video"
    }
  },
  data() {
    return {
      scrollPosition: null,
      minimized: false,
      width: 330
    };
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.updateScroll);
  },
  methods: {
    updateScroll() {
      let element = document.getElementById("savings-report-video-opened-id");
      if (element) {
        if (window.scrollY < 61) {
          element.style.top = 60 - window.scrollY + "px";
        } else {
          element.style.top = "0px";
        }
      }
    },
    toggleMinimize() {
      this.minimized = !this.minimized;
    }
  }
};
</script>
