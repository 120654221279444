<template>
  <div class="basic-details">
    <!-- Supply details -->
    <div id="supply-details" class="mb-5">
      <b-row
        class="supply-details-row"
        v-if="
          supply.address &&
          (supply.address.county ||
            supply.address.city ||
            supply.address.postcode ||
            supply.address.address1 ||
            supply.address.address2 ||
            supply.address.address3)
        "
      >
        <b-col cols="6" md="8" class="col-left">Address</b-col>
        <b-col cols="6" md="4" class="col-right title-parts">
          <div class="d-inline-block">
            <span>
              <b class="basic-style" v-if="supply.address.address1">{{
                supply.address.address1
              }}</b>
              <b v-if="supply.address.address2">, </b>
              <b class="basic-style" v-if="supply.address.address2">{{
                supply.address.address2
              }}</b>
              <b v-if="supply.address.address3">, </b>
              <b class="basic-style" v-if="supply.address.address3">{{
                supply.address.address3
              }}</b>
              <b v-if="supply.address.city">, </b>
              <b class="basic-style" v-if="supply.address.city">{{ supply.address.city }}</b>
              <b v-if="supply.address.county">, </b>
              <b class="basic-style" v-if="supply.address.county">{{ supply.address.county }}</b>
              <b v-if="supply.address.postcode">, </b>
              <b class="basic-style" v-if="supply.address.postcode">{{
                supply.address.postcode.replace(/(\r\n|\n|\r)/gm, "")
              }}</b>
            </span>
          </div>
        </b-col>
      </b-row>

      <b-row class="supply-details-row" align-v="center" v-if="supply && supply.meterNumber">
        <b-col cols="6" md="8" class="col-left">Meter Number</b-col>
        <b-col cols="6" md="4" class="col-right"
          ><div>{{ supply.meterNumber }}</div></b-col
        >
      </b-row>
      <b-row class="supply-details-row" align-v="center" v-if="supply && supply.consumption">
        <b-col cols="6" md="8" class="col-left">Consumption</b-col>
        <b-col cols="6" md="4" class="col-right"
          ><div>
            {{ supply.consumption }}<span v-if="supply.type == 'WATER'"> m<sup>3</sup></span>
          </div></b-col
        >
      </b-row>

      <b-row class="supply-details-row" align-v="center" v-if="supply && supply.dayConsumption">
        <b-col cols="6" md="8" class="col-left">Consumption (Day)</b-col>
        <b-col cols="6" md="4" class="col-right"
          ><div>{{ supply.dayConsumption + " kWh" }}</div></b-col
        >
      </b-row>

      <b-row class="supply-details-row" align-v="center" v-if="supply && supply.nightConsumption">
        <b-col cols="6" md="8" class="col-left">Consumption (Night)</b-col>
        <b-col cols="6" md="4" class="col-right"
          ><div>{{ supply.nightConsumption + " kWh" }}</div></b-col
        >
      </b-row>

      <b-row class="supply-details-row" align-v="center" v-if="supply && supply.ewConsumption">
        <b-col cols="6" md="8" class="col-left">Consumption (EW)</b-col>
        <b-col cols="6" md="4" class="col-right"
          ><div>{{ supply.ewConsumption + " kWh" }}</div></b-col
        >
      </b-row>

      <b-row class="supply-details-row" align-v="center" v-if="supply && supply.winterConsumption">
        <b-col cols="6" md="8" class="col-left">Consumption (Winter)</b-col>
        <b-col cols="6" md="4" class="col-right"
          ><div>{{ supply.winterConsumption + " kWh" }}</div></b-col
        >
      </b-row>
    </div>

    <!-- Deal details -->

    <b-row class="badge-row mb-1">
      <b-col cols="4" class="col-left"></b-col>
      <b-col cols="4" class="col-center">
        <b-badge class="col-badge d-none d-md-block py-1 px-2 mr-lg-3">Current Contract</b-badge>
        <b-badge class="col-badge d-block d-md-none p-1">Current</b-badge>
      </b-col>
      <b-col cols="4" class="col-right">
        <b-badge class="col-badge d-none d-md-block py-1 px-2 mr-lg-3" id="offer-badge"
          >{{ deal.recommended ? "Recommended" : "Your" }} Offer</b-badge
        >
        <b-badge class="col-badge d-block d-md-none p-1" id="offer-badge">Offer</b-badge>
      </b-col>
    </b-row>
    <b-row class="basic-details-row" align-v="center">
      <b-col cols="4" class="col-left">Supplier</b-col>
      <b-col cols="4" class="col-center"
        ><img
          v-if="
            currentDeal &&
            suppliers &&
            !isEmpty(suppliers[currentDeal.supplierId]) &&
            suppliers[currentDeal.supplierId].logos3url
          "
          class="basic-details-logo"
          :src="suppliers[currentDeal.supplierId].logos3url"
        />
        <div v-else>{{ currentDeal.supplierName }}</div></b-col
      >
      <b-col cols="4" class="col-right ml-auto"
        ><img
          v-if="
            deal &&
            suppliers &&
            !isEmpty(suppliers[deal.supplierId]) &&
            suppliers[deal.supplierId].logos3url
          "
          class="basic-details-logo"
          :src="suppliers[deal.supplierId].logos3url"
        />
        <div v-else>{{ deal.supplierName }}</div></b-col
      >
    </b-row>
    <b-row
      class="basic-details-row"
      align-v="center"
      v-if="currentDeal.contractExpiry && currentDeal.outOfContract != 1"
    >
      <b-col cols="4" class="col-left">Contract end</b-col>
      <b-col cols="4" class="col-center">{{ formatDate(currentDeal.contractExpiry) }}</b-col>
      <b-col cols="4" class="col-right ml-auto"></b-col>
    </b-row>
    <b-row class="basic-details-row" align-v="center" v-if="deal.contractStartDate">
      <b-col cols="4" class="col-left">Contract start</b-col>
      <b-col cols="4" class="col-center"></b-col>
      <b-col cols="4" class="col-right ml-auto">{{ formatDate(deal.contractStartDate) }}</b-col>
    </b-row>
    <b-row class="basic-details-row" align-v="center" v-if="deal.contractTerm">
      <b-col cols="4" class="col-left">Contract term</b-col>
      <b-col cols="4" class="col-center"></b-col>
      <b-col cols="4" class="col-right ml-auto">{{ formatMonthsToYears(deal.contractTerm) }}</b-col>
    </b-row>

    <b-row class="basic-details-row" align-v="center" v-if="deal.dayUnitRate">
      <b-col cols="4" class="col-left">Day unit rate</b-col>
      <b-col v-if="currentDeal.projectedDayUnitRate" cols="4" class="col-center">
        {{ formatTwoDecimals(currentDeal.projectedDayUnitRate) }} p/kWh
        <div>
          <b-badge variant="info" class="renewable-badge">{{ projectedCostsLabel }} Rate</b-badge>
        </div></b-col
      >
      <b-col v-else cols="4" class="col-center">
        {{ formatTwoDecimals(currentDeal.dayUnitRate) }} p/kWh
      </b-col>
      <b-col cols="4" class="col-right ml-auto"
        >{{ formatTwoDecimals(deal.dayUnitRate) }} p/kWh</b-col
      >
    </b-row>

    <b-row class="basic-details-row" align-v="center" v-if="deal.nightUnitRate">
      <b-col cols="4" class="col-left">Night unit rate</b-col>
      <b-col v-if="currentDeal.projectedNightUnitRate" cols="4" class="col-center">
        {{ formatTwoDecimals(currentDeal.projectedNightUnitRate) }} p/kWh
        <div>
          <b-badge variant="info" class="renewable-badge">{{ projectedCostsLabel }} Rate</b-badge>
        </div></b-col
      >
      <b-col v-else cols="4" class="col-center">
        {{ formatTwoDecimals(currentDeal.nightUnitRate) }} p/kWh
      </b-col>
      <b-col cols="4" class="col-right ml-auto"
        >{{ formatTwoDecimals(deal.nightUnitRate) }} p/kWh</b-col
      >
    </b-row>

    <b-row class="basic-details-row" align-v="center" v-if="deal.ewUnitRate">
      <b-col cols="4" class="col-left">EW unit rate</b-col>
      <b-col v-if="currentDeal.projectedEwUnitRate" cols="4" class="col-center">
        {{ formatTwoDecimals(currentDeal.projectedEwUnitRate) }} p/kWh
        <div>
          <b-badge variant="info" class="renewable-badge">{{ projectedCostsLabel }} Rate</b-badge>
        </div></b-col
      >
      <b-col v-else cols="4" class="col-center">
        {{ formatTwoDecimals(currentDeal.ewUnitRate) }} p/kWh
      </b-col>
      <b-col cols="4" class="col-right ml-auto"
        >{{ formatTwoDecimals(deal.ewUnitRate) }} p/kWh</b-col
      >
    </b-row>

    <b-row class="basic-details-row" align-v="center" v-if="deal.winterUnitRate">
      <b-col cols="4" class="col-left">Winter unit rate</b-col>
      <b-col v-if="currentDeal.projectedWinterUnitRate" cols="4" class="col-center">
        {{ formatTwoDecimals(currentDeal.projectedWinterUnitRate) }} p/kWh
        <div>
          <b-badge variant="info" class="renewable-badge">{{ projectedCostsLabel }} Rate</b-badge>
        </div></b-col
      >
      <b-col v-else cols="4" class="col-center">
        {{ formatTwoDecimals(currentDeal.winterUnitRate) }} p/kWh
      </b-col>
      <b-col cols="4" class="col-right ml-auto"
        >{{ formatTwoDecimals(deal.winterUnitRate) }} p/kWh</b-col
      >
    </b-row>

    <b-row class="basic-details-row" align-v="center" v-if="supply.paysClimateLevy">
      <b-col cols="4" class="col-left">Climate Change Levy Rate</b-col>
      <b-col cols="4" class="col-center">{{ ccl[supply.type] }}p</b-col>
      <b-col cols="4" class="col-right ml-auto">{{ ccl[supply.type] }}p</b-col>
    </b-row>

    <b-row class="basic-details-row" align-v="center" v-if="deal.standingCharge">
      <b-col cols="4" class="col-left">Standing Charge</b-col>
      <b-col v-if="currentDeal.projectedStandingCharge" cols="4" class="col-center">
        {{ formatTwoDecimals(currentDeal.projectedStandingCharge) }} p/day
        <div>
          <b-badge variant="info" class="renewable-badge">{{ projectedCostsLabel }} Charge</b-badge>
        </div></b-col
      >
      <b-col v-else cols="4" class="col-center">
        {{ formatTwoDecimals(currentDeal.standingCharge) }} p/day
      </b-col>
      <b-col cols="4" class="col-right ml-auto"
        >{{ formatTwoDecimals(deal.standingCharge) }} p/day</b-col
      >
    </b-row>

    <b-row
      class="basic-details-row"
      align-v="center"
      v-if="deal.monthlyCost && deal.type == 'TELECOMS'"
    >
      <b-col cols="4" class="col-left">Monthly cost</b-col>
      <b-col cols="4" class="col-center">{{
        currentDeal.monthlyCost
          ? $currencySymbol + formatNumberToDisplay(currentDeal.monthlyCost)
          : ""
      }}</b-col>
      <b-col cols="4" class="col-right ml-auto">
        {{ $currencySymbol }}{{ formatNumberToDisplay(deal.monthlyCost) }}
      </b-col>
    </b-row>

    <b-row class="basic-details-row" align-v="center" v-if="deal.careDutyCharge">
      <b-col cols="4" class="col-left">Duty of Care Charge</b-col>
      <b-col cols="4" class="col-center">{{
        currentDeal.careDutyCharge
          ? $currencySymbol + formatNumberToDisplay(currentDeal.careDutyCharge)
          : ""
      }}</b-col>
      <b-col cols="4" class="col-right ml-auto">
        {{ $currencySymbol }}{{ formatNumberToDisplay(deal.careDutyCharge) }}
      </b-col>
    </b-row>

    <b-row class="basic-details-row" align-v="center" v-if="deal.combinedLineRentalVoip">
      <b-col cols="4" class="col-left">Line Rental &amp; VoIP Charge</b-col>
      <b-col cols="4" class="col-center">{{
        currentDeal.combinedLineRentalVoip
          ? $currencySymbol + formatNumberToDisplay(currentDeal.combinedLineRentalVoip) + " /month"
          : ""
      }}</b-col>
      <b-col cols="4" class="col-right ml-auto">
        {{ $currencySymbol }}{{ formatNumberToDisplay(deal.combinedLineRentalVoip) }}/month
      </b-col>
    </b-row>

    <b-row class="basic-details-row" align-v="center" v-if="deal.setupFee">
      <b-col cols="4" class="col-left">Setup fee</b-col>
      <b-col cols="4" class="col-center"></b-col>
      <b-col cols="4" class="col-right ml-auto">
        {{ $currencySymbol }}{{ formatTwoDecimals(deal.setupFee) }}
      </b-col>
    </b-row>

    <b-row
      class="basic-details-row"
      align-v="center"
      v-if="deal.callingPackages || currentDeal.callingPackages"
    >
      <b-col cols="4" class="col-left">Calling packages</b-col>
      <b-col cols="4" class="col-center"
        ><b class="description-style">{{ currentDeal.callingPackages }}</b></b-col
      >
      <b-col cols="4" class="col-right ml-auto">
        <b class="description-style">{{ deal.callingPackages }}</b>
      </b-col>
    </b-row>

    <b-row class="basic-details-row" align-v="center" v-if="deal.annualCost">
      <b-col cols="4" class="col-left">Annual cost</b-col>
      <b-col v-if="currentDeal.projectedAnnualCost" cols="4" class="col-center">
        {{ $currencySymbol }}{{ formatNumberToDisplay(currentDeal.projectedAnnualCost) }}
        <div>
          <b-badge variant="info" class="renewable-badge">{{ projectedCostsLabel }} Price</b-badge>
        </div></b-col
      >
      <b-col v-else cols="4" class="col-center">
        {{ $currencySymbol }}{{ formatNumberToDisplay(currentDeal.annualCost) }}
      </b-col>
      <b-col cols="4" class="col-right ml-auto"
        >{{ $currencySymbol }}{{ formatNumberToDisplay(deal.annualCost) }}
      </b-col>
    </b-row>

    <b-row class="basic-details-row annual-savings-row" align-v="center" v-if="deal.saving">
      <b-col cols="4" class="col-left"><b>Annual savings</b></b-col>
      <b-col cols="4" class="col-center"></b-col>
      <b-col cols="4" class="col-right ml-auto"
        ><div id="savings-cell">
          <b>{{ $currencySymbol }}{{ formatNumberToDisplay(deal.saving) }}</b>
        </div></b-col
      >
    </b-row>

    <p class="border-0 mx-2 my-3 text-center" v-if="deal.description">{{ deal.description }}</p>
  </div>
</template>

<style lang="scss">
@import "@/styles/common.scss";

.basic-details {
  width: 100%;
  margin: 0 1rem 2rem 0 !important;

  .col-center,
  .col-badge {
    text-align: center;
    @media screen and (min-width: 768px) {
      text-align: left;
    }
  }

  .basic-details-row,
  .supply-details-row,
  .badge-row {
    margin: 0;
  }

  .basic-details-row {
    word-wrap: break-word;

    padding-top: 10px;
    padding-bottom: 10px;
    color: $color-font-headings;
    &:not(:last-of-type) {
      border-bottom: solid 1px $color-grey-lighter4;
    }

    .col-right {
      text-align: center;
      @media screen and (min-width: 768px) {
        text-align: left;
      }
    }
  }

  .supply-details-row {
    word-wrap: break-word;

    padding-top: 10px;
    padding-bottom: 10px;
    color: $color-font-headings;
    &:not(:last-of-type) {
      border-bottom: solid 1px $color-grey-lighter4;
    }
    .col-right {
      text-align: right;
      @media screen and (min-width: 768px) {
        text-align: left;
      }
    }
  }

  .col-left {
    font-weight: 600;
  }

  #offer-badge {
    background-color: $color-pink-main;
  }

  .basic-details-logo {
    max-width: 80%;
    max-height: 40px;
  }

  & > p {
    width: 100%;
    display: inline-block;
    margin: 0 0.5rem 0 0.5rem;
    padding: 8px 10px 8px 10px;
    // Don't apply border to the last p
    &:not(:last-of-type) {
      border-bottom: solid 1px $color-grey-lighter4;
    }
    & > b {
      float: right;
      text-align: right;

      &.basic-style {
        max-width: 80%;
      }

      &.description-style {
        max-width: 70%;
        @media screen and (max-width: 991px) {
          width: 100%;
          max-width: 100%;
          text-align: center;
        }
      }
    }
  }
}
</style>

<script>
import FormatHelper from "@/helper/formathelper";
import StaticRates from "@/components/savingseditor/helpers/staticRates";

export default {
  name: "GeneralDealDetails",
  props: {
    deal: Object,
    supply: Object,
    currentDeal: Object,
    suppliers: Object
  },
  computed: {
    projectedCostsLabel() {
      if (this.currentDeal.projectedAnnualCost) {
        if (!this.currentDeal.projectedCostsLabel) {
          return "Renewal";
        } else if (this.currentDeal.projectedCostsLabel == 1) {
          if (window.screen.width > 575) return "Out of Contract";
          else return "OOC";
        }
      }
      return null;
    },
    ccl() {
      return StaticRates.cclRates;
    }
  },
  methods: {
    formatDate: FormatHelper.formatDate,
    formatMonthsToYears: FormatHelper.formatMonthsToYears,
    formatNumberToDisplay: FormatHelper.formatNumberToDisplay,
    isEmpty: FormatHelper.isEmpty,

    formatTwoDecimals(value) {
      if ((value || value == 0) && typeof value != "undefined") {
        const number = parseFloat(value.toFixed(2));
        return number.toLocaleString("en-US"); // US format
      } else return "";
    }
  }
};
</script>