<template>
  <!-- WASTE BINS-->
  <div id="waste-details" class="w-100">
    <div
      v-if="deal.bins && deal.bins.length > 0"
      class="dropdown-reducer"
      @click="$emit('toggle', id)"
    >
      <h5>
        {{ title }}
        <font-awesome-icon
          class="mr-1 mt-1 float-right"
          :id="id + '-chevron'"
          :icon="['fas', 'chevron-up']"
          v-bind:style="{ color: color }"
        />
      </h5>
    </div>

    <div
      :id="id"
      v-bind:class="{ show: open }"
      class="collapse w-100"
      v-if="deal.bins && deal.bins.length > 0"
    >
      <div class="details-flex">
        <div
          class="reducer-box-shadow"
          v-for="(bin, index) in deal.bins"
          :key="bin.wasteType + index"
          v-show="bin.wasteType || bin.binSize"
        >
          <p v-if="bin.wasteType">
            Waste type:
            <b>{{ bin.wasteType }}</b>
          </p>
          <p v-if="bin.binSize">
            Bin size:
            <b>{{ bin.binSize }}</b>
          </p>
          <p v-if="bin.liftsCount">
            Lifts no.:
            <b>{{ bin.liftsCount }} /week</b>
          </p>
          <p v-if="bin.binLiftCost">
            Bin lift cost:
            <b>{{ formatPrice(bin.binLiftCost) }}</b>
          </p>
          <p class="border-0" v-if="bin.binRentalCharge">
            Bin rental charge:
            <b>{{ $currencySymbol }}{{ bin.binRentalCharge.toFixed(2) }}/month</b>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/styles/common.scss";
</style>

<script>
import FormatHelper from "@/helper/formathelper";

export default {
  name: "WasteBinDetails",
  props: {
    deal: Object,
    id: {
      type: String,
      default: "waste"
    },
    open: {
      type: Boolean,
      default: true
    },
    title: String,
    color: {
      type: String
    }
  },
  methods: {
    formatPrice: FormatHelper.formatPrice
  }
};
</script>