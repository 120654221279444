<template>
  <div class="w-100">
    <div class="dropdown-reducer mb-3" @click="$emit('toggle', id)">
      <h5>
        {{ title }}
        <font-awesome-icon
          class="mr-1 mt-1 float-right"
          :id="id + '-chevron'"
          :icon="['fas', 'chevron-up']"
          v-bind:style="{ color: color }"
        />
      </h5>
    </div>

    <div :id="id" v-bind:class="{ show: open }" class="collapse w-100">
      <!-- MONTHLY EXTRAS-->
      <div v-if="deal.monthlyExtras && deal.monthlyExtras.length > 0">
        <h5 class="ml-4 mb-0">Monthly Extras</h5>
        <div class="details-flex">
          <div
            v-for="(monthlyExtra, index) in deal.monthlyExtras"
            :key="index"
            class="reducer-box-shadow"
            v-show="monthlyExtra.description"
          >
            <p class="border-0 w-100">
              <span class="d-inline-block basic-style">
                {{ monthlyExtra.count && monthlyExtra.count > 1 ? monthlyExtra.count + " x " : "" }}
                {{ monthlyExtra.description + ": " }}
              </span>
              <b>{{ formatPrice(monthlyExtra.charge) }}</b>
            </p>
          </div>
        </div>
      </div>

      <!-- ONE OFF EXTRAS-->
      <div v-if="deal.oneOffExtras && deal.oneOffExtras.length > 0">
        <h5 class="ml-4 mb-0">One-Off Extras</h5>
        <div class="details-flex">
          <div
            v-for="(oneOffExtra, index) in deal.oneOffExtras"
            :key="index"
            class="reducer-box-shadow"
          >
            <div v-for="(value, key) in oneOffExtra" :key="value" v-show="key">
              <p>
                <span>Description:</span>
                <b class="description-style">{{ key }}</b>
              </p>
              <p class="border-0">
                Charge:
                <b>{{ formatPrice(value) }}</b>
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- LINE RENTALS-->

      <div v-if="deal.lineRentals && deal.lineRentals.length > 0 && !deal.combinedLineRentalVoip">
        <h5 class="ml-4 mb-0">Line Rentals</h5>
        <div class="details-flex">
          <div
            v-for="(lineRental, index) in deal.lineRentals"
            :key="index"
            class="reducer-box-shadow"
            v-show="lineRental.count || lineRental.charge"
          >
            <div class="my-auto">
              <p>
                Count:
                <b>{{ lineRental.count ? lineRental.count : 1 }}</b>
              </p>
              <p>
                Monthly cost:
                <b>{{ formatPrice(lineRental.charge) }}</b>
              </p>
              <p v-if="lineRental.description" class="border-0">
                Note:
                <b class="note-style">{{ lineRental.description }}</b>
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- VOIP SERVICES-->
      <div v-if="deal.voipServices && deal.voipServices.length > 0 && !deal.combinedLineRentalVoip">
        <h5 class="ml-4 mb-0">VOIP Services</h5>
        <div class="details-flex">
          <div
            v-for="(voipService, index) in deal.voipServices"
            :key="index"
            class="reducer-box-shadow"
            v-show="voipService.charge || voipService.count"
          >
            <p v-if="voipService.count != null">
              Count:
              <b>{{ voipService.count }}</b>
            </p>
            <p v-if="voipService.charge">
              Monthly cost:
              <b>{{ formatPrice(voipService.charge) }}</b>
            </p>
            <p v-if="voipService.minutes || voipService.unlimitedMinutes">
              Minutes:
              <b>
                {{
                  voipService.unlimitedMinutes ? "unlimited" : voipService.minutes + " mins/month"
                }}
              </b>
            </p>
            <p v-if="voipService.minutesInternational || voipService.unlimitedMinutesInternational">
              International minutes:
              <b>
                {{
                  voipService.unlimitedMinutesInternational
                    ? "unlimited"
                    : voipService.minutesInternational + " mins/month"
                }}
              </b>
            </p>
            <p class="border-0" v-if="voipService.description">
              Note:
              <b class="note-style">{{ voipService.description }}</b>
            </p>
          </div>
        </div>
      </div>

      <!-- BROADBAND SERVICES-->
      <div v-if="deal.broadbandServices && deal.broadbandServices.length > 0">
        <h5 class="ml-4 mb-0">Broadband Services</h5>
        <div class="details-flex">
          <div
            v-for="(broadbandService, index) in deal.broadbandServices"
            :key="index"
            class="reducer-box-shadow"
            v-show="broadbandService.charge || broadbandService.type"
          >
            <p v-if="broadbandService.count != null">
              Count:
              <b>{{ broadbandService.count }}</b>
            </p>
            <p v-if="broadbandService.charge">
              Monthly cost:
              <b>{{ formatPrice(broadbandService.charge) }}</b>
            </p>
            <p v-if="broadbandService.type">
              Type:
              <b>{{ broadbandService.type }}</b>
            </p>
            <p v-if="broadbandService.downloadSpeed">
              Download speed:
              <b>{{ broadbandService.downloadSpeed }} Mbps</b>
            </p>
            <p v-if="broadbandService.uploadSpeed">
              Upload speed:
              <b>{{ broadbandService.uploadSpeed }} Mbps</b>
            </p>
            <p class="border-0" v-if="broadbandService.description">
              Note:
              <b class="note-style">{{ broadbandService.description }}</b>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/styles/common.scss";
</style>

<script>
import FormatHelper from "@/helper/formathelper";

export default {
  name: "TelecomsDetails",
  props: {
    deal: Object,
    id: {
      type: String
    },
    open: {
      type: Boolean,
      default: true
    },
    title: String,
    color: {
      type: String
    }
  },
  methods: {
    formatPrice: FormatHelper.formatPrice
  }
};
</script>