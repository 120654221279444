<template>
  <div :id="supply.supplyId">
    <DemoSavingsReportModal
      :demoModal="demoModal"
      :color="branding.primaryColor"
    ></DemoSavingsReportModal>
    <TakeDealModal
      :deal="takeDeal"
      :organisationId="organisationId"
      :created="created"
      :primaryColor="branding.primaryColor"
      :secondaryColor="branding.secondaryColor"
      @cancelled="takeDeal = null"
    />
    <SupplierDetailsModal
      :supplier="supplierDetails"
      @cancelled="supplierDetails = null"
      :primaryColor="branding.primaryColor"
    ></SupplierDetailsModal>
    <BookConsultationModal
      :showBookConsultationModal="showBookConsultationModal"
      @cancelled="showBookConsultationModal = false"
    />
    <DealDetailsModal
      ref="dealDetailModal"
      :suppliers="suppliers"
      :deal="supply.recommendedDeal"
      :currentDeal="supply.currentDeal"
      :supply="supply"
      :color="branding.primaryColor"
    ></DealDetailsModal>

    <FileUploadModal ref="fileUploadModal" />

    <!--NO QUOTES-->
    <div v-if="supply.noQuotes" class="mb-5">
      <b-card class="savings-report-card reducer-box-shadow-medium no-quotes-card">
        <template v-slot:header>
          <div class="card-headings">
            <h5>{{ supply.name }}</h5>
            <span>{{ noQuotesToDisplay(supply.noQuotes) }}</span>
          </div>
        </template>

        <b-row class="p-3 py-md-4 align-items-center h-100">
          <b-col cols="12" md="3" class="text-center mb-3 mb-md-0 ">
            <b-img
              v-if="currentSupplier && currentSupplier.logos3url"
              :src="currentSupplier.logos3url"
              :alt="currentSupplier.name"
              class="supplierLogo"
            />
            <div
              v-else-if="supply.noQuotes"
              class="no-quotes-icon p-3 rounded-circle m-auto reducer-box-shadow"
            >
              <font-awesome-icon
                v-if="supply.noQuotes == 'ON_GOOD_DEAL'"
                :icon="['fad', 'thumbs-up']"
              />
              <font-awesome-icon
                v-else-if="supply.noQuotes == 'IN_CONTRACT'"
                :icon="['fad', 'lock-alt']"
              />
              <font-awesome-icon
                v-else-if="supply.noQuotes == 'NOT_ENOUGH_DATA'"
                :icon="['fad', 'info']"
              />
            </div>
          </b-col>

          <b-col class="d-flex align-items-center ml-lg-5 text-center text-md-left">
            <div v-if="supply.noQuotes == 'ON_GOOD_DEAL'">
              <b-card-text v-if="currentSupplier">
                Nice one, you're on a good deal with
                <b>{{ currentSupplier.name }}</b
                >.
              </b-card-text>
              <b-card-text v-else>Nice one, you're on a good deal.</b-card-text>
              <b-card-text
                >We checked the market but couldn't find a better deal than the one you are
                on.</b-card-text
              >
            </div>

            <div v-else-if="supply.noQuotes == 'IN_CONTRACT'">
              <div
                v-if="
                  supply.showIndicativeSavings &&
                    supply.indicativeSaving &&
                    supply.indicativeSaving > 0
                "
              >
                <b-card-text>
                  You're in contract
                  <span v-if="currentSupplier">
                    with
                    <b>{{ currentSupplier.supplierName }}</b>
                  </span>
                  <span
                    v-if="
                      supply.currentDeal &&
                        supply.currentDeal.contractExpiry &&
                        !supply.currentDeal.outOfContract
                    "
                  >
                    until
                    <b>
                      {{
                        formatDateTimeCustom(supply.currentDeal.contractExpiry, "Do MMMM YYYY")
                      }} </b
                    >.
                  </span>
                </b-card-text>
                <b-card-text>
                  If you had signed through Reducer, we would have saved you
                  <b> >{{ $currencySymbol }}{{ formatNumberToDisplay(supply.indicativeSaving) }}</b>
                  a year.
                </b-card-text>
                <b-card-text>
                  We cannot switch you right now but will be in touch 12 months before your contract
                  ends.
                </b-card-text>
              </div>

              <div v-else>
                <b-card-text v-if="currentSupplier">
                  You're in contract with
                  <b>{{ currentSupplier.name }}</b>
                  right now.
                </b-card-text>
                <b-card-text v-else>You are in contract right now.</b-card-text>
                <b-card-text
                  v-if="
                    supply.currentDeal &&
                      supply.currentDeal.contractExpiry &&
                      supply.currentDeal.outOfContract != 1
                  "
                >
                  The contract ends on
                  {{ formatDateTimeCustom(supply.currentDeal.contractExpiry, "Do MMMM YYYY") }}.
                </b-card-text>
              </div>
            </div>

            <div v-else-if="supply.noQuotes == 'NOT_ENOUGH_DATA'">
              <b-card-text v-if="supply.currentDeal && supply.currentDeal.supplierName">
                We identified your supplier as
                <b>
                  {{ supply.currentDeal.supplierName }}
                </b>
                but didn't have enough data to provide quotes.
              </b-card-text>
              <b-card-text v-else>
                We identified some spend in this area but didn't have enough data to provide quotes.
              </b-card-text>
              <b-card-text>
                We need one recent bill, containing all pages, to provide a quote.
                <b-button
                  v-if="supply.noQuotes == 'NOT_ENOUGH_DATA'"
                  class="upload-bill-btn px-5 mt-3 d-block mx-auto mx-md-0"
                  variant="outline-secondary"
                  @click="$refs.fileUploadModal.show(supply.organisationId, supply.currentDeal)"
                >
                  <font-awesome-icon class="mr-1" :icon="['fas', 'cloud-upload']" />
                  Upload Bill
                </b-button>
              </b-card-text>
            </div>
          </b-col>
        </b-row>
      </b-card>
    </div>

    <!-- WITH SAVING -->
    <div v-else class="mb-5">
      <b-card class="savings-report-card reducer-box-shadow-medium">
        <!-- HEADER -->
        <template v-slot:header>
          <div class="card-headings">
            <h5>{{ supply.name }}</h5>
            <div
              v-if="!hasActiveContract"
              class="current-plan-details d-none d-md-flex align-items-center"
            >
              <span class="text-right">{{ currentDealDescription() }}</span>
              <span v-if="currentSupplier">
                <b-img
                  v-if="currentSupplier.logos3url"
                  class="p-1"
                  :src="currentSupplier.logos3url"
                  :alt="currentSupplier.name"
                />
                <b v-else-if="currentSupplier.name" class="pl-1">{{ currentSupplier.name }}</b>
              </span>
            </div>
          </div>
        </template>

        <Ribbon v-if="hasActiveContract" align-right big>
          {{ contract.state == "OUT" ? "Signing" : "Signed" }}
        </Ribbon>

        <!-- BRIEF DESCRIPTION -->
        <b-row class="py-md-3 py-lg-4 pl-md-4">
          <b-col
            md="4"
            lg="3"
            cols="12"
            class="
              d-flex
              flex-column
              justify-content-center
              text-center
              card-supplier-info
              p-4
              py-md-0
              px-md-3
              ml-lg-3
            "
          >
            <div>
              <div v-if="hasSupplierLogo">
                <b-img
                  class="supplierLogo"
                  :src="recommendedSupplier.logos3url"
                  :alt="recommendedSupplier.name"
                />
              </div>
              <h2 v-else-if="recommendedSupplier" class="supplierName">
                {{ recommendedSupplier.name }}
              </h2>

              <div class="d-flex justify-content-center my-3 badge-row">
                <b-badge variant="primary" class="mr-2">Recommended deal</b-badge>
                <b-badge v-if="supply.recommendedDeal.greenTariff" variant="success"
                  >Green tariff</b-badge
                >
                <b-badge v-if="supply.type == 'MOBILE' && handsetIncluded" variant="success"
                  >Handset included</b-badge
                >
                <b-badge v-else-if="supply.type == 'MOBILE'" variant="success">SIM only </b-badge>
              </div>
            </div>

            <div class="">
              <div class="py-2 mx-1 mb-2 grey-bg">
                <b class="mb-1 annual-savings">
                  {{ $currencySymbol }}{{ formatNumberToDisplay(supply.recommendedDeal.saving) }}
                </b>
                <br />
                <span class="small">Savings per year</span>
              </div>
              <b-button
                class="take-deal-btn reducer-box-shadow gtm-takedeal-attempt"
                :style="
                  hasActiveContract
                    ? `background: grey`
                    : `background: ${branding.gradientTopColor}`
                "
                @click="handleTakeDeal(supply.recommendedDeal)"
                :disabled="hasActiveContract"
              >
                {{
                  hasActiveContract && contract.dealId == supply.recommendedDeal.dealId
                    ? "Deal taken"
                    : "Take deal"
                }}
              </b-button>

              <div class="text-center mt-2">
                <u
                  v-if="hasEnoughData"
                  class="small mr-3 details-btn"
                  role="button"
                  @click="toSupplierDetailsModal(supply.recommendedDeal.supplierId)"
                  >Supplier details</u
                >
                <u class="small details-btn" role="button" @click="$refs.dealDetailModal.show()"
                  >Offer details</u
                >
              </div>
            </div>
          </b-col>

          <b-col class="ml-lg-5 mt-2 mt-md-0">
            <div class="d-flex flex-column justify-content-around h-100">
              <div>
                <div
                  v-if="currentSupplier || hasCurrentDealExpiry"
                  class="deal-details-row grey-bg mb-1 d-md-none"
                >
                  <b class="text-bold">Current plan details</b>
                </div>

                <div v-if="currentSupplier" class="deal-details-row d-md-none">
                  <span>Current Supplier</span>
                  <b-img
                    v-if="
                      suppliers[supply.currentDeal.supplierId] &&
                        suppliers[supply.currentDeal.supplierId].logos3url
                    "
                    :src="suppliers[supply.currentDeal.supplierId].logos3url"
                    :alt="suppliers[supply.currentDeal.supplierId].name"
                    height="25px"
                    style="margin-top: -5px"
                  />
                  <b v-else-if="suppliers[supply.currentDeal.supplierId]">
                    {{ suppliers[supply.currentDeal.supplierId].name }}
                  </b>
                  <b v-else> Unknown </b>
                </div>

                <div v-if="hasCurrentDealExpiry" class="deal-details-row d-md-none">
                  <span>Contract Expiry</span>
                  <b>{{ currentDealDescription(true) }}</b>
                </div>

                <div class="deal-details-row grey-bg mb-1 d-md-none">
                  <b class="text-bold">Recommended plan details</b>
                </div>

                <div class="deal-details-row">
                  <span>Contract Term</span>
                  <b>{{ formatMonthsToYears(supply.recommendedDeal.contractTerm.toFixed(0)) }}</b>
                </div>

                <div v-if="supply.type == 'TELECOMS'" class="deal-details-row">
                  <span>Setup Fee</span>
                  <b
                    v-if="
                      !supply.recommendedDeal.setupFee || supply.recommendedDeal.setupFee == '0'
                    "
                    >Free</b
                  >
                  <b v-else>{{ $currencySymbol }}{{ supply.recommendedDeal.setupFee }}</b>
                </div>

                <div class="deal-details-row">
                  <span>Annual Cost</span
                  ><b
                    >{{ $currencySymbol
                    }}{{ formatNumberToDisplay(supply.recommendedDeal.annualCost) }}</b
                  >
                </div>

                <div class="deal-details-row">
                  <span
                    v-if="
                      supply.currentDeal.projectedAnnualCost &&
                        !supply.currentDeal.projectedCostsLabel
                    "
                    >Annual Savings vs Renewal</span
                  >
                  <span
                    v-else-if="
                      supply.currentDeal.projectedAnnualCost &&
                        supply.currentDeal.projectedCostsLabel == 1
                    "
                  >
                    Annual Savings vs Out of Contract Rates
                  </span>
                  <span v-else>Annual Savings</span>
                  <b
                    >{{ $currencySymbol
                    }}{{ formatNumberToDisplay(supply.recommendedDeal.saving) }}</b
                  >
                </div>
              </div>

              <div class="deal-details-row grey-bg py-3 total-savings">
                <h5>Total Savings</h5>
                <b>
                  {{ $currencySymbol
                  }}{{
                    formatNumberToDisplay(
                      (supply.recommendedDeal.contractTerm / 12) * supply.recommendedDeal.saving
                    )
                  }}
                </b>
              </div>
              <div v-if="hasExtraDetails" class="mx-2 mb-2 m-md-0 pr-md-3 text-center text-md-left">
                <b-card-text v-if="supply.header" class="extra-details">{{
                  supply.header
                }}</b-card-text>
                <b-card-text v-if="supply.footer" class="extra-details">{{
                  supply.footer
                }}</b-card-text>
                <b-card-text
                  v-if="
                    supply.type == 'MOBILE' ||
                      supply.type == 'TELECOMS' ||
                      supply.currentDeal.projectedAnnualCost ||
                      (!supply.currentDeal.outOfContract && !supply.currentDeal.contractExpiry)
                  "
                  class="extra-details"
                >
                  <span v-if="supply.type == 'MOBILE' || supply.type == 'TELECOMS'">
                    Savings quoted could change, full breakdown of requirements needed for final
                    quote.&nbsp;
                  </span>
                  <span
                    v-if="!supply.currentDeal.outOfContract && !supply.currentDeal.contractExpiry"
                  >
                    <span v-if="!isRenewSupplier()">Switching</span>
                    <span v-else>Renewing</span>
                    is subject to confirmation of your current contract end date.
                  </span>
                  <span
                    v-if="
                      supply.currentDeal.projectedAnnualCost &&
                        !supply.currentDeal.projectedCostsLabel
                    "
                  >
                    Renewal prices are predicted rates that will be offered to you by your current
                    supplier at the end of your existing contract. We calculate this using the
                    forward market and average renewal prices offered by your supplier based on your
                    location and meter type.
                  </span>
                  <span
                    v-else-if="
                      supply.currentDeal.projectedAnnualCost &&
                        supply.currentDeal.projectedCostsLabel == 1
                    "
                  >
                    Out of contract rates are default rates that you will automatically be moved on
                    to if a new contract is not in place. These rates will be applied once your
                    current contract ends.
                  </span>
                </b-card-text>
              </div>
            </div>
          </b-col>
        </b-row>

        <!-- OTHER OPTIONS-->
        <div
          v-if="supply.deals.length > 2"
          :id="'dropdown-footer' + supply.supplyId + '-other-options'"
          class="dropdown-footer d-flex justify-content-center mt-2 mt-md-0"
          @click="toggleCollapsed(supply.supplyId + '-other-options')"
        >
          <h5 class="m-auto">
            See all
            <b>{{ supply.deals.length - 1 }}</b>
            saving
            <b v-if="supply.deals.length - 1 > 1">options</b>
            <b v-else>option</b>
            <font-awesome-icon
              class="ml-2"
              :style="{ color: branding.primaryColor }"
              :icon="['fas', 'chevron-down']"
            />
          </h5>
        </div>

        <div
          v-if="supply.deals.length > 2"
          :id="supply.supplyId + '-other-options'"
          class="collapse pt-3"
        >
          <OtherSavingOptionsEnergy
            v-if="supply.type == 'ELECTRICITY' || supply.type == 'GAS'"
            :supply="supply"
            :organisationId="organisationId"
            :created="created"
            :demo="demo"
            :suppliers="suppliers"
            :category="category"
            :primaryColor="branding.primaryColor"
            :secondaryColor="branding.secondaryColor"
            :solidTakeDeal="branding.solidTakeDeal"
            :showPlaceholders="showPlaceholders"
            :contract="contract"
          />
          <OtherSavingOptionsTelecoms
            v-else-if="supply.type == 'TELECOMS'"
            :supply="supply"
            :organisationId="organisationId"
            :created="created"
            :demo="demo"
            :suppliers="suppliers"
            :category="category"
            :primaryColor="branding.primaryColor"
            :secondaryColor="branding.secondaryColor"
            :solidTakeDeal="branding.solidTakeDeal"
            :contract="contract"
          />
          <OtherSavingOptionsMobile
            v-else-if="supply.type == 'MOBILE'"
            :supply="supply"
            :organisationId="organisationId"
            :created="created"
            :demo="demo"
            :suppliers="suppliers"
            :category="category"
            :primaryColor="branding.primaryColor"
            :secondaryColor="branding.secondaryColor"
            :solidTakeDeal="branding.solidTakeDeal"
            :contract="contract"
          />
          <OtherSavingOptions
            v-else
            :supply="supply"
            :organisationId="organisationId"
            :created="created"
            :demo="demo"
            :suppliers="suppliers"
            :category="category"
            :primaryColor="branding.primaryColor"
            :secondaryColor="branding.secondaryColor"
            :solidTakeDeal="branding.solidTakeDeal"
            :contract="contract"
          />
          <div class="mb-4 pb-2 mr-2">
            <div
              class="float-right mr-3 close-modal-div small"
              @click="toggleCollapsed(supply.supplyId + '-other-options')"
            >
              <u> Close section </u>
            </div>
          </div>
        </div>
      </b-card>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/styles/common.scss";

.savings-report-card {
  & > .card-body,
  .card-header,
  .modal-body {
    padding: 0;
  }

  h5 {
    color: $color-font-headings;
  }

  .card-headings {
    background-color: $color-font-headings;
    border-color: $color-font-headings;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.3rem 1rem;
    min-height: 40px;
    margin: -1px;

    h5 {
      color: white !important;
      margin-bottom: 0 !important;
      font-size: 17px;
    }
    span {
      color: white !important;
      font-size: 14px;
    }

    .current-plan-details {
      img {
        background-color: white;
        border-radius: 3px;
        max-height: 30px;
        margin-left: 8px;
      }
    }
  }

  .supplierLogo {
    max-height: 4rem;
    max-width: 90%;
  }

  .card-supplier-info {
    h5 {
      color: $color-font-headings;
    }
    .badge-row {
      @include badge-styles;
    }
    .annual-savings {
      font-size: 27px;
    }
    .take-deal-btn {
      border: none;
      color: white;
      width: 240px;
      font-size: 19px;
      font-weight: 600;
      max-width: 100%;
      transition: all 0.1s ease-in-out;
      &:hover {
        filter: brightness(95%);
      }
    }
    .details-btn {
      cursor: pointer;
    }
    .small {
      font-size: 13.5px;
      white-space: nowrap;
    }
  }

  .deal-details-row {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem;
    padding-right: 2rem;
    border-bottom: solid 1px #f1f2f5;
    font-size: 15.5px;
    h5,
    b {
      margin: 0;
      color: $color-font-headings;
    }
    &.total-savings {
      b {
        font-size: 20px;
      }
    }
  }

  .grey-bg {
    background-color: whitesmoke;
  }

  .zoomGradientOnHover:hover img {
    transform: scale(1.08);
    cursor: pointer;
  }

  .supplierName {
    font-size: 1.8rem;
    margin-bottom: 0px;
  }

  .dropdown-footer {
    background-color: $color-grey-lighter5;
    cursor: pointer;
    border: 1px solid $color-grey-lighter5;
    border-width: 1px 0 0 0;
    height: 60px;
    h5 {
      font-size: 18px;
    }
  }

  .close-modal-div:hover {
    cursor: pointer;
  }

  .extra-details {
    font-size: 13px;
    padding-bottom: 0;
    margin: 0;
    &:first-of-type {
      padding-top: 0.5rem;
    }
  }

  &.no-quotes-card {
    .card-text {
      &:not(:last-of-type) {
        margin-bottom: 0.5rem;
      }
    }
    .no-quotes-icon {
      color: $color-grey-lighter1;
      background-color: whitesmoke;
      width: 5rem;
      height: 5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        font-size: 2.7rem;
      }
    }
  }

  @media screen and (max-width: 767px) {
    .deal-details-row {
      font-size: 13px;
      padding: 0.5rem 1rem;
      &.total-savings b,
      h5 {
        font-size: 18px;
      }
    }
    .dropdown-footer h5 {
      font-size: 15px;
    }
    .card-headings {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      span,
      h5 {
        font-size: 13px !important;
      }
    }
    .supplierLogo {
      max-height: 70px !important;
    }
    .card-supplier-info {
      .small {
        font-size: 13px !important;
      }
    }
  }
}
// Out of scope to apply to child components
.savings-report-card,
.other-savings-options-container {
  .green-tariff-icon {
    border-radius: 50%;
    width: 1.5rem;
    height: 1.5rem;
    color: white;
    text-align: center;
    font-size: 17px;
    margin-right: 0.5rem;
    svg {
      margin: auto;
    }
  }
}
</style>

<script>
import FormatHelper from "@/helper/formathelper";
import OtherSavingOptions from "@/components/savingsreport/OtherSavingOptions";
import OtherSavingOptionsTelecoms from "@/components/savingsreport/OtherSavingOptionsTelecoms";
import OtherSavingOptionsMobile from "@/components/savingsreport/OtherSavingOptionsMobile";
import OtherSavingOptionsEnergy from "@/components/savingsreport/OtherSavingOptionsEnergy";
import TakeDealModal from "@/components/savingsreport/TakeDealModal";
import DemoSavingsReportModal from "@/components/savingsreport/DemoSavingsReportModal";
import SupplierDetailsModal from "@/components/savingsreport/SupplierDetailsModal";
import BookConsultationModal from "@/components/savingsreport/BookConsultationModal";
import moment from "moment";
import DealDetailsModal from "@/components/savingsreport/DealDetailsModal";
import Ribbon from "@/components/Ribbon";
import FileUploadModal from "@/components/modals/FileUploadModal";

export default {
  name: "SavingsCategoryCard",
  components: {
    BookConsultationModal,
    OtherSavingOptions,
    TakeDealModal,
    DemoSavingsReportModal,
    SupplierDetailsModal,
    OtherSavingOptionsTelecoms,
    OtherSavingOptionsMobile,
    OtherSavingOptionsEnergy,
    DealDetailsModal,
    FileUploadModal,
    Ribbon
  },
  props: {
    supply: Object,
    suppliers: Object,
    category: String,
    organisationId: String,
    created: String,
    demo: Boolean,
    branding: Object,
    showPlaceholders: Boolean,
    contract: Object
  },
  data() {
    return {
      takeDeal: null,
      demoModal: false,
      showBookConsultationModal: false,
      supplierDetails: null,
      dealModal: null
    };
  },
  computed: {
    recommendedSupplier() {
      if (
        this.supply.recommendedDeal &&
        this.supply.recommendedDeal.supplierId &&
        this.suppliers &&
        this.suppliers[this.supply.recommendedDeal.supplierId] &&
        typeof this.suppliers[this.supply.recommendedDeal.supplierId] != "undefined"
      ) {
        return this.suppliers[this.supply.recommendedDeal.supplierId];
      } else return null;
    },

    currentSupplier() {
      if (
        this.supply.currentDeal &&
        this.supply.currentDeal.supplierId &&
        this.suppliers &&
        this.suppliers[this.supply.currentDeal.supplierId] &&
        typeof this.suppliers[this.supply.currentDeal.supplierId] != "undefined"
      ) {
        return this.suppliers[this.supply.currentDeal.supplierId];
      } else return null;
    },

    hasSupplierLogo() {
      return this.recommendedSupplier && this.recommendedSupplier.logos3url;
    },

    hasEnoughData() {
      let supplier = this.recommendedSupplier;
      if (supplier) {
        let nrOfData = 0;

        if (supplier) {
          if (supplier.reducerScore && supplier.reducerScore != 0) {
            nrOfData++;
          }
          if (supplier.logos3url) {
            nrOfData++;
          }
          if (supplier.shortDescription && supplier.shortDescription.length > 0) {
            nrOfData++;
          }
          if (supplier.keyPoints && supplier.keyPoints.length > 0) {
            nrOfData++;
          }
        }
        return nrOfData > 1 ? true : false;
      }
      return false;
    },

    hasExtraDetails() {
      return (
        this.supply.header ||
        this.supply.footer ||
        this.supply.type == "MOBILE" ||
        this.supply.type == "TELECOMS" ||
        this.supply.currentDeal.projectedAnnualCost ||
        (!this.supply.currentDeal.outOfContract && !this.supply.currentDeal.contractExpiry)
      );
    },

    handsetIncluded() {
      return (
        this.supply.type == "MOBILE" &&
        this.supply.recommendedDeal.numbers &&
        this.supply.recommendedDeal.numbers.length &&
        this.supply.recommendedDeal.numbers[0].handset
      );
    },

    hasCurrentDealExpiry() {
      return (
        this.supply.currentDeal &&
        (this.supply.currentDeal.outOfContract || this.supply.currentDeal.contractExpiry)
      );
    },

    hasActiveContract() {
      return this.contract && (this.contract.state == "OUT" || this.contract.state == "SIGNED");
    }
  },
  methods: {
    formatNumberToDisplay: FormatHelper.formatNumberToDisplay,
    formatMonthsToYears: FormatHelper.formatMonthsToYears,
    formatDate: FormatHelper.formatDate,
    formatDateTimeCustom: FormatHelper.formatDateTimeCustom,

    currentDealDescription(short) {
      let description = "";
      if (this.supply.currentDeal.contractExpiry || this.supply.currentDeal.outOfContract) {
        const expired =
          this.supply.currentDeal.outOfContract ||
          moment(this.supply.currentDeal.contractExpiry).isBefore(moment());
        const expiryDate = this.formatDateTimeCustom(
          this.supply.currentDeal.contractExpiry,
          "Do MMMM YYYY"
        );

        if (short && expired) {
          description = "Out of contract";
        } else if (short && !expired) {
          description = expiryDate;
        } else if (expired) {
          description = "You are currently out of contract";
        } else {
          description = "Your current contract ends on " + expiryDate;
        }

        if (this.currentSupplier && !short) description += " with";
      } else if (this.currentSupplier) {
        description = "Your current supplier is";
      }
      return description;
    },

    isRenewSupplier() {
      if (this.currentSupplier && this.recommendedSupplier) {
        return this.currentSupplier.supplierId == this.recommendedSupplier.supplierId;
      }
      return false;
    },

    noQuotesToDisplay(noQuotes) {
      switch (noQuotes) {
        case "ON_GOOD_DEAL":
          return "On a good deal";
        case "IN_CONTRACT":
          return "In contract";
        case "NOT_ENOUGH_DATA":
          return "Not enough data";
      }
    },

    toggleCollapsed(id) {
      var element = document.getElementById(id);

      if (element.className.includes(" show")) {
        element.className = element.className.replace(" show", "");
      } else {
        element.className += " show";
      }
    },

    handleTakeDeal(deal) {
      if (!this.demo) this.takeDeal = deal;
      else this.demoModal = !this.demoModal;
    },

    toSupplierDetailsModal(id) {
      if (this.suppliers[id]) {
        this.supplierDetails = Object.assign({}, this.suppliers[id]);
      } else {
        this.supplierDetails = new Object();
      }
    }
  }
};
</script>
