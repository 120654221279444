<template>
  <div class="other-saving-options-container other-saving-options-telecoms px-4 px-md-0">
    <!-- Filtering -->
    <b-row
      v-if="supply.deals.length > 4"
      class="options-filter mx-1 mx-md-3 justify-content-between"
    >
      <b-col cols="12" class="d-flex justify-content-between pb-1 align-items-end">
        <p v-if="filteredDeals.length == 0" class="m-0">No options available</p>
        <p v-else class="m-0">Showing {{ filteredDeals.length }} options</p>
        <b-button size="sm" variant="light" class="border" @click="resetFilter()"
          >Reset filter</b-button
        >
      </b-col>

      <b-col v-if="anyBroadbandDeals" class="filter-section">
        <h5>Package includes</h5>
        <b-checkbox-group v-model="selectedPackages" :options="packageOptions" buttons />
      </b-col>
      <b-col v-if="anyBroadbandDeals" class="filter-section">
        <h5>Average speed</h5>
        <b-checkbox-group v-model="selectedSpeeds" :options="speedOptions" buttons />
      </b-col>
      <b-col class="filter-section">
        <h5>Contract length</h5>
        <b-checkbox-group v-model="selectedTerms" :options="termOptions" buttons />
      </b-col>
    </b-row>

    <div v-for="(deal, dealName) in filteredDeals" :key="dealName" v-show="deal.supplierId">
      <!-- Recommended badge -->
      <div :style="styleObject" :class="'mx-3 mx-md-5 info-badge-' + displayInfoBadge(deal)">
        <p v-if="hasActiveContract && contract.dealId == deal.dealId" class="mb-0 px-3 pt-1">
          {{ contract.state == "OUT" ? "Contract ready to sign" : "Contract has been signed" }}
        </p>
        <p v-else-if="deal.recommended" class="mb-0 px-3 pt-1">
          Recommended
        </p>
      </div>

      <div class="w-100 other-savings-option other-savings-option-telecoms">
        <b-row
          :style="styleObject"
          :class="
            'my-3 justify-content-center align-items-stretch position-relative reducer-box-shadow savings-card show-border-' +
              displayInfoBadge(deal)
          "
        >
          <!-- Supplier image column -->

          <b-col cols="12" md="2" class="deal-col supplier-column mt-2 m-md-0">
            <b-img
              v-if="
                deal.supplierId &&
                  suppliers &&
                  suppliers[deal.supplierId] &&
                  typeof suppliers[deal.supplierId] != 'undefined' &&
                  suppliers[deal.supplierId].logos3url
              "
              :src="suppliers[deal.supplierId].logos3url"
              :alt="suppliers[deal.supplierId].name"
              class="align-items-center image-size"
            />
            <h5 v-else class="m-0">
              <span
                v-if="
                  suppliers[deal.supplierId] &&
                    typeof suppliers[deal.supplierId] != 'undefined' &&
                    suppliers[deal.supplierId].name
                "
              >
                {{ suppliers[deal.supplierId].name }}
              </span>
            </h5>
            <div class="ml-auto">
              <b-badge
                v-if="
                  deal.broadbandServices &&
                    deal.broadbandServices.length > 0 &&
                    deal.broadbandServices[0].type == 'FIBRE'
                "
                class="grey-badge mb-2"
                >FIBRE</b-badge
              >
            </div>
          </b-col>

          <b-col cols="12" class="d-md-none mb-2">
            <div class="text-center" v-if="hasEnoughData(deal)">
              <u class="pointer supplier-link" @click="openSupplierDetailsModal(deal.supplierId)"
                >Supplier details</u
              >
            </div>
          </b-col>

          <!-- Deal details (sm screens) -->

          <b-col cols="12" class="d-md-none p-0">
            <div class="deal-details-row pl-3 pr-0">
              <span>{{ telecomsDescription(deal) }}</span>
              <div class="double-icon d-md-none">
                <font-awesome-icon
                  v-if="deal.broadbandServices && deal.broadbandServices.length > 0"
                  :icon="['fas', 'wifi']"
                  class="text-muted"
                />
                <font-awesome-icon
                  v-if="deal.lineRentals && deal.lineRentals.length > 0"
                  :icon="['fas', 'phone-alt']"
                  class="text-muted"
                />
              </div>
            </div>

            <div
              v-if="
                deal.broadbandServices &&
                  deal.broadbandServices.length > 0 &&
                  deal.broadbandServices[0].downloadSpeed
              "
              class="deal-details-row px-3"
            >
              <span>Average Speed</span>
              <b>{{ deal.broadbandServices[0].downloadSpeed }} Mbps</b>
            </div>

            <div class="deal-details-row px-3">
              <span>Contract Term</span>
              <b>{{ formatMonthsToYears(deal.contractTerm.toFixed(0)) }}</b>
            </div>

            <div class="deal-details-row px-3">
              <span>Setup Fee</span>
              <b v-if="deal.setupFee">{{ $currencySymbol }}{{ deal.setupFee }}</b>
              <b v-else>Free</b>
            </div>

            <div v-if="deal.estimatedAnnualCost" class="deal-details-row px-3">
              <span>Estimated Annual Cost</span
              ><b>{{ $currencySymbol }}{{ formatNumberToDisplay(deal.estimatedAnnualCost) }}</b>
            </div>
            <div v-else class="deal-details-row px-3">
              <span>Annual Cost</span
              ><b>{{ $currencySymbol }}{{ formatNumberToDisplay(deal.annualCost) }}</b>
            </div>

            <div class="deal-details-row px-3">
              <span v-if="deal.projectedAnnualCost">Annual Savings vs Renewal</span>
              <span v-else>Annual Savings</span>
              <b>{{ $currencySymbol }}{{ formatNumberToDisplay(deal.saving) }}</b>
            </div>
          </b-col>

          <b-col cols="3" md="2" class="deal-col deal-details-col">
            <h5 v-if="deal.contractTerm">
              <span>{{ formatMonthsToYears(deal.contractTerm) }}</span>
            </h5>
            <p v-if="deal.contractTerm" class="m-0">Contract</p>
          </b-col>

          <b-col cols="3" md="2" class="deal-col deal-details-col">
            <div v-if="deal.annualCost">
              <h5>
                <span>{{ $currencySymbol }}{{ formatNumberToDisplay(deal.annualCost) }}</span>
              </h5>
              <p class="m-0">Annual cost</p>
            </div>
            <div v-else-if="deal.estimatedAnnualCost">
              <h5>
                <span
                  >{{ $currencySymbol }}{{ formatNumberToDisplay(deal.estimatedAnnualCost) }}</span
                >
              </h5>
              <p class="m-0">Estimated Annual cost</p>
            </div>
            <p v-if="deal.setupFee" class="m-0 pt-1 small">
              + {{ $currencySymbol }}{{ deal.setupFee }} setup fee
            </p>
          </b-col>

          <b-col cols="3" md="2" class="deal-col deal-details-col">
            <h5>
              <span v-if="deal.saving"
                >{{ $currencySymbol }}{{ formatNumberToDisplay(deal.saving) }}</span
              >
              <span v-else>{{ $currencySymbol }}0</span>
            </h5>
            <p v-if="deal.saving" class="m-0">Savings/yr</p>
          </b-col>

          <b-col cols="3" md="2" class="deal-col deal-details-col speed-column">
            <div v-b-popover.hover.top="telecomsDescription(deal)">
              <div class="double-icon d-none d-md-flex m-auto">
                <font-awesome-icon
                  v-if="deal.broadbandServices && deal.broadbandServices.length > 0"
                  :icon="['fas', 'wifi']"
                  class="text-muted"
                />
                <font-awesome-icon
                  v-if="deal.lineRentals && deal.lineRentals.length > 0"
                  :icon="['fas', 'phone-alt']"
                  class="text-muted"
                />
              </div>
              <div
                v-if="
                  deal.broadbandServices &&
                    deal.broadbandServices.length > 0 &&
                    deal.broadbandServices[0].downloadSpeed
                "
              >
                <h5 class="pt-md-3 m-0">
                  <span>{{ deal.broadbandServices[0].downloadSpeed + " Mbps" }}</span>
                </h5>
                <p
                  class="m-0 mt-1"
                  v-if="
                    deal.broadbandServices &&
                      deal.broadbandServices.length > 0 &&
                      deal.broadbandServices[0].downloadSpeed
                  "
                >
                  Average speed
                </p>
                <p class="m-0 pt-1 small">+ Unlimited Downloads</p>
              </div>
              <p v-else class="pt-md-2 m-0">{{ telecomsDescription(deal) }}</p>
            </div>
          </b-col>

          <b-col cols="12" md="2" class="deal-col flex-row flex-md-column">
            <b-button
              class="take-deal-btn gtm-takedeal-attempt py-2 px-4 px-md-2 px-xl-4 mx-auto"
              :style="hasActiveContract ? `background: grey !important;` : ``"
              @click="handleTakeDeal(deal)"
              :disabled="hasActiveContract"
            >
              {{ hasActiveContract && contract.dealId == deal.dealId ? "Deal taken" : "Take deal" }}
            </b-button>

            <u
              class="pointer mt-2 mx-auto"
              @click="
                dealDetails = deal;
                $refs.dealDetailModal.show();
              "
              >Offer details</u
            >
            <div class="mt-1 d-none d-md-block" v-if="hasEnoughData(deal)">
              <u class="pointer" @click="openSupplierDetailsModal(deal.supplierId)"
                >Supplier details</u
              >
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
    <SupplierDetailsModal
      :supplier="supplierDetails"
      @cancelled="supplierDetails = null"
      :primaryColor="primaryColor"
    ></SupplierDetailsModal>
    <DealDetailsModal
      ref="dealDetailModal"
      :suppliers="suppliers"
      :deal="dealDetails"
      :currentDeal="supply.currentDeal"
      :supply="supply"
      :color="primaryColor"
    ></DealDetailsModal>
    <DemoSavingsReportModal :demoModal="demoModal" :color="primaryColor"></DemoSavingsReportModal>
    <TakeDealModal
      :deal="takeDeal"
      :organisationId="organisationId"
      :created="created"
      :primaryColor="primaryColor"
      :secondaryColor="secondaryColor"
      @cancelled="takeDeal = null"
    />
  </div>
</template>

<script>
import { otherSavingOptionsMixin } from "./mixins/otherSavingOptionsMixin";

export default {
  name: "OtherSavingOptionsTelecoms",
  mixins: [otherSavingOptionsMixin],
  data() {
    return {
      anyBroadbandDeals: true,
      selectedTerms: [12, 24, 36],
      termOptions: [
        { text: "1 year", value: 12 },
        { text: "2 years", value: 24 },
        { text: "3+ years", value: 36 }
      ],
      selectedPackages: ["broadband", "phoneline"],
      packageOptions: [
        { text: "Broadband", value: "broadband" },
        { text: "Phone line", value: "phoneline" }
      ],
      selectedSpeeds: ["0-30", "30-60", "60+"],
      speedOptions: [
        { text: "0-30Mb", value: "0-30" },
        { text: "30-60Mb", value: "30-60" },
        { text: "60Mb+", value: "60+" }
      ]
    };
  },
  computed: {
    filteredDeals() {
      let deals = this.offers;

      //  Contract term filtering
      if (this.selectedTerms.length > 0 && this.selectedTerms.length != this.termOptions.length) {
        deals = deals.filter(d => {
          if (this.selectedTerms.includes(36)) {
            return this.selectedTerms.includes(d.contractTerm) || d.contractTerm > 36;
          } else return this.selectedTerms.includes(d.contractTerm);
        });
      }

      // Broadband speed filtering
      if (
        this.selectedSpeeds.length > 0 &&
        this.selectedSpeeds.length != this.speedOptions.length
      ) {
        deals = deals.filter(d => {
          if (d.broadbandServices && d.broadbandServices.length > 0) {
            const speed = d.broadbandServices[0].downloadSpeed;
            if (this.selectedSpeeds.includes("0-30") && speed <= 30) return true;
            if (this.selectedSpeeds.includes("30-60") && speed >= 30 && speed <= 60) return true;
            if (this.selectedSpeeds.includes("60+") && speed >= 60) return true;
            return false;
          }
        });
      }
      // Package filtering
      if (
        this.selectedPackages.length > 0 &&
        this.selectedPackages.length != this.packageOptions.length
      ) {
        deals = deals.filter(d => {
          const hasBroadband = d.broadbandServices && d.broadbandServices.length > 0;
          const hasPhoneLine = d.lineRentals && d.lineRentals.length > 0;

          if (this.selectedPackages.includes("broadband")) {
            return hasBroadband; // broadband only
          } else if (this.selectedPackages.includes("phoneline")) {
            return hasPhoneLine; // phone line only
          }
        });
      }

      return deals;
    }
  },
  created() {
    this.updateFilterOptions();
  },
  methods: {
    telecomsDescription(deal) {
      return deal.broadbandServices &&
        deal.broadbandServices.length > 0 &&
        deal.lineRentals &&
        deal.lineRentals.length > 0
        ? "Broadband and phone line included"
        : deal.broadbandServices && deal.broadbandServices.length > 0
        ? "Broadband only"
        : "Phone line only";
    },
    resetFilter() {
      this.selectedTerms = this.termOptions.map(o => o.value);
      if (this.anyBroadbandDeals) {
        this.selectedPackages = this.packageOptions.map(o => o.value);
        this.selectedSpeeds = this.speedOptions.map(o => o.value);
      }
    },
    updateFilterOptions() {
      // Remove broadband package if there are no deals with broadband
      if (!this.filteredDeals.some(d => d.broadbandServices && d.broadbandServices.length > 0)) {
        this.anyBroadbandDeals = false;
        this.selectedPackages = [];
        this.selectedSpeeds = [];
      }

      // Remove contract term options that don't exist in deals
      if (!this.supply.deals.some(d => d.contractTerm == 12)) {
        this.termOptions = this.termOptions.filter(o => o.value != 12);
        this.selectedTerms = this.selectedTerms.filter(o => o != 12);
      }
      if (!this.supply.deals.some(d => d.contractTerm == 24)) {
        this.termOptions = this.termOptions.filter(o => o.value != 24);
        this.selectedTerms = this.selectedTerms.filter(o => o != 24);
      }
      if (!this.supply.deals.some(d => d.contractTerm >= 36)) {
        this.termOptions = this.termOptions.filter(o => o.value != 36);
        this.selectedTerms = this.selectedTerms.filter(o => o != 36);
      }
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/common.scss";
@import "./styles/otherSavingOptions";

.other-saving-options-telecoms {
  .other-savings-option-telecoms {
    .deal-col {
      flex-flow: column;
      height: unset !important;
    }
    .double-icon {
      display: flex;
      justify-content: space-evenly;
      min-width: 70px;
      max-width: 100px;
      svg {
        background-color: $color-grey-lighter3;
        padding: 5px;
        font-size: 23px;
        border-radius: 6px;
      }
    }

    .speed-column {
      h5 {
        font-size: inherit;
      }
    }

    .supplier-column {
      .badge {
        position: absolute;
        top: 10px;
        right: 10px;
      }
    }

    .grey-badge {
      background-color: $color-grey-lighter3;
      color: $color-font-para;
    }
  }
}
</style>
