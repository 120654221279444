<template>
  <div class="circle-wrap" :style="styleObject" v-b-popover.hover.right="ratingStatus">
    <div class="circle" :style="styleObject">
      <div class="mask full" :style="styleObject">
        <div class="fill" :style="styleObject"></div>
      </div>

      <div class="mask half" :style="styleObject">
        <div class="fill" :style="styleObject"></div>
      </div>

      <div
        class="inside-circle d-flex align-items-center justify-content-center"
        :style="styleObject"
      >
        <p class="mb-0 circle-title">{{ score.toFixed(1) }}</p>
        <p class="circle-subtitle mt-4">/10</p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/styles/common.scss";
.circle-wrap {
  margin: 50px auto;
  width: 150px;
  height: 150px;
  background: $color-grey-lighter3;
  border-radius: 50%;

  .circle {
    .mask,
    .fill {
      width: 150px;
      height: 150px;
      position: absolute;
      border-radius: 50%;
    }

    .mask {
      clip: rect(0px, 150px, 150px, 75px);

      .fill {
        clip: rect(0px, 75px, 150px, 0px);
        background-color: var(--branding-primary-color);
      }

      &.full,
      .fill {
        animation: fill ease-in-out 0.5s;
        transform: rotate(var(--percentage-degree));

        @keyframes fill {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(var(--percentage-degree));
          }
        }
      }
    }

    .inside-circle {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      background: white;
      text-align: center;
      margin-top: 25px;
      margin-left: 25px;
      position: absolute;
      z-index: 100;
    }

    .circle-title {
      font-size: 45px;
      font-weight: 700;
      color: $color-font-headings;

      .circle-subtitle {
        font-size: 15px;
        font-weight: 700;
        color: $color-font-headings;
      }
    }
  }
}
</style>

<script>
export default {
  name: "ProgressBarCircle",
  props: {
    percentage: {
      type: Number,
      default: 0
    },
    score: {
      type: Number,
      default: 0
    },
    title: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      progressColor: "#ff3d3d",
      ratingStatus: "Bad"
    };
  },
  computed: {
    styleObject: function () {
      return {
        "--percentage-degree": this.percentage * 1.8 + "deg",
        "--branding-primary-color": this.progressColor
      };
    }
  },
  created() {
    if (this.score < 2.5) {
      this.progressColor = "#ff3d3d";
      this.ratingStatus = "Bad Score";
    } else if (this.score >= 2.5 && this.score < 5) {
      this.progressColor = "#ef9e81";
      this.ratingStatus = "Weak Score";
    } else if (this.score >= 5 && this.score < 7.5) {
      this.progressColor = "#fff954";
      this.ratingStatus = "Good Score";
    } else if (this.score >= 7.5) {
      this.progressColor = "#6bdb58";
      this.ratingStatus = "Great Score";
    }
  }
};
</script>