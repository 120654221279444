<template>
  <div
    class="mobile-deal-breakdown"
    :style="styleObject"
    v-if="deal.numbers && typeof deal.numbers !== undefined && deal.numbers.length > 0"
  >
    <b-row
      class="mobile-option-card reducer-box-shadow m-3"
      v-for="numberObj in deal.numbers"
      :key="numberObj.number"
      v-show="numberObj.number"
    >
      <!-- IMAGE COL -->
      <b-col cols="12" lg="2" class="d-flex justify-content-center align-items-center p-0">
        <b-img
          v-if="numberObj.handset && numberObj.handset.s3ImageUrl"
          class="handset-image"
          :src="numberObj.handset.s3ImageUrl"
        />
        <b-img
          v-else-if="numberObj.handset"
          class="handset-image p-2"
          :src="require('@/assets/images/mobile.png')"
        />
        <b-img
          v-else-if="numberObj.simOnly"
          class="handset-image p-2 sim-drop-shadow"
          :src="require('@/assets/images/simcard.png')"
        />
        <b-img v-else class="handset-image p-2" :src="require('@/assets/images/mobile.png')" />
      </b-col>

      <b-col>
        <b-row>
          <b-col cols="12" class="mt-3 mb-1">
            <h5 v-if="numberObj.simOnly">
              <b>SIM Only</b>
            </h5>
            <h5 v-else-if="numberObj.unknownHandset">Unknown handset type</h5>
            <h5 v-else-if="numberObj.handset">
              <b>{{ numberObj.handset.brand }} {{ numberObj.handset.model }}</b>
            </h5>
            <h5 v-else-if="numberObj.handsetType">{{ numberObj.handsetType }}</h5>
            <h5 v-else>Unknown handset type</h5>
          </b-col>

          <!-- DETAILS LEFT SIDE -->
          <b-col cols="12" lg="6" class="bordered-col">
            <p v-if="numberObj.providerId && suppliers && suppliers[numberObj.providerId]">
              Provider
              <b class="float-right">{{ suppliers[numberObj.providerId].name }}</b>
            </p>

            <p>
              Upfront cost
              <b class="float-right"
                >{{ $currencySymbol
                }}{{
                  numberObj.oneOffCost
                    ? formatNumberToDisplay(numberObj.oneOffCost.toFixed(2))
                    : "0"
                }}</b
              >
            </p>
            <p>
              Monthly cost
              <b class="float-right"
                >{{ $currencySymbol
                }}{{
                  numberObj.monthlyRental
                    ? formatNumberToDisplay(numberObj.monthlyRental.toFixed(2))
                    : "0"
                }}</b
              >
            </p>
            <p>
              Extra monthly cost
              <b class="float-right"
                >{{ $currencySymbol
                }}{{ numberObj.extraMonthlyCost ? numberObj.extraMonthlyCost : "0" }}</b
              >
            </p>
          </b-col>

          <!-- DETAILS RIGHT SIDE -->
          <b-col cols="12" lg="6" class="unbordered-col">
            <p v-if="numberObj.simOnly && numberObj.handsetType">
              HandsetType
              <b class="float-right">{{ numberObj.handsetType }}</b>
            </p>

            <p>
              Texts
              <b class="float-right">
                <span v-if="numberObj.unlimitedTexts">Unlimited</span>
                <span v-else>{{ numberObj.texts ? numberObj.texts : "--" }}</span>
              </b>
            </p>

            <p>
              Minutes
              <b class="float-right">
                <span v-if="numberObj.unlimitedMinutes">Unlimited</span>
                <span v-else>{{ numberObj.minutes ? numberObj.minutes : "--" }}</span>
              </b>
            </p>

            <p>
              Data
              <b class="float-right">
                <span v-if="numberObj.unlimitedInternetAllowance">Unlimited</span>
                <span v-else>{{
                  numberObj.internetAllowance ? numberObj.internetAllowance : "--"
                }}</span>
              </b>
            </p>

            <p v-if="numberObj.extras">
              Extras
              <b class="float-right">
                <span>{{ numberObj.extras }}</span>
              </b>
            </p>
          </b-col>
        </b-row>
      </b-col>

      <b-col cols="12" class="mt-3 mb-3">
        <div v-if="totalNumbers(numberObj.number) < 4" class="mobile-numbers-text">
          <span
            >Numbers included in the package: <b>{{ numbersToDisplay(numberObj.number) }}</b></span
          >
        </div>
        <div v-else class="mobile-numbers-text">
          <u class="pr-2 pointer" @click="showNumbers = !showNumbers"
            >{{ totalNumbers(numberObj.number) }} numbers included in the package.</u
          >
          <p v-show="showNumbers">{{ numbersToDisplay(numberObj.number) }}</p>
        </div>
      </b-col>
    </b-row>
  </div>
  <div v-else class="text-center">
    <p>No number breakdown available.</p>
  </div>
</template>

<style lang="scss">
@import "@/styles/common.scss";

.mobile-deal-breakdown {
  p {
    color: $color-font-headings;
  }
  .mobile-option-card {
    border-radius: 1rem;

    .sim-drop-shadow {
      filter: drop-shadow(2px 3px 2px $color-grey-lighter2);
    }

    .handset-image {
      height: 140px;
      color: $color-grey-lighter2;
    }

    .bordered-col {
      border-right: 1px solid $color-grey-lighter3;
    }

    .mobile-numbers-text {
      font-size: 15px;
      b {
        font-weight: 500;
      }
    }

    @media screen and (max-width: 991px) {
      .bordered-col {
        margin-bottom: 0 !important;
      }

      .unbordered-col {
        border-right: none;
        margin: 0 !important;
      }
    }
  }
}
</style>

<script>
import FormatHelper from "@/helper/formathelper";

export default {
  name: "MobileDealBreakdown",
  props: {
    deal: Object,
    color: {
      type: String,
      default: "#F40C8F"
    },
    suppliers: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      showNumbers: false
    };
  },
  computed: {
    styleObject: function () {
      return {
        "--branding-primary-color": this.color
      };
    }
  },
  methods: {
    formatNumberToDisplay: FormatHelper.formatNumberToDisplay,
    totalNumbers(number) {
      if (number) {
        if (number.includes("[")) {
          return JSON.parse(number).length;
        } else return 1;
      }
      return 0;
    },
    numbersToDisplay(numbers) {
      let str = "";
      let numbersCount = this.totalNumbers(numbers);
      if (numbersCount != 0) {
        if (numbersCount > 1) {
          JSON.parse(numbers).forEach((n, i) => {
            str += n;
            if (numbersCount - 1 != i) str += ", ";
          });
        } else {
          str = numbers;
        }
      }
      return str;
    }
  }
};
</script>
