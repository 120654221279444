import Console from "@/console";

class Savings {
  constructor(supplyTypes, vm) {
    this.supplyTypes = supplyTypes;
    this.orgName = "";
    this.supplies = {};
    this.deals = {};
    this.savings = {};
    this.vm = vm;
  }
  updateDeals(supplies, deals, includeCommission) {
    this.supplies = supplies;
    this.deals = deals;
    this.calcSavings(includeCommission);
  }
  updateCategoryDeals(supplies, deals, includeCommission, categories) {
    this.supplies = supplies;
    this.deals = deals;
    this.calcSavings(includeCommission, categories);
  }
  updateName(name) {
    this.orgName = name;
  }

  sortSuppliesAndDeals(savingsObj) {
    for (var [typename, typeObj] of Object.entries(savingsObj["REPORT"])) {
      if (typename == "CUSTOM") {
        for (var [fieldName, fieldObj] of Object.entries(typeObj)) {
          if (typeof fieldObj === "object" && fieldName != "supplies") {
            this.orderSupplies(fieldObj);
            this.orderDeals(fieldObj);
          }
        }
      } else if (typeObj) {
        this.orderSupplies(typeObj);
        this.orderDeals(typeObj);
      }
    }
  }

  orderSupplies(typeObj) {
    //ORDER SUPPLIES WITH SAVINGS FIRST
    if ((typeObj.savings || typeObj.savings == 0) && Object.keys(typeObj.supplies).length > 1) {
      var sortedSupplies = new Object();

      //Sort the values and remember key order
      var keysSorted = Object.keys(typeObj.supplies).sort(function (a, b) {
        if (
          typeObj.supplies[a].saving > typeObj.supplies[b].saving ||
          typeof typeObj.supplies[b].saving == "undefined"
        )
          return -1;
        else if (
          typeObj.supplies[a].saving < typeObj.supplies[b].saving ||
          typeof typeObj.supplies[a].saving == "undefined"
        )
          return 1;
        else return 0;
      });

      //Create an object with the ordered elements
      for (var i = 0; i < keysSorted.length; i++) {
        var key = keysSorted[i];
        sortedSupplies[key] = typeObj.supplies[key];
      }

      //Set the original object to the newly ordered object
      typeObj.supplies = sortedSupplies;
    }
  }

  orderDeals(typeObj) {
    //ORDER DEALS WITHIN WACH SUPPLY -> SUPPLIERS -> DEALS
    if (typeObj.savings || typeObj.savings == 0) {
      for (var supply of Object.values(typeObj.supplies)) {
        if (typeof supply.deals != "undefined") {
          var sorted = supply.deals.sort(function (a, b) {
            // if (a.contractTerm < b.contractTerm) return -1;
            // else if (a.contractTerm > b.contractTerm) return 1;
            if (a.annualCost < b.annualCost) return -1;
            else return 1;
          });

          supply.deals = sorted;
        }
      }
    }
  }

  calcSavings(includeCommission, categories) {
    if (!categories) categories = this.supplyTypes;

    var calculatedSavingsObj = this.initialiseCalculation();

    Console.log("Calculating savings for categories: ", categories.join(", "));

    categories.forEach(category => {
      this.calculateCategorySavings(
        this.supplies,
        calculatedSavingsObj,
        category,
        includeCommission
      );
    });

    if (categories.includes("CUSTOM")) {
      let customTypes = new Set();
      var customSupplies = {};
      this.setCustomSupplyTypes(customTypes, customSupplies);

      customTypes.forEach(category => {
        this.calculateCategorySavings(
          customSupplies,
          calculatedSavingsObj,
          category,
          includeCommission
        );
      });
    }

    if (categories.length == 1) {
      this.finaliseCategoryCalculation(calculatedSavingsObj, includeCommission, categories[0]);
    } else {
      this.finaliseCalculation(calculatedSavingsObj, includeCommission);
    }
  }

  initialiseCalculation() {
    var calculatedSavingsObj = {
      totalsavings: 0,
      totalcommission: 0,
      totalswitches: 0,
      reportCategories: {},
      categoryCommission: {},
      categorySavings: {},
      categoryNoQuotes: {},
      notReportCategories: {},
      newSaving: {},
      allSupplySavings: {},
      allSupplyCommissions: {},
      allSupplyNoQuotes: {},
      allDealSavings: {}
    };
    calculatedSavingsObj.newSaving["SUPPLIERS"] = [];
    return calculatedSavingsObj;
  }

  setCustomSupplyTypes(customTypes, customSupplies) {
    this.supplies["CUSTOM"].forEach(supply => {
      if (typeof supply.customTypeName == "undefined") {
        supply["customTypeName"] = "Default custom name";
      }

      if (supply.customTypeName) {
        customTypes.add(supply.customTypeName);
        var sups = customSupplies[supply.customTypeName];
        if (!sups) {
          sups = [];
        }
        sups.push(supply);
        customSupplies[supply.customTypeName] = sups;
        this.supplies[supply.customTypeName] = customSupplies[supply.customTypeName];
      }
    });
  }

  calculateCategorySavings(supplies, calculatedSavingsObj, category, includeCommission) {
    var categorySupplies = {};
    var categorysaving = 0;
    var categoryNoQuotes = {};
    var categorycommission = 0;
    var categoryswitches = 0;
    var categoryissues = 0;
    var categoryunpublished = 0;

    categorySupplies = supplies[category];
    var reportCategory = {};
    var reportSupplies = {};
    var notReportCategory = {};
    var notReportSupplies = {};

    categorySupplies.forEach(supply => {
      var newSupply = supply;
      var supplysaving;
      var recommendedDeal;
      var currentDeal;
      var deals = this.deals[supply.supplyId];

      if (supply.noQuotes) {
        categoryNoQuotes = supply.noQuotes;
        calculatedSavingsObj.allSupplyNoQuotes[supply.supplyId] = supply.noQuotes;
      }

      if (deals) {
        // Filter out blank deals
        deals = deals.filter(d => (d.draftId || d.dealId) && d.published);

        deals.forEach(deal => {
          // Record current and recommended deals
          if (deal.state == "CURRENT") currentDeal = deal;
          if (deal.recommended) recommendedDeal = deal;

          // Record suppliers (mobile number providers too)
          if (
            deal.supplierId &&
            calculatedSavingsObj.newSaving["SUPPLIERS"].indexOf(deal.supplierId) === -1
          ) {
            calculatedSavingsObj.newSaving["SUPPLIERS"].push(deal.supplierId);
          }
          if (deal.type == "MOBILE" && typeof deal.numbers != "undefined") {
            deal.numbers.forEach(number => {
              if (
                number.providerId &&
                calculatedSavingsObj.newSaving["SUPPLIERS"].indexOf(number.providerId) === -1
              ) {
                calculatedSavingsObj.newSaving["SUPPLIERS"].push(number.providerId);
              }
            });
          }
        });

        if (currentDeal) newSupply["currentDeal"] = currentDeal;
        if (recommendedDeal) newSupply["recommendedDeal"] = recommendedDeal;

        if (currentDeal && recommendedDeal) {
          deals.forEach(deal => {
            // Calculate deal saving, set using Vue.$set to make sure DOM is re-rendered
            let saving = null;
            if (currentDeal.projectedAnnualCost > currentDeal.annualCost) {
              saving = currentDeal.projectedAnnualCost - deal.annualCost;
              this.vm.$set(
                deal,
                "projectedSaving",
                recommendedDeal.annualCost - currentDeal.projectedAnnualCost
              );
            } else {
              saving = currentDeal.annualCost - deal.annualCost;
            }
            this.vm.$set(deal, "saving", !saving || saving < 0 ? 0 : +saving.toFixed(2));

            if (supply.published) {
              if (deal.dealId) {
                calculatedSavingsObj.allDealSavings[deal.dealId] = deal.saving;
              } else {
                calculatedSavingsObj.allDealSavings[deal.draftId] = deal.saving;
              }
            }
          });

          // Record info for category switches/savings/etc.
          if (supply.published && !supply.noQuotes) {
            // Calculate recommended deal commission
            recommendedDeal.consumptionCommission =
              !recommendedDeal.consumptionCommission || recommendedDeal.consumptionCommission < 0
                ? 0
                : +parseFloat(recommendedDeal.consumptionCommission).toFixed(2);

            // Calculate supply saving
            if (currentDeal.projectedAnnualCost > currentDeal.annualCost) {
              supplysaving = currentDeal.projectedAnnualCost - recommendedDeal.annualCost;
            } else {
              supplysaving = currentDeal.annualCost - recommendedDeal.annualCost;
            }
            supplysaving = !supplysaving || supplysaving < 0 ? 0 : +supplysaving.toFixed(2);

            // Record/reset supply/deal info
            newSupply["saving"] = supplysaving;
            newSupply["deals"] = deals;
            this.vm.$delete(newSupply, "nosaving");

            categoryswitches += 1;
            categorysaving += Math.floor(supplysaving);
            categorycommission += recommendedDeal.consumptionCommission;
            calculatedSavingsObj.allSupplySavings[supply.supplyId] = supplysaving;

            // Record commission if required
            if (includeCommission) {
              newSupply["commission"] = recommendedDeal.consumptionCommission;
              calculatedSavingsObj.allSupplyCommissions[supply.supplyId] =
                recommendedDeal.consumptionCommission;
            }

            if (category != "CUSTOM") {
              calculatedSavingsObj.totalswitches += 1;
              calculatedSavingsObj.totalcommission += recommendedDeal.consumptionCommission;
              calculatedSavingsObj.totalsavings += Math.floor(supplysaving);
            }
          }
        } else {
          categoryissues += 1;
          this.vm.$set(newSupply, "nosaving", "No current or recommended");
          delete newSupply.saving;
        }
      } else if (!supply.noQuotes) {
        categoryissues += 1;
        this.vm.$set(newSupply, "nosaving", "No deals");
        delete newSupply.saving;
      }

      if (!supply.published) {
        categoryunpublished += 1;
      }

      // Published supplies that have a recommended and current deal, or have a no quote reason will be added to report
      if (supply.published && ((currentDeal && recommendedDeal) || supply.noQuotes)) {
        reportSupplies[supply.supplyId] = newSupply;
      } else {
        notReportSupplies[supply.supplyId] = newSupply;
      }
    });

    if (Object.keys(reportSupplies).length > 0) {
      reportCategory["supplies"] = reportSupplies;
      reportCategory["switches"] = categoryswitches;

      if (categorycommission > 0 && includeCommission) {
        reportCategory["commission"] = categorycommission;
        calculatedSavingsObj.categoryCommission[category] = categorycommission;
      }

      if (categoryswitches > 0) {
        reportCategory["savings"] = categorysaving;
        calculatedSavingsObj.categorySavings[category] = categorysaving;
      } else {
        calculatedSavingsObj.categoryNoQuotes[category] = categoryNoQuotes;
      }

      if (this.supplyTypes.includes(category)) {
        calculatedSavingsObj.reportCategories[category] = reportCategory;
      } else {
        calculatedSavingsObj.reportCategories["CUSTOM"][category] = reportCategory;
      }
    }

    if (Object.keys(notReportSupplies).length > 0) {
      notReportCategory["supplies"] = notReportSupplies;
      notReportCategory["issues"] = categoryissues;
      notReportCategory["unpublished"] = categoryunpublished;

      if (categorycommission > 0 && includeCommission) {
        notReportCategory["commission"] = categorycommission;
      }

      if (categoryswitches > 0) {
        notReportCategory["savings"] = categorysaving;
      }

      if (this.supplyTypes.includes(category)) {
        calculatedSavingsObj.notReportCategories[category] = notReportCategory;
      } else {
        calculatedSavingsObj.notReportCategories["CUSTOM"][category] = notReportCategory;
      }
    }
  }

  finaliseCalculation(calculatedSavingsObj, includeCommission) {
    if (includeCommission) {
      calculatedSavingsObj.newSaving["TYPECOMMISION"] = calculatedSavingsObj.categoryCommission;
      calculatedSavingsObj.newSaving["COMMISSION"] = calculatedSavingsObj.totalcommission;
      calculatedSavingsObj.newSaving["NOTREPORT"] = calculatedSavingsObj.notReportCategories;
      calculatedSavingsObj.newSaving["SUPPLYCOMMISSION"] =
        calculatedSavingsObj.allSupplyCommissions;
    }
    calculatedSavingsObj.newSaving["SUPPLYSAVINGS"] = calculatedSavingsObj.allSupplySavings;
    calculatedSavingsObj.newSaving["SUPPLYNOQUOTES"] = calculatedSavingsObj.allSupplyNoQuotes;
    calculatedSavingsObj.newSaving["DEALSAVINGS"] = calculatedSavingsObj.allDealSavings;
    calculatedSavingsObj.newSaving["ORGNAME"] = this.orgName;
    calculatedSavingsObj.newSaving["REPORT"] = calculatedSavingsObj.reportCategories;
    calculatedSavingsObj.newSaving["TYPESAVINGS"] = calculatedSavingsObj.categorySavings;
    calculatedSavingsObj.newSaving["TYPENOQUOTES"] = calculatedSavingsObj.categoryNoQuotes;
    calculatedSavingsObj.newSaving["SWITCHES"] = calculatedSavingsObj.totalswitches;
    calculatedSavingsObj.newSaving["TOTAL"] = calculatedSavingsObj.totalsavings;

    calculatedSavingsObj.newSaving["BRANDINGID"] = calculatedSavingsObj.brandingId;

    this.savings = calculatedSavingsObj.newSaving;
  }

  finaliseCategoryCalculation(calculatedSavingsObj, includeCommission, category) {
    const valOrZero = val => (val ? val : 0);
    if (includeCommission) {
      this.savings.NOTREPORT[category] = calculatedSavingsObj.notReportCategories[category];
      this.savings.TYPECOMMISION[category] = valOrZero(
        calculatedSavingsObj.categoryCommission[category]
      );
      this.savings.SUPPLYCOMMISSION = Object.assign(
        this.savings.SUPPLYCOMMISSION,
        calculatedSavingsObj.allSupplyCommissions
      );

      this.savings.COMMISSION = 0;
      Object.entries(this.savings.TYPECOMMISION).forEach(([type, comm]) => {
        if (this.supplyTypes.includes(type)) this.savings.COMMISSION += comm;
      });
    }
    this.savings.SUPPLYSAVINGS = Object.assign(
      this.savings.SUPPLYSAVINGS,
      calculatedSavingsObj.allSupplySavings
    );
    this.savings.SUPPLYNOQUOTES = Object.assign(
      this.savings.SUPPLYNOQUOTES,
      calculatedSavingsObj.allSupplyNoQuotes
    );
    this.savings.DEALSAVINGS = Object.assign(
      this.savings.DEALSAVINGS,
      calculatedSavingsObj.allDealSavings
    );
    if (calculatedSavingsObj.reportCategories[category] != undefined) {
      this.savings.REPORT[category] = calculatedSavingsObj.reportCategories[category];
    }
    this.savings.TYPESAVINGS[category] = valOrZero(calculatedSavingsObj.categorySavings[category]);
    this.savings.TYPENOQUOTES[category] = calculatedSavingsObj.categoryNoQuotes[category];

    this.savings.TOTAL = 0;
    Object.entries(this.savings.TYPESAVINGS).forEach(([type, saving]) => {
      if (this.supplyTypes.includes(type)) this.savings.TOTAL += saving;
    });
  }
}

export default Savings;
