import FormatHelper from "@/helper/formathelper";
import DealDetailsModal from "@/components/savingsreport/DealDetailsModal";
import TakeDealModal from "@/components/savingsreport/TakeDealModal";
import DemoSavingsReportModal from "@/components/savingsreport/DemoSavingsReportModal";
import SupplierDetailsModal from "@/components/savingsreport/SupplierDetailsModal";

export const otherSavingOptionsMixin = {
  props: {
    supply: Object,
    organisationId: String,
    created: String,
    demo: Boolean,
    suppliers: Object,
    category: String,
    primaryColor: {
      type: String,
      default: "#F40C8F"
    },
    secondaryColor: {
      type: String,
      default: "#007BFF"
    },
    solidTakeDeal: {
      type: Boolean,
      default: false
    },
    showPlaceholders: Boolean,
    contract: Object
  },
  components: {
    DealDetailsModal,
    TakeDealModal,
    DemoSavingsReportModal,
    SupplierDetailsModal
  },
  data() {
    return {
      dealDetails: {},
      takeDeal: null,
      demoModal: false,
      supplierDetails: null
    };
  },
  computed: {
    styleObject: function() {
      return {
        "--branding-primary-color": this.primaryColor,
        "--branding-secondary-color": this.secondaryColor
      };
    },
    hasActiveContract() {
      return this.contract && (this.contract.state == "OUT" || this.contract.state == "SIGNED");
    },
    offers() {
      let offs = [];
      if (this.supply.deals) {
        offs = this.supply.deals.filter(d => d.state == "FUTURE");
      }
      return offs;
    }
  },
  methods: {
    formatNumberToDisplay: FormatHelper.formatNumberToDisplay,
    formatMonthsToYears: FormatHelper.formatMonthsToYears,

    openSupplierDetailsModal(id) {
      if (this.suppliers[id]) {
        this.supplierDetails = Object.assign({}, this.suppliers[id]);
      } else {
        this.supplierDetails = new Object();
      }
    },

    handleTakeDeal(deal) {
      if (!this.demo) this.takeDeal = deal;
      else this.demoModal = !this.demoModal;
    },

    hasEnoughData(deal) {
      let nrOfData = 0;

      if (deal && deal.supplierId && this.suppliers && this.suppliers[deal.supplierId]) {
        const supplier = this.suppliers[deal.supplierId];

        if (supplier) {
          if (supplier.reducerScore && supplier.reducerScore != 0) {
            nrOfData++;
          }
          if (supplier.logos3url) {
            nrOfData++;
          }
          if (supplier.shortDescription && supplier.shortDescription.length > 0) {
            nrOfData++;
          }
          if (supplier.keyPoints && supplier.keyPoints.length > 0) {
            nrOfData++;
          }
        }
      }

      return nrOfData > 1 ? true : false;
    },

    displayInfoBadge(deal) {
      return deal.recommended || (this.hasActiveContract && this.contract.dealId == deal.dealId);
    }
  }
};
