<template>
  <div v-if="isAdmin" id="report-admin-options-container">
    <ShareSavingsReport
      :report="reportToShare"
      :primaryColor="branding.primaryColor"
      :secondaryColor="branding.secondaryColor"
      @cancelled="reportToShare = null"
      @sharedSuccessfully="reportToShare = null"
    />
    <DemoSavingsReportModal :demoModal="demoModal" :color="branding.primaryColor" />
    <BrandingEditor
      ref="brandingEditor"
      :organisationName="orgShortName(organisation.name)"
      @change-detected="fetchBrandings"
    />
    <UpdateOrganisationModal
      :showUpdateOrgModal="modals.showUpdateOrgModal"
      :organisation="organisation"
      :videoOnly="true"
      @cancelled="modals.showUpdateOrgModal = false"
    />
    <EditReportSharedModal
      ref="editReportSharedModal"
      :genericInfo="genericInfo"
      :organisationId="organisationId"
    ></EditReportSharedModal>
    <SavingsHistoryModal
      ref="savingsHistory"
      :organisationId="organisationId"
      @loadSaving="loadSaving"
    />
    <!-- <CallNumberModal ref="numberModal" /> -->

    <b-row class="admin-card" align-v="center">
      <div class="corner-buttons">
        <b-button class="open-non-admin" size="sm" @click="$emit('clientPreview')"
          ><font-awesome-icon :icon="['far', 'eye']" v-b-popover.hover.top="'Open non-admin view'"
        /></b-button>
        <b-button
          class="show-placeholders"
          size="sm"
          @click="$emit('showPlaceholders')"
          v-b-popover.hover.top="'Mark placeholder offers'"
          ><font-awesome-icon :icon="['far', 'marker']"
        /></b-button>
      </div>

      <b-col cols="12" lg="6">
        <b-button-group class="mt-1 w-100">
          <b-input-group-text>Branding</b-input-group-text>

          <b-button v-if="loadingBrandings" variant="outline-secondary">
            <Busy class="m-0 loading-brandings" :size="1" />
          </b-button>

          <b-select v-else :value="branding" v-on:change="applyBranding" :options="brandings">
            <template #first>
              <b-form-select-option :value="defaultBranding">{{
                defaultBranding.brandingName
              }}</b-form-select-option>
            </template>
          </b-select>

          <b-button
            variant="outline-secondary"
            @click="$refs.brandingEditor.show()"
            :disabled="demo"
          >
            <font-awesome-icon
              :icon="['fas', 'paint-brush']"
              v-b-popover.hover.top="'Add/Edit Brandings'"
            />
          </b-button>
        </b-button-group>

        <b-skeleton-wrapper v-if="!preview" :loading="loading">
          <template #loading>
            <div class="m-4 pb-0 w-100">
              <b-skeleton width="85%" class="my-3"></b-skeleton>
              <b-skeleton width="55%" class="my-3"></b-skeleton>
              <b-skeleton width="70%" class="my-3"></b-skeleton>
              <b-skeleton width="55%" class="my-3"></b-skeleton>
            </div>
          </template>

          <div class="info-card mt-2" v-if="!preview">
            <p v-if="genericInfo.createdDate" class="mb-0 line">
              Published:
              <strong class="float-right">{{
                formatDateTimeCustom(genericInfo.createdDate, "DD MMM YYYY HH:MM")
              }}</strong>
            </p>
            <p v-else class="mb-0 line">
              Published by: <strong class="float-right">No data</strong>
            </p>
            <p v-if="genericInfo.createdByEmail" class="mb-0 line">
              Published by:
              <strong class="float-right">{{ genericInfo.createdByEmail }}</strong>
            </p>
            <p v-else class="mb-0 line">
              Published by: <strong class="float-right">No data</strong>
            </p>
            <p v-if="genericInfo.orgFirstUserEmail" class="mb-0 line">
              First user:
              <strong class="float-right">{{ genericInfo.orgFirstUserEmail }}</strong>
            </p>
            <p v-else class="mb-0 line">
              Published by: <strong class="float-right">No data</strong>
            </p>
            <p v-if="genericInfo.orgLastExtractUserEmail" class="mb-0">
              Last Extract user:
              <strong class="float-right">{{ genericInfo.orgLastExtractUserEmail }}</strong>
            </p>
            <p v-else class="mb-0">Published by: <strong class="float-right">No data</strong></p>
          </div>
        </b-skeleton-wrapper>
      </b-col>

      <b-col cols="12" lg="6" class="buttons-box justify-content-center mt-3 mt-lg-0">
        <b-button variant="outline-secondary" class="m-1" @click.stop="goToEditor"
          >Savings Editor</b-button
        >

        <b-button
          variant="outline-secondary"
          v-if="!preview"
          class="m-1"
          size="md"
          :disabled="archived || publishBusy"
          @click.stop="openShareModal"
        >
          <Busy v-if="publishBusy" class="d-inline-block" :margin="0" :size="1" />
          <span v-else>Share&nbsp;Report</span>
        </b-button>
        <b-button
          class="m-1 secondary-designed"
          v-else
          id="publishButton"
          v-bind:disabled="publishBusy"
          @click.stop="checkForUnsavedChanges()"
        >
          <Busy v-if="publishBusy" class="d-inline-block" :margin="0" :size="1" />
          <span v-else>Publish&nbsp;Report</span>
        </b-button>

        <b-button
          v-if="!preview"
          class="m-1"
          v-bind:disabled="publishBusy"
          @click.stop="
            $emit('setArchived', !archived);
            publishReport('save', !archived);
          "
          variant="outline-secondary"
        >
          <Busy :margin="0" v-if="publishBusy" :size="1" class="d-inline-block" />
          <span v-else-if="!archived">Archive report</span>
          <span v-else> Keep report </span>
        </b-button>
        <b-button
          v-if="!preview"
          variant="outline-secondary"
          class="m-1"
          @click.stop="openSavingsHistory"
        >
          Savings&nbsp;History
        </b-button>
        <b-button
          v-if="!preview && isDev"
          class="m-1"
          variant="outline-secondary"
          @click.stop="logSavingsToConsole"
          >Log</b-button
        >
        <b-button
          v-if="!preview"
          variant="outline-secondary"
          class="m-1"
          @click="toggleModal('showUpdateOrgModal')"
          >Add video</b-button
        >
        <b-button
          variant="outline-secondary"
          class="m-1"
          v-if="!preview"
          @click="$refs.editReportSharedModal.show()"
        >
          Update shared date
        </b-button>
        <b-dropdown v-if="!preview" variant="outline-secondary" class="m-1" text="Download PDF">
          <template v-slot:button-content>
            <span v-if="!pdfBusy">Download PDF</span>
            <div v-else class="d-inline-block">
              <Busy class="d-inline-block" :margin="0" :size="1" />
            </div>
          </template>

          <b-dropdown-item @click.stop="downloadPDF('Reducer')">Reducer Branded</b-dropdown-item>
          <b-dropdown-item @click.stop="downloadPDF('Armstrong-Watson')"
            >Armstrong Watson Branded</b-dropdown-item
          >
        </b-dropdown>
        <!-- <b-button
          variant="outline-secondary"
          class="m-1"
          v-if="!preview"
          @click="$refs.numberModal.show(organisation, organisationId)"
        >
          Make a call
        </b-button> -->
      </b-col>
    </b-row>
  </div>
</template>

<style lang="scss">
@import "@/styles/common.scss";

#report-admin-options-container {
  .admin-card {
    border-radius: 10px;
    padding: 1rem;
    border: 1px solid $color-grey-lighter3;
    position: relative;

    .loading-brandings {
      padding: 0 9rem 0 9rem;
    }

    .corner-buttons {
      position: absolute;
      right: 0;
      top: 0;
      display: flex;
      flex-flow: column;
      .btn {
        padding: 0.2rem 0.35rem 0.2rem 0.35rem;
        border-bottom: none;
        border-top: none;
        border-radius: 0px;
        &:first-child {
          border-top-right-radius: 10px;
        }
        &:last-child {
          border-bottom-left-radius: 10px;
        }
        &.open-non-admin {
          background: $color-font-headings;
        }
      }
    }

    .info-card {
      border-radius: 10px;
      padding: 0.5rem 1rem 0.5rem 1rem;

      & > p {
        color: $color-font-headings;
        padding: 0.4rem 0 0.4rem 0;
        font-size: 15px;
      }
      .line {
        border-bottom: 1px solid $color-grey-lighter3;
      }
    }

    .buttons-box {
      display: flex;
      flex-wrap: wrap;
      & > button,
      div > button {
        color: $color-font-headings;
        &:hover {
          background-color: $color-font-headings;
          color: white;
          &:disabled {
            background-color: white;
            color: $color-font-headings;
          }
        }
      }

      .secondary-designed {
        background-color: $color-font-headings;
        color: white;
      }

      &.big {
        & > button,
        div {
          flex-grow: 0;
          width: 31.5%;
        }
      }

      & > button,
      div {
        flex-grow: 0;
        width: 47.5%;
      }
    }
  }
}
</style>

<script>
import DemoSavingsReportModal from "@/components/savingsreport/DemoSavingsReportModal";
import FormatHelper from "@/helper/formathelper";
import GeneratePDF from "@/helper/generatePdf";
import axios from "axios";
import Busy from "@/components/Busy";
import UpdateOrganisationModal from "@/components/savingsreport/UpdateOrganisationModal";
import BrandingEditor from "@/components/savingsreport/BrandingEditor";
import { EventBus } from "@/components/eventbus";
import SupplyTypes from "@/helper/supplytypehelper";
import ApiHelper from "@/helper/apihelper";
import Console from "@/console";
import EditReportSharedModal from "@/components/savingsreport/EditReportSharedModal";
import SavingsHistoryModal from "@/components/savingsreport/SavingsHistoryModal";
import ShareSavingsReport from "@/components/modals/ShareSavingsReport";
import CognitoAuth from "@/cognito/cognitoauth";

export default {
  name: "ReportAdminOptions",
  props: {
    preview: Boolean,
    archived: Boolean,
    viewed: String,
    loading: {
      type: Boolean,
      default: false
    },
    isAdmin: {
      type: Boolean,
      default: false
    },
    demo: {
      type: Boolean,
      default: false
    },
    genericInfo: Object,
    organisationId: String,
    organisation: Object,
    savingsDemoObject: Object,
    branding: Object,

    suppliers: Object,
    savings: Object
  },
  components: {
    Busy,
    DemoSavingsReportModal,
    UpdateOrganisationModal,
    BrandingEditor,
    EditReportSharedModal,
    ShareSavingsReport,
    SavingsHistoryModal
  },

  data() {
    return {
      visibleAdminOption: false,
      isDev: false,
      demoModal: false,
      publishBusy: false,
      pdfBusy: false,
      historicSavings: [],
      loadingHistory: false,
      reportToShare: null,

      defaultBranding: {
        brandingId: null,
        brandingName: "Default",
        logoS3Filename: null,
        primaryColor: "#ff0090",
        secondaryColor: "#007BFF",
        gradientTopColor: "#ff0090",
        gradientBottomColor: "#007cf9",
        solidTakeDeal: false
      },
      loadingBrandings: false,
      brandings: [],

      modals: {
        showUpdateOrgModal: false
      }
    };
  },
  async created() {
    this.fetchBrandings();
    this.isDev = await CognitoAuth.isCurrentUserDev();
  },
  methods: {
    formatDateTimeCustom: FormatHelper.formatDateTimeCustom,
    orgShortName: FormatHelper.orgShortName,

    openSavingsHistory() {
      if (!this.demo) {
        this.$refs.savingsHistory.show();
      } else {
        this.demoModal = !this.demoModal;
      }
    },

    async previewReport() {
      this.$router.push({
        name: "savings/" + this.organisationId,
        params: {
          savingProp: this.savingsObject,
          demo: false,
          clientPreview: true
        }
      });
    },

    openShareModal() {
      if (!this.demo) {
        var obj = new Object();

        obj["created"] = this.genericInfo.createdDate;
        obj["organisationId"] = this.organisationId;

        this.reportToShare = obj;
      } else {
        this.demoModal = !this.demoModal;
      }
    },

    checkForUnsavedChanges() {
      if (this.$route.params.unsavedData) {
        EventBus.$emit("show-unsaved-data-modal", () =>
          this.publishReport("publish", this.archived)
        );
      } else {
        this.publishReport("publish", this.archived);
      }
    },

    async publishReport(type, archived) {
      var saveSavingRequest = {};

      if (type == "publish") {
        saveSavingRequest.state = "complete";
        saveSavingRequest.totalSavings = this.savings.TOTAL;
        saveSavingRequest.totalCommission = this.savings.COMMISSION;
        saveSavingRequest.switches = this.savings.SWITCHES;
        saveSavingRequest.supplyTypeSavings = this.savings.TYPESAVINGS;
        saveSavingRequest.supplyTypeNoQuotes = this.savings.TYPENOQUOTES;
        saveSavingRequest.supplyTypeCommission = this.savings.TYPECOMMISION;
        saveSavingRequest.supplySavings = this.savings.SUPPLYSAVINGS;
        saveSavingRequest.supplyNoQuotes = this.savings.SUPPLYNOQUOTES;
        saveSavingRequest.supplyCommission = this.savings.SUPPLYCOMMISSION;
        saveSavingRequest.brandingId = this.branding.brandingId;
      }

      saveSavingRequest.archived = archived;
      saveSavingRequest.viewed = this.viewed;

      this.publishBusy = true;
      try {
        let client = await ApiHelper.http();
        var response = await client.post(`${ApiHelper.endPoint()}savings/${this.organisationId}`, {
          saving: saveSavingRequest,
          type: type
        });
        Console.log("Publish report :", response);
        if (response.data.status === "success") {
          this.$emit("setPreview", false);
          this.$emit("fetchData");
          this.showAlert("The report has been published", "success");
        } else {
          this.showAlert("There was a problem publishing the report.", "warning");
        }
      } catch {
        this.showAlert("There was a problem publishing the report.", "warning");
      } finally {
        this.publishBusy = false;
      }
    },

    goToEditor() {
      if (!this.demo) {
        this.$router.push({
          name: "savings-editor",
          params: { organisationId: this.organisationId }
        });
      } else {
        this.demoModal = !this.demoModal;
      }
    },

    loadSaving(saving) {
      this.$emit("loadSaving", saving);
    },

    toggleModal(property) {
      if (!this.demo) {
        this.modals[property] = true;
      } else {
        this.demoModal = true;
      }
    },

    logSavingsToConsole() {
      //remove commissions and email addresses before logging
      const recursiveRemoveKey = (object, deleteKey) => {
        if (object != null) {
          delete object[deleteKey];

          Object.values(object).forEach(val => {
            if (typeof val !== "object") return;

            recursiveRemoveKey(val, deleteKey);
          });
        }
      };

      delete this.savingsDemoObject.totalCommission;
      delete this.savingsDemoObject.supplyTypeCommission;
      delete this.savingsDemoObject.supplyCommission;
      delete this.savingsDemoObject.supplies;
      delete this.savingsDemoObject.deals;
      delete this.savingsDemoObject.totalSavings;
      delete this.savingsDemoObject.supplyTypeSavings;
      delete this.savingsDemoObject.supplyTypeNoQuotes;
      delete this.savingsDemoObject.switches;
      delete this.savingsDemoObject.supplySavings;
      delete this.savingsDemoObject.supplyNoQuotes;
      delete this.savingsDemoObject.orgLastExtractUserId;
      delete this.savingsDemoObject.organisationId;
      delete this.savingsDemoObject.createdDate;
      delete this.savingsDemoObject.version;
      delete this.savingsDemoObject.shared;
      recursiveRemoveKey(this.savingsDemoObject, "consumptionCommission");
      recursiveRemoveKey(this.savingsDemoObject, "commission");
      recursiveRemoveKey(this.savingsDemoObject, "createdByUserEmail");
      recursiveRemoveKey(this.savingsDemoObject, "createdByEmail");
      recursiveRemoveKey(this.savingsDemoObject, "lastUpdatedByUserEmail");
      recursiveRemoveKey(this.savingsDemoObject, "orgLastExtractUserEmail");
      recursiveRemoveKey(this.savingsDemoObject, "orgFirstUserEmail");
      this.savingsDemoObject.orgName = "Reducer Demo";
      // eslint-disable-next-line
      console.log(JSON.stringify(this.savingsDemoObject));
      // eslint-disable-next-line
      console.log(JSON.stringify(this.branding));
    },

    //PDF GENERATION
    getImageByLocalPath(url) {
      var getUrl = window.location;
      var baseUrl = getUrl.protocol + "//" + getUrl.host + "/" + getUrl.pathname.split("/")[1];

      var img = new Image();
      img.src = baseUrl + "/report/" + url;
      return img;
    },

    async downloadImage(url) {
      if (url) {
        var base64 = await axios
          .get(url, {
            responseType: "arraybuffer",
            headers: {
              Authorization: ""
            }
          })
          .then(response => Buffer.from(response.data, "binary").toString("base64"));
        var img = new Image();
        img.src = "data:image/jpeg;base64, " + base64;
      }

      return img;
    },

    async downloadPDF(pdfBrandingType) {
      var images = {};

      try {
        this.pdfBusy = true;

        if (!this.demo && pdfBrandingType != "Armstrong-Watson") {
          //load supplier images (but don't need them for Armstrong-Watson reports)
          for (let [key, value] of Object.entries(this.suppliers)) {
            if (value && value.logos3url) {
              var img = await this.downloadImage(value.logos3url);
              images[key] = img;
            }
          }
        }

        //load supply type icons
        for (var i = 0; i < SupplyTypes.supplyTypes().length; i++) {
          var type = SupplyTypes.supplyTypes()[i];
          images[type.toLowerCase()] = await this.getImageByLocalPath(
            "icons/" + type.toLowerCase() + ".png"
          );
          images[type.toLowerCase() + "_disabled"] = await this.getImageByLocalPath(
            "icons/" + type.toLowerCase() + "_disabled.png"
          );
        }

        //load additional icons
        images.reducer = await this.getImageByLocalPath("reducer.png");
        if (pdfBrandingType == "Reducer") {
          images.reducer_pink = await this.getImageByLocalPath("reducer_pink.png");
          images.handshake = await this.getImageByLocalPath("handshake.png");
          images.consultation = await this.getImageByLocalPath("consultation_pink.png");
          images.trustpilot = await this.getImageByLocalPath("trustpilot.png");
        } else {
          images.aw = await this.getImageByLocalPath("aw.png");
        }

        //prepare report data
        var report = {};
        report.switches = this.savings.SWITCHES;
        report.orgName = this.organisation.name;
        report.suppliers = this.suppliers;
        report.savings = this.savings.TOTAL;
        report.images = images;
        report.supplyData = this.savings.REPORT;

        GeneratePDF.generate(report, pdfBrandingType);
      } finally {
        this.pdfBusy = false;
      }
    },

    //BRANDINGS
    applyBranding(value) {
      this.$emit("applyBranding", value, "update");
    },

    async fetchBrandings() {
      this.loadingBrandings = true;
      try {
        const client = await ApiHelper.http();
        const response = await client.get(`${ApiHelper.endPoint()}brandings`);
        if (response.data.status === "success") {
          this.brandings = [];

          for (var i = 0; i < response.data.brandings.length; i++) {
            let obj = {};
            obj.value = response.data.brandings[i];
            obj.text = response.data.brandings[i].brandingName;

            this.brandings.push(obj);
          }

          this.sortBrandings();
        } else {
          this.showAlert("A problem occured in searching for data.", "warning");
        }
      } catch (error) {
        this.showAlert("A problem occured in searching for data.", "warning");
      } finally {
        this.loadingBrandings = false;
      }
    },

    sortBrandings() {
      if (this.brandings.length > 0) {
        this.brandings.sort((a, b) => (a.text.toUpperCase() > b.text.toUpperCase() ? 1 : -1));
      }
    },

    showAlert(message, variant) {
      EventBus.$emit("show-toast", { message: message, variant: variant });
    }
  }
};
</script>
