<template>
  <b-modal
    size="md"
    v-model="showModal"
    id="edit-report-shared-modal"
    ref="editReportSharedModal"
    hide-header
    :cancel-disabled="busySaving"
    :ok-disabled="busySaving"
    :no-close-on-backdrop="busySaving"
    :no-close-on-esc="busySaving"
    :ok-title="!busySaving ? 'Save' : 'Saving ...'"
    @ok="saveShared"
  >
    <h5>Choose a new shared date</h5>
    <p v-if="genericInfo.shared" class="small-text">
      Current shared date is {{ formatDateTimeToMin(genericInfo.shared) }}
    </p>

    <p id="errorMessage" v-if="invalidInput" class="px-2 py-1">Invalid input</p>

    <b-input-group>
      <b-form-input v-model="sharedDate" type="text" placeholder="YYYY-MM-DD"></b-form-input>
      <b-input-group-append>
        <b-form-datepicker
          v-model="sharedDate"
          button-only
          button-variant="outline-secondary"
        ></b-form-datepicker>
      </b-input-group-append>
      <b-button class="ml-2" @click="today"> Today </b-button>
    </b-input-group>

    <h5 class="mt-4">
      The email address you shared it with <span class="small-text">(optional)</span> :
    </h5>
    <p class="small-text">
      If you share a report manually, by sending the client a magic link, no
      <i>shared-report</i> organisation event is recorded in the system. If you provide us with the
      email address, we can record the <i>shared-report</i> event.
    </p>
    <b-input v-model="email"></b-input>
  </b-modal>
</template>

<style lang="scss">
@import "@/styles/common.scss";

#edit-report-shared-modal {
  #errorMessage {
    background-color: lightcoral;
    color: white;
    border-radius: 10px;
  }

  .small-text {
    font-size: 13px;
  }
}
</style>

<script>
import FormatHelper from "@/helper/formathelper";
import ApiHelper from "@/helper/apihelper";
import { EventBus } from "@/components/eventbus";
import Console from "@/console";

export default {
  name: "EditReportSharedModal",
  props: {
    genericInfo: Object,
    organisationId: String
  },
  data() {
    return {
      showModal: false,
      sharedDate: null,
      email: null,
      busySaving: false,
      invalidInput: false
    };
  },

  methods: {
    formatDateTimeToMin: FormatHelper.formatDateTimeToMin,
    formatDateTimeCustom: FormatHelper.formatDateTimeCustom,
    formatDateToLocalDateTime: FormatHelper.formatDateToLocalDateTime,

    clearForm() {
      this.email = null;
      this.sharedDate = null;
    },

    show() {
      this.showModal = true;
    },

    hide() {
      this.showModal = false;
    },

    today() {
      this.sharedDate = this.formatDateTimeCustom(
        new Date().toISOString("YYYY-MM-DD"),
        "YYYY-MM-DD"
      );
    },

    showMessage(message, type) {
      EventBus.$emit("show-toast", { message: message, variant: type });
    },

    validateInput() {
      const regexExp = new RegExp("20[23][0-9]-[01][0-9]-[0-3][0-9]");
      return regexExp.test(this.sharedDate);
    },

    async saveShared(evt) {
      this.invalidInput = false;
      evt.preventDefault();
      if (!this.validateInput()) {
        this.invalidInput = true;
      } else {
        if (this.sharedDate) {
          this.busySaving = true;
          try {
            let client = await ApiHelper.http();
            const response = await client.post(
              `${ApiHelper.endPoint()}savings/dateshared?organisationId=${
                this.organisationId
              }&shared=${this.sharedDate + "T00:00:01.000001"}&email=${this.email}`
            );
            if (response.data.status === "success") {
              this.genericInfo.shared = this.sharedDate;
              this.clearForm();
              this.showMessage("Savings report shared date updated successfully", "success");
            } else {
              this.showMessage("A problem occured while updating report shared date", "warning");
            }
          } catch (err) {
            console.error(err);
            this.showMessage("A problem occured while updating report shared date", "warning");
          } finally {
            this.busySaving = false;
            this.hide();
          }
        } else {
          this.invalidInput = true;
        }
      }
    }
  }
};
</script>