<template>
  <div v-if="Object.keys(navItems).length > 1" id="side-nav">
    <b-nav vertical pills v-for="cat in possibleNavItems" :key="cat">
      <b-nav-item class="supplierTypes hide" v-if="cat in navItems" @click="scrollToRef(cat)">
        <div
          class="side-nav-circle d-flex align-items-center justify-content-center"
          :style="styleObject"
        >
          <SavingsIcon class="side-nav-button savingsIcon" :supplyType="cat" disabled />
        </div>
      </b-nav-item>
    </b-nav>

    <b-nav vertical pills id="togglerHamburger">
      <b-nav-item class="sideNavToggler animate" :style="styleObject" @click="toggleItems()">
        <div
          class="side-nav-circle d-flex align-items-center justify-content-center"
          :style="styleObject"
        >
          <font-awesome-icon class="side-nav-button hamburger" :icon="['fas', 'bars']" />
        </div>
      </b-nav-item>
    </b-nav>
  </div>
</template>

<style lang="scss">
@import "@/styles/common.scss";
#side-nav {
  z-index: 1000;
  left: 2%;
  position: fixed;

  .side-nav-circle {
    height: 45px;
    width: 45px;
    border-radius: 50%;
    box-shadow: 2px 2px 6px rgba($color: $color-font-para, $alpha: 0.5);
    background-color: white;

    &:hover {
      box-shadow: var(--box-color);
    }
  }

  .side-nav-button {
    font-size: 1.4rem;

    &.savingsIcon {
      color: $color-font-para !important;
      &.active {
        color: var(--color) !important;
      }
    }
  }

  .sideNavToggler {
    .hamburger {
      color: var(--color);
      // -moz-transition: all 0.5s linear;
      // -webkit-transition: all 0.5s linear;
      // transition: all 0.5s linear;
    }

    .side-nav-circle {
      background-color: white;
    }

    &.animate {
      .hamburger {
        color: white;
        // -ms-transform: rotate(180deg);
        // -moz-transform: rotate(180deg);
        // -webkit-transform: rotate(180deg);
        // transform: rotate(180deg);
      }

      .side-nav-circle {
        background-color: var(--color);
      }
    }
  }

  //SIDE-NAV WHEN THE SCREEN IS LARGE
  @media only screen and (min-width: 1500px) and (min-height: 800px) {
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);

    #togglerHamburger {
      display: none;
    }
    .supplierTypes.hide {
      pointer-events: auto;
      opacity: 1;
      transition: opacity 1s;
    }

    .supplierTypes {
      pointer-events: auto;
      opacity: 1;
      transition: opacity 1s;
    }
  }

  //SIDE-NAV WHEN THE SCREEN IS SMALL
  @media only screen and (max-width: 1499px), (max-height: 799px) {
    margin-bottom: 20px;
    bottom: 0;

    .supplierTypes {
      pointer-events: auto;
      opacity: 1;
      transition: opacity 1s;
    }

    .supplierTypes.hide {
      pointer-events: none;
      opacity: 0;
      transition: opacity 1s;
      transition-delay: 0.1s;
      transition: position 0.5s;
      position: fixed;
      z-index: 0;
    }
  }
}
</style>

<script>
import SavingsIcon from "@/components/SavingsIcon";

export default {
  name: "SideNavigation",
  props: {
    navItems: Object,
    possibleNavItems: Array,
    color: {
      type: String,
      default: "#F40C8F"
    }
  },
  components: {
    SavingsIcon
  },
  computed: {
    styleObject: function () {
      return {
        "--box-color": "2px 2px 6px " + this.color,
        "--color": this.color
      };
    }
  },
  methods: {
    toggleItems() {
      var ham = document.getElementsByClassName("sideNavToggler")[0];
      var bubbles = document.getElementsByClassName("supplierTypes");
      if (ham.className.includes("animate")) {
        //CLOSING
        for (var i = 0; i < bubbles.length; i++) {
          bubbles[i].className = bubbles[i].className.replace(" hide", "");
        }

        ham.className = ham.className.replace(" animate", "");
      } else {
        //OPENING
        for (var j = 0; j < bubbles.length; j++) {
          bubbles[j].className += " hide";
        }

        ham.className += " animate";
      }
    },

    scrollToRef(id) {
      this.$emit("scrollToRef", id);
      if (window.matchMedia("screen and (max-width: 1499px), (max-height: 799px)").matches) {
        this.toggleItems();
      }
    },

    activateSideBar(id) {
      var sectionButtons = document.getElementsByClassName("side-nav-button");
      for (var i = 0; i < sectionButtons.length; i++) {
        sectionButtons[i].className.baseVal = sectionButtons[i].className.baseVal.replace(
          " active",
          ""
        );
      }

      var sectionButton = document.getElementById(id + "_icon");
      sectionButton.className.baseVal += " active";
    }
  }
};
</script>
