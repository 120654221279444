<template>
  <div id="savings-banner" :class="['pb-4 pb-lg-0 px-3', { isDemo: demo }]">
    <b-row class="align-items-center justify-content-between">
      <b-col id="savings-banner-get-in-touch" cols="12" md="6" lg="4" class="py-3">
        <div class="dark-grey-bg py-2 px-3 px-md-4 d-flex flex-column text-center">
          <h5 class="mb-1"><b>Get in touch</b></h5>
          <p class="mb-2">We're on hand Mon-Fri 9am-5pm</p>
          <b>Email</b>
          <a v-if="demo" href="mailto:hello@reducer.co.uk"><u>hello@reducer.co.uk</u></a>
          <a v-else href="mailto:support@reducer.co.uk"><u>support@reducer.co.uk</u></a>
          <b class="mt-2">Phone</b>
          <i v-if="demo" class="small">Unavailable on our demo</i>
          <a v-else href="tel:+442039704884" class="text-reset">+44 20 4571 7704</a>
        </div>
      </b-col>

      <b-col cols="12" md="6" lg="3">
        <XeroTrustpilotReviews stacked />
      </b-col>

      <b-col id="savings-banner-team" cols="12" lg="4" class="text-center mt-3 mt-lg-0">
        <h5 class="mb-4"><b>Meet our client support team</b></h5>
        <div class="d-flex w-100 justify-content-around justify-content-lg-between">
          <div v-for="(member, index) in team" :key="index" class="team-member">
            <div class="avatar-container">
              <img
                :src="require(`@/assets/images/team/${member.image}`)"
                :alt="member.name"
                class="avatar"
              />
              <svg viewBox="0 0 100 100" width="100" height="100" class="curved-text">
                <path id="curve" fill="transparent" d="M30,90 A40,40 0 0,1 70,30" />
                <text width="100">
                  <textPath xlink:href="#curve" startOffset="50%">
                    <tspan class="name-text">{{ member.name }}</tspan>
                  </textPath>
                </text>
              </svg>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import XeroTrustpilotReviews from "@/components/XeroTrustpilotReviews";
export default {
  name: "SavingsBanner",
  components: { XeroTrustpilotReviews },
  props: {
    demo: Boolean
  },
  data() {
    return {
      team: [
        { name: "Amar", image: "amar.jpg" },
        { name: "Rishi", image: "rishi.jpg" },
        { name: "Paige", image: "paige.jpg" }
      ]
    };
  }
};
</script>

<style lang="scss">
#savings-banner {
  @import "@/styles/common.scss";
  background-color: whitesmoke;
  h5,
  span,
  b,
  p {
    color: $color-font-headings;
  }

  h5 {
    font-size: 21px;
    letter-spacing: 0.5px;
    line-height: 26px;
  }

  .dark-grey-bg {
    background-color: $color-grey-lighter3;
  }

  .star-rating {
    svg {
      color: $color-yellow-red1;
      font-size: 19px;
    }
  }

  .small {
    font-size: 14px;
  }

  &.isDemo {
    text-align: center;
    .xero-rating {
      flex-flow: column;
      img {
        margin-bottom: 0.5rem;
        margin-right: 0 !important;
      }
    }
  }

  #xero-trustpilot-reviews {
    @media only screen and (max-width: 767px) {
      a {
        cursor: not-allowed;
        pointer-events: none;
      }
      span {
        text-decoration: none !important;
      }
    }
    a:hover {
      span {
        text-decoration: underline;
      }
    }
  }

  .team-member {
    position: relative;
    width: 100px;
    height: 100px;
  }

  .avatar-container {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .avatar {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }

  .curved-text {
    position: absolute;
    top: -38px;
    left: -37px;
    width: 125px;
    height: 120px;
    transform: rotate(-2deg);
  }

  .name-text {
    fill: $color-font-headings;
    font-size: 12px;
    font-weight: bold;
  }

  @media only screen and (min-width: 1139px) {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
    #savings-banner-get-in-touch {
      max-width: 22rem;
    }
    #xero-trustpilot-reviews {
      max-width: 25rem;
    }
    #savings-banner-team {
      min-width: 26rem;
      pointer-events: none;
    }
  }
}
</style>
