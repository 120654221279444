<template>
  <div id="savings">
    <vue-headful :title="title + ($appName ? ' | ' + $appName : '')" />
    <b-container>
      <div v-if="isAdmin && !clientPreview && !busy" class="mb-3">
        <ReportAdminOptions
          :isAdmin="isAdmin"
          :demo="demo"
          :organisationId="organisationId"
          :organisation="organisation"
          :savingsDemoObject="savingsDemoObject"
          :branding="branding"
          :suppliers="suppliers"
          :genericInfo="genericInfo"
          :loading="busy"
          :savings="savings"
          :preview="preview"
          :viewed="viewed"
          :archived="archived"
          @setPreview="setPreview"
          @setArchived="setArchived"
          @loadSaving="loadSaving"
          @applyBranding="applyBranding"
          @clientPreview="clientPreview = true"
          @showPlaceholders="showPlaceholders = !showPlaceholders"
          @fetchData="fetchData"
        />
      </div>

      <div v-if="busy" class="text-center">
        <Busy primary :size="4" />
        <p class="mt-4 mb-2 text-primary">Preparing your savings report</p>
        <span class="m-0" style="font-size: 30px">&#128640;</span>
      </div>

      <div v-else-if="error">
        <p>{{ error }}</p>
      </div>

      <div v-else>
        <h1 v-if="organisationName">
          {{ orgShortName(organisationName) }}
        </h1>
        <h1 v-else>Savings</h1>
        <DemoSavingsReportModal :demoModal="demoModal" :color="branding.primaryColor" />
        <SideNavigation
          ref="sideNavigation"
          :navItems="savings['REPORT']"
          :possibleNavItems="supplyTypes"
          :color="branding.primaryColor"
          @scrollToRef="scrollTo"
        />

        <b-row class="d-flex justify-content-center align-items-center p-0 mt-3 mb-4">
          <!-- HEADLINE SAVING -->
          <b-col lg="3" md="12" class="d-flex justify-content-center flex-wrap align-items-center">
            <div v-if="branding.logoS3FileUrl" class="my-lg-2 mb-4 w-100 text-center">
              <b-img class="brand-logo" :src="branding.logoS3FileUrl" fluid />
            </div>
            <div class="p-2">
              <GradientCircle
                :colorA="branding.gradientTopColor"
                :colorB="branding.gradientBottomColor"
                size="165px"
                thickness="7px"
                class="main-circle"
              >
                <h2 class="headlinePrice">
                  <b>{{
                    savings["TOTAL"]
                      ? $currencySymbol + formatNumberToDisplay(savings["TOTAL"])
                      : $currencySymbol + "0"
                  }}</b>
                </h2>
                <h2 class="headlineTitle">Annual Savings</h2>
              </GradientCircle>
            </div>

            <div class="p-2">
              <GradientCircle
                :colorA="branding.gradientTopColor"
                :colorB="branding.gradientBottomColor"
                size="165px"
                thickness="7px"
                class="main-circle"
              >
                <h2 class="headlinePrice">
                  <b>{{ savings["SWITCHES"] ? savings["SWITCHES"] : "0" }}</b>
                </h2>
                <h2 v-if="savings['SWITCHES'].toFixed(0) > 1" class="headlineTitle">
                  Suggested <br />
                  Switches
                </h2>
                <h2 v-else class="headlineTitle">
                  Suggested <br />
                  Switch
                </h2>
              </GradientCircle>
            </div>
          </b-col>

          <!-- SAVINGS BY SUPPLY TYPE -->
          <b-col lg="9" md="12" class="mt-3 mt-lg-0 pt-2">
            <b-row>
              <b-col
                v-for="cat in supplyTypes"
                :key="cat"
                cols="6"
                md="4"
                class="pb-3 px-lg-4 py-lg-3 main-icons"
              >
                <div
                  v-if="cat in savings['REPORT'] && savings.REPORT[cat].savings"
                  @click="scrollTo(cat)"
                  :class="`savings-general-buttons savings-found type-${cat.toLowerCase()}`"
                >
                  <div
                    class="accent-stripe"
                    :style="`background: ${branding.gradientTopColor}`"
                  ></div>
                  <div class="main-icon-wrapper">
                    <SavingsIcon
                      color="#3A3C3F"
                      :supplyType="cat"
                      class="m-auto main-icon"
                      disabled
                    />
                  </div>

                  <h4 class="main-text d-none d-md-block">{{ supplyTypesDisplay(cat) }}</h4>
                  <h4 class="main-text d-md-none">{{ shortSupplyTypes(cat) }}</h4>

                  <h4 class="main-text mt-4">
                    <span class="save-text">Save</span>
                    <b class="savings-amount">
                      {{ $currencySymbol + formatNumberToDisplay(savings["REPORT"][cat].savings) }}
                    </b>
                  </h4>
                </div>

                <div
                  v-else
                  :class="`savings-general-buttons savings-not-found type-${cat.toLowerCase()}`"
                >
                  <div class="main-icon-wrapper">
                    <SavingsIcon :supplyType="cat" class="m-auto main-icon" disabled />
                  </div>

                  <h4 class="nosaving main-text d-none d-md-block">
                    {{ supplyTypesDisplay(cat) }}
                  </h4>
                  <h4 class="nosaving main-text d-md-none">{{ shortSupplyTypes(cat) }}</h4>

                  <h4 v-if="cat in savings['REPORT']" class="nosaving main-text mt-4">
                    No Savings
                  </h4>
                  <h4 v-else class="nosaving main-text mt-4">Not Found</h4>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <div class="d-flex justify-content-center text-center w-100">
          <SavingsReportVideo
            v-if="!busy"
            :videoId="demo ? demoVideoId : organisation.savingsVideoId"
          ></SavingsReportVideo>
        </div>

        <SavingsBanner v-if="$appType == 'reducer'" :demo="demo" class="my-4" />

        <div v-for="(categoryObj, category) in savings['REPORT']" :key="category" :id="category">
          <div v-if="category != 'CUSTOM'">
            <h1 class="mb-3 mt-5 h1-hr" v-bind:style="{ 'border-color': branding.primaryColor }">
              {{ supplyTypesDisplay(category) }}
            </h1>

            <div v-for="(supply, supplyName) in categoryObj.supplies" :key="supplyName">
              <SavingsCategoryCard
                :supply="supply"
                :suppliers="suppliers"
                :category="category"
                :organisationId="organisationId"
                :created="genericInfo.createdDate"
                :demo="demo"
                :branding="branding"
                :showPlaceholders="showPlaceholders"
                :contract="contracts[supply.supplyId]"
              ></SavingsCategoryCard>
            </div>
          </div>

          <div v-else>
            <h1 class="mb-3 mt-5 h1-hr" v-bind:style="{ 'border-color': branding.primaryColor }">
              Other
            </h1>

            <div v-for="(fieldObj, fieldName) in categoryObj" :key="fieldName">
              <div v-if="typeof fieldObj === 'object' && fieldName != 'supplies'">
                <h3 class="pb-2 h3-hr">{{ fieldName }}</h3>

                <div v-for="(supply, supplyName) in fieldObj.supplies" :key="supplyName">
                  <SavingsCategoryCard
                    :supply="supply"
                    :suppliers="suppliers"
                    :category="category"
                    :organisationId="organisationId"
                    :created="genericInfo.createdDate"
                    :demo="demo"
                    :branding="branding"
                    :contract="contracts[supply.supplyId]"
                  ></SavingsCategoryCard>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ReportFooter
          v-if="$appType == 'reducer'"
          :savings="savings"
          :genericInfo="genericInfo"
          :branding="branding"
          :demo="demo"
          :preview="preview"
          :organisationId="organisationId"
        />
      </div>
    </b-container>
  </div>
</template>

<style lang="scss">
@import "@/styles/common.scss";

#savings {
  .brand-logo {
    max-height: 6rem;
    max-width: 10rem;
  }

  h1 {
    font-size: 2rem;
  }

  h3 {
    font-size: 1.6rem;
  }

  .h1-hr,
  .h3-hr {
    border-bottom: 0.1em solid $color-font-para;
  }

  .reducer-pink {
    color: $color-pink-main;
  }

  .main-icons {
    .savings-general-buttons {
      transition: all 300ms ease-in-out;
      background-color: $color-grey-lighter5;
      padding: 1rem;
      border-radius: 10px;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
      text-align: left;
      position: relative;
      width: 100%;
      height: 100px;
      overflow: hidden;
      &.savings-found:hover {
        transform: translateY(-5px);
        cursor: pointer;
      }
      &.savings-not-found {
        pointer-events: none;
      }
      .accent-stripe {
        position: absolute;
        width: 0.35rem;
        height: 100%;
        left: 0;
        top: 0;
      }
      .main-icon-wrapper {
        position: absolute;
        right: 0px;
        top: 0px;
        width: 3rem;
        height: 3rem;
        display: flex;
        .main-icon {
          font-size: 22px;
        }
      }
      .main-text {
        padding-left: 0.3rem;
        font-size: 18px;
        .savings-amount {
          font-size: 20px;
        }
      }
    }
  }

  .headlineTitle {
    font-size: 1.1rem;
    margin: 0px;
    padding-top: 5px;
  }
  .headlinePrice {
    font-size: 2.3rem;
    margin: 0px;
  }
  .nosaving {
    color: $color-grey-lighter1;
  }

  @media only screen and (max-width: 767px) {
    .savings-not-found.type-custom {
      display: none;
    }
    #maincircle {
      width: 140px !important;
      height: 140px !important;
    }
    .headlinePrice {
      font-size: 1.8rem !important;
    }
    .headlineTitle {
      font-size: 1rem !important;
    }
  }
}
.green-tariff-color {
  background-color: $color-green !important;
}
</style>

<script>
/* eslint-disable no-undef */
import ApiHelper from "@/helper/apihelper";
import Console from "@/console";
import CognitoAuth from "@/cognito/cognitoauth";
import SupplyTypes from "@/helper/supplytypehelper";
import FormatHelper from "@/helper/formathelper";
import Savings from "@/helper/savings";
import OrganisationEventHelper from "@/helper/organisationeventhelper";
import Busy from "@/components/Busy";
import { EventBus } from "@/components/eventbus";
import SavingsIcon from "@/components/SavingsIcon";
import GradientCircle from "@/components/savingsreport/GradientCircle";
import SavingsCategoryCard from "@/components/savingsreport/SavingsCategoryCard";
import SideNavigation from "@/components/savingsreport/SideNavigation";
import SavingsReportVideo from "@/components/savingsreport/SavingsReportVideo";
import DemoSavingsReportObject from "@/assets/demo_savings_report";
import DemoSavingsReportModal from "@/components/savingsreport/DemoSavingsReportModal";
import ReportAdminOptions from "@/components/savingsreport/ReportAdminOptions";
import TokenHelper from "@/cognito/tokenhelper";
import { userleap, mouseflow } from "@/config/reducerproperties";
import ReportFooter from "@/components/savingsreport/ReportFooter";
import SavingsBanner from "@/components/savingsreport/SavingsBanner";

export default {
  name: "savings",
  components: {
    Busy,
    GradientCircle,
    SavingsIcon,
    SavingsCategoryCard,
    SavingsReportVideo,
    SideNavigation,
    DemoSavingsReportModal,
    ReportAdminOptions,
    ReportFooter,
    SavingsBanner
  },
  props: {
    savingProp: Object,
    demo: Boolean,
    demoBranding: Object,
    previewOrganisation: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      title: "Savings",
      busy: true,
      error: null,
      guide: false,
      preview: null,
      isAdmin: false,
      organisationId: null,
      clientPreview: false,
      showPlaceholders: false,
      organisationName: null,
      genericInfo: {
        createdByEmail: null,
        createdDate: null,
        orgLastExtractUserEmail: null,
        orgFirstUserEmail: null,
        shared: null
      },
      savings: {},
      supplies: {},
      deals: {},
      supplyTypes: [],
      savingsObject: null,
      archived: false,
      viewed: null,
      suppliers: {},
      sourceUsers: [],
      savingsDemoObject: {},
      demoModal: false,
      initialBranding: {},
      branding: {
        brandingId: null,
        brandingName: "Default",
        logoS3Filename: null,
        primaryColor: "#ff0090",
        secondaryColor: "#007BFF",
        gradientTopColor: "#ff0090",
        gradientBottomColor: "#007cf9",
        solidTakeDeal: false
      },
      organisation: {},
      videoBusy: false,
      demoVideoId: "4ecc8f9bec0d46999d0bdc59ec617fe1",
      contracts: {},
      scrolledToDiv: false
    };
  },
  beforeCreate() {
    //DISABLE USERLEAP
    /*
    var userLeapHeader = document.createElement("script");
    var attName = "type";
    var attValue = "text/javascript";
    var scriptsrc =
      "  (function(l,e,a,p) { " +
      " if (window.UserLeap) return;" +
      " window.UserLeap = function(){U._queue.push(arguments)}; " +
      " var U = window.UserLeap;U.appId = a; U._queue = [];" +
      " a=l.createElement('script');" +
      " a.async=1;a.src=e+'?id='+U.appId;" +
      " p=l.getElementsByTagName('script')[0];" +
      " p.parentNode.insertBefore(a, p);" +
      "})(document, 'https://cdn.userleap.com/shim.js', '" +
      userleap.id +
      "');";
    userLeapHeader.innerHTML = scriptsrc;
    userLeapHeader.setAttribute(attName, attValue);
    document.head.appendChild(userLeapHeader);
    */
  },
  created() {
    this.initialBranding = Object.assign({}, this.branding);
    this.refreshAdmin();
    if (!this.demo) this.organisationId = this.$route.params.organisationId;

    this.supplyTypes = SupplyTypes.supplyTypes();
    this.savingsObject = new Savings(this.supplyTypes, this);
    if (this.savingProp) {
      this.preview = true;
      this.busy = false;

      var tempSavings = this.savingProp.savings;
      this.savingsObject.sortSuppliesAndDeals(tempSavings);
      this.savings = tempSavings;

      this.organisation = Object.assign({}, this.previewOrganisation);
      this.organisationName = this.organisation.name;

      this.getSuppliersByIds(this.savings.SUPPLIERS);
    } else {
      this.preview = false;
      if (this.demo) {
        this.fetchDemoData();
      } else {
        this.fetchData().then(() => {
          this.setUpMouseFlow();
        });
        // User Leap disabled
        // this.setUpUserLeap();
      }
    }
    this.fetchContracts();

    window.addEventListener("scroll", this.handleScroll);
  },
  updated() {
    let id = this.$route.params.scroll ? this.$route.params.scroll : this.$route.query.scroll;
    if (id && !this.scrolledToDiv) {
      this.scrollToDiv(id);
    }
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    formatDateTimeCustom: FormatHelper.formatDateTimeCustom,
    orgShortName: FormatHelper.orgShortName,
    supplyTypesDisplay: SupplyTypes.toDisplay,
    toGuideType: SupplyTypes.toGuides,
    formatNumberToDisplay: FormatHelper.formatNumberToDisplay,
    formatMonthsToYears: FormatHelper.formatMonthsToYears,
    formatDate: FormatHelper.formatDate,

    scrollToDiv(divid) {
      var element = document.getElementById(divid);
      if (element) {
        var top = element.offsetTop;
        window.scrollTo(0, top);
        this.scrolledToDiv = true;
      }
    },

    async setUpMouseFlow() {
      this.isAdmin = await CognitoAuth.isCurrentUserAdmin();
      this.user = await this.getUserInfo();
      if (!this.isAdmin) {
        if (window.mouseflow) {
          Console.log("Mouseflow already setup");
        } else {
          if (mouseflow && mouseflow.id) {
            Console.log("Mouseflow not found, starting up mouseflow");
            var mouseFlowHeader = document.createElement("script");
            var attName = "type";
            var attValue = "text/javascript";
            var scriptsrc =
              " window._mfq = window._mfq || []; " +
              " (function() { " +
              ' var mf = document.createElement("script"); ' +
              ' mf.type = "text/javascript"; mf.defer = true; ' +
              ' mf.src = "//cdn.mouseflow.com/projects/' +
              mouseflow.id +
              '.js"; ' +
              ' document.getElementsByTagName("head")[0].appendChild(mf); ' +
              " })(); ";
            if (this.organisationName) {
              scriptsrc += ' window._mfq.push(["tag", "' + this.organisationName + '"]);';
            } else {
              console.warn("Organisation name was not available to tag mouseflow session");
            }
            if (this.user && this.user.email) {
              scriptsrc += ' window._mfq.push(["tag", "' + this.user.email + '"]);';
            } else {
              console.warn("User was not available to tag mouseflow session");
            }
            mouseFlowHeader.innerHTML = scriptsrc;
            mouseFlowHeader.setAttribute(attName, attValue);
            document.body.appendChild(mouseFlowHeader);
          } else {
            console.warn("No mouseflow application id was available to start mouseflow.");
          }
        }
      }
    },

    //USER LEAP
    async setUpUserLeap() {
      let user = await this.getUserInfo();
      if (user && typeof user != undefined) {
        UserLeap("setUserId", user.userId);
        UserLeap("setEmail", user.email);
        UserLeap("track", "savingsreport");
      }
    },
    async getUserId() {
      const token = await CognitoAuth.getCurrentUserAccessToken();
      return TokenHelper.parseJwtToken(token).sub;
    },
    async getUserInfo() {
      try {
        let client = await ApiHelper.http();
        var response = await client.get(`${ApiHelper.endPoint()}users/${await this.getUserId()}`);

        if (response.data.status === "success") {
          return response.data.user;
        } else {
          this.showAlert(
            "Sorry, we had a problem getting your user data from our database.",
            "warning"
          );
        }
      } catch (err) {
        Console.error(err);
        this.showAlert(
          "Sorry, we had a problem getting your user data from our database",
          "warning"
        );
      }
    },

    //BRANDING
    async fetchDemoBranding(friendlyName) {
      try {
        const client = await ApiHelper.http();
        const response = await client.get(
          `${ApiHelper.endPoint()}brandings/demo?friendlyName=${friendlyName.toLowerCase()}`
        );
        if (response.data.status === "success") {
          if (response.data.branding) {
            this.branding = response.data.branding;
          } else {
            this.showAlert("We couldn't find any branding with the name provided.", "warning");
            console.warn(
              "The branding friendly name couldn't be fetched. Default branding applied."
            );
          }
        }
      } catch (error) {
        // eslint-disable-next-line
        console.warn("The branding friendly name couldn't be fetched. Default branding applied.");
        //branding just sets to default
      } finally {
        this.busy = false;
      }
    },
    async fetchBranding(brandingIds) {
      try {
        const client = await ApiHelper.http();
        const response = await client.get(
          `${ApiHelper.endPoint()}brandings?brandingIds=${brandingIds}`
        );
        if (response.data.status === "success") {
          var firstElement = Object.keys(response.data.brandings)[0];
          this.branding = response.data.brandings[firstElement];
          this.initialBranding = Object.assign({}, this.branding);
        }
      } catch (error) {
        // eslint-disable-next-line
        console.warn("The branding ID couldn't be fetched. Default branding applied.");
        //branding just sets to default
      }
    },
    applyBranding(brand, type) {
      if (type == "update") {
        this.branding = Object.assign({}, brand);
        if (brand.brandingId != this.initialBranding.brandingId) {
          this.preview = true;
        } else {
          this.preview = false;
        }
      } else if (type == "initial") {
        this.branding = Object.assign({}, this.initialBranding);
        this.preview = false;
      }
    },

    //DEMO REPORT
    fetchDemoData() {
      this.loadSaving(DemoSavingsReportObject);
      this.suppliers = DemoSavingsReportObject.demoSuppliers;

      if (
        this.$route.params.friendlyName &&
        FormatHelper.validFriendlyName(this.$route.params.friendlyName)
      ) {
        this.fetchDemoBranding(this.$route.params.friendlyName);
      } else if (this.demoBranding) {
        this.branding = Object.assign({}, this.demoBranding);
        this.busy = false;
      } else {
        this.busy = false;
      }
    },

    //ORGANISATION CONTRACTS
    async fetchContracts() {
      const client = await ApiHelper.http();
      return client
        .get(`${ApiHelper.endPoint()}contracts/organisations/${this.organisationId}`)
        .then(response => {
          Console.log("Contracts response", response.data.contracts);
          this.contracts = response.data.contracts.reduce(
            (arr, c) => ({ ...arr, [c.supplyId]: c }),
            {}
          );
        })
        .catch(e => {
          Console.error(e);
        });
    },

    //SIDE NAVIGATION
    scrollTo(id) {
      var section = document.getElementById(id);
      section.scrollIntoView({ behavior: "smooth" });
    },
    handleScroll() {
      let mainNavLinks = document.getElementsByClassName("savingsIcon side-nav-button");
      let fromTop = window.scrollY;

      for (let i = 0; i < mainNavLinks.length; i++) {
        let section = document.getElementById(mainNavLinks[i].id.replace("_icon", ""));

        if (
          section != null &&
          section.offsetTop <= fromTop + 450 &&
          section.offsetTop + section.offsetHeight > fromTop + 450
        ) {
          var sectionID = section.id;

          if (
            typeof this.$refs != undefined &&
            this.$refs.supplyCardTitle != undefined &&
            this.$refs.supplyCardTitle[0] != undefined
          ) {
            this.$refs.supplyCardTitle[0].activateGuideSlide(sectionID);
          }

          if (typeof this.$refs.sideNavigation !== "undefined") {
            this.$refs.sideNavigation.activateSideBar(mainNavLinks[i].id.replace("_icon", ""));
          }
        }
      }
    },

    //API
    async fetchOrganisation() {
      this.busy = true;
      try {
        let client = await ApiHelper.http();
        var response = await client.get(
          `${ApiHelper.endPoint()}organisations/${this.organisationId}`
        );
        if (response.data.status === "success") {
          this.organisation = response.data.organisation;
        } else {
          Console.log("Response from Organisation API was not a success", response);
        }
      } catch (err) {
        Console.log("Error loading organisation data", err);
      } finally {
        this.busy = false;
      }
    },
    async fetchData() {
      try {
        let client = await ApiHelper.http();
        var response = await client.get(
          `${ApiHelper.endPoint()}savings?organisationId=${this.organisationId}`
        );
        Console.log("SavingsReport: ", response);
        if (response.data.status === "success") {
          if (response.data.savings[0]) {
            this.savingsDemoObject = response.data.savings[0];
            this.loadSaving(response.data.savings[0]);

            OrganisationEventHelper.sendReportOpened(
              response.data.savings[0].organisationId,
              response.data.savings[0].createdDate
            );
          }
        }
      } catch (error) {
        console.error(error);
        this.showAlert("There was a problem getting the savings report.", "warning");
        this.error = "There was an issue getting that savings report.";
      } finally {
        this.busy = false;
      }
    },

    async refreshAdmin() {
      this.isAdmin = await CognitoAuth.isCurrentUserAdmin();
    },
    loadSaving(saving) {
      this.supplies = saving.suppliesMap;
      this.deals = saving.dealsMap;
      this.organisationName = saving.orgName;
      this.genericInfo.createdByEmail = saving.createdByEmail;
      this.genericInfo.createdDate = saving.createdDate;
      this.genericInfo.orgLastExtractUserEmail = saving.orgLastExtractUserEmail;
      this.genericInfo.orgFirstUserEmail = saving.orgFirstUserEmail;
      this.genericInfo.shared = saving.shared;

      if (typeof saving.brandingId != "undefined") this.fetchBranding([saving.brandingId]);
      this.archived = typeof saving.archived == "undefined" ? false : saving.archived;
      this.viewed = typeof saving.viewed == "undefined" ? null : saving.viewed;

      var tempSavings = {};
      const safeToCalcCommission = Object.prototype.hasOwnProperty.call(saving, "totalCommission");
      this.savingsObject.updateDeals(this.supplies, this.deals, safeToCalcCommission);
      tempSavings = this.savingsObject.savings;
      this.savingsObject.sortSuppliesAndDeals(tempSavings);
      this.savings = tempSavings;

      this.sortSavingsAreas(this.savings.REPORT);

      if (!this.demo) {
        this.fetchOrganisation();
        this.getSuppliersByIds(this.savings.SUPPLIERS);
      }
    },

    sortSavingsAreas(areas) {
      try {
        if (typeof areas !== "undefined" && areas && Object.keys(areas).length > 0) {
          /* SORTING THE SUPPLY CARDS (AREAS) BASED ON SAVINGS
           * areas = { type: {supplyObj} }
           * areas CONTAINS ONLY the areas with savings AND areas with noQuotes
           * we are sortig the 'type' keys based on the supplyObj.savings
           */
          const sortable = Object.fromEntries(
            Object.entries(areas).sort(([, a], [, b]) => {
              //if supplyObj.savings exists sort by it
              if (
                typeof b.savings !== "undefined" &&
                b.savings &&
                typeof a.savings !== "undefined" &&
                a.savings
              ) {
                return b.savings - a.savings;
              }
              //else if one of them does not exist
              else if (
                (typeof b.savings == "undefined" || !b.savings) &&
                typeof a.savings !== "undefined" &&
                a.savings
              ) {
                return -1;
              } else if (
                (typeof a.savings == "undefined" || !a.savings) &&
                typeof b.savings !== "undefined" &&
                b.savings
              ) {
                return 1;
              }
              //else make no changes
              else {
                return 0;
              }
            })
          );

          this.savings.REPORT = Object.assign({}, sortable);

          /*  SORTING top and side menu icons (SUPPLYTYPES) based on the already sorted AREAS keys
           *  supplyTypes CONTAINS ALL the areas we currently support
           *  supplyTypes = [supplyTypesName1, supplyTypesName2, ... ]
           *  areasKeys = [type1, type2, ...]
           */
          this.supplyTypes.sort((a, b) => {
            // if areasKeys contains both supplyType a and b
            // move up the lower indexed supplyType
            if (Object.keys(sortable).indexOf(a) != -1 && Object.keys(sortable).indexOf(b) != -1) {
              if (Object.keys(sortable).indexOf(a) < Object.keys(sortable).indexOf(b)) {
                return -1;
              } else if (Object.keys(sortable).indexOf(a) > Object.keys(sortable).indexOf(b)) {
                return 1;
              } else {
                return 0;
              }
            }
            // if areasKeys does not contain one of the supplyTypes a or b
            // we move down the lower indexed supplyType
            else if (Object.keys(sortable).indexOf(a) < Object.keys(sortable).indexOf(b)) {
              return 1;
            } else if (Object.keys(sortable).indexOf(a) > Object.keys(sortable).indexOf(b)) {
              return -1;
            } else {
              return 0;
            }
          });
        }
      } catch (err) {
        console.error("Savings areas sorting error: ", err);
      }
    },

    async getSuppliersByIds(supplierIds) {
      this.busy = true;
      if (supplierIds && supplierIds.length > 0) {
        try {
          var summarised = true;
          const client = await ApiHelper.http();
          const response = await client.get(
            `${ApiHelper.endPoint()}suppliers?summarised=${summarised}&supplierIds=${supplierIds}`
          );
          if (response.data.status === "success") {
            this.suppliers = response.data.suppliers;
            this.savingsDemoObject["demoSuppliers"] = this.suppliers;
          } else {
            this.showAlert("A problem occured whilst fetching supplier details", "warning");
          }
        } catch (error) {
          Console.error(error);
          this.showAlert("A problem occured whilst fetching supplier details", "warning");
        } finally {
          this.busy = false;
        }
      } else {
        this.busy = false;
      }
    },

    shortSupplyTypes(type) {
      if (type == "CARD_PAYMENTS") {
        return "Card Paym";
      } else {
        return this.supplyTypesDisplay(type);
      }
    },

    setPreview(prev) {
      this.preview = prev;
    },
    setArchived(arch) {
      this.archived = arch;
    },

    showAlert(message, variant) {
      EventBus.$emit("show-toast", { message: message, variant: variant });
    }
  },

  beforeRouteLeave(to, from, next) {
    if (to.name == "savings-editor" && to.params.organisationId == this.organisationId) {
      to.params.savingProp = this.savingProp;
      to.params.suppliers = this.suppliers;
      to.params.latestSupply = this.$route.params.latestSupply;
    }
    next();
  }
};
</script>
