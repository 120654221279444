<template>
  <div id="report-footer">
    <DemoSavingsReportModal :demoModal="demoModal" :color="branding.primaryColor" />
    <ShareSavingsReport
      :report="reportToShare"
      :primaryColor="branding.primaryColor"
      :secondaryColor="branding.secondaryColor"
      @cancelled="reportToShare = null"
      @sharedSuccessfully="reportToShare = null"
    />

    <div id="whats-next-section" class="pt-4">
      <h1 class="section-header">What's Next?</h1>
      <DotCards
        class="pt-3"
        text1="Click the <b>take deal button</b> and we will email a digital contract to you. You are
            not committed to anything until you sign the digital contract."
        text2="We handle the switch for you, including letting your old supplier know you are leaving."
        text3="If you have any questions our customer support team is ready to help. You can call us,
            email us or book a callback for a time that suits you."
      />
    </div>

    <b-row id="get-in-touch-section" class="my-3 mx-3">
      <b-col cols="12" class="mb-2 mb-md-1"><h3>Get in touch with&nbsp;us</h3></b-col>
      <b-col cols="12" md="6" class="text-left">
        <p class="d-block d-lg-inline-block">Monday - Friday</p>
        <p class="d-block d-lg-inline-block">
          <span class="d-none d-lg-inline-block mx-2"> between</span>9am - 5pm.
        </p>
        <b-button
          id="book-a-call-button"
          class="reducer-box-shadow my-2 d-block"
          size="md"
          href="https://calendly.com/reducer-rishi-sharma/meeting"
          target="_blank"
        >
          Book a call</b-button
        >
      </b-col>
      <b-col cols="12" md="6" class="text-md-right">
        <br v-if="demo" />
        <p v-else>+44 20 4571 7704</p>
        <p v-if="demo">hello@reducer.co.uk</p>
        <p v-else>support@reducer.co.uk</p>
        <p>124 City Road, London, EC1V 2NX</p>
      </b-col>
    </b-row>

    <div id="share-report-section" class="mb-4 text-center" v-if="!preview">
      <p class="m-0 mt-4">
        Would you like to share this savings report with your colleagues? Your report is secure and
        only accessible by authorised users. <br />
        Choose to share the report and we will send the email recipient a secure link to open this
        report.
      </p>
      <b-button
        id="share-report-button"
        class="reducer-box-shadow my-3"
        size="md"
        @click.stop="openShareModal"
      >
        <font-awesome-icon :icon="['fas', 'share-alt']" class="mr-2" />
        Share&nbsp;report</b-button
      >
    </div>

    <BusinessCarousel class="pt-4" />

    <b-row id="review-us-section" class="pt-3 justify-content-center">
      <b-col cold="12" md="4" class="my-2">
        <a
          class="review-card reducer-box-shadow"
          href="https://apps.xero.com/uk/app/reducer/reviews"
          target="_blank"
        >
          <img class="mx-2" border="0" height="50px" width="50px" src="@/assets/images/xero.png" />
          <div class="ml-2 ml-md-0 ml-lg-2">
            <h5 class="m-0">
              <span class="mr-2"><b>5.0</b> </span>
              <font-awesome-icon
                v-for="i in 5"
                :key="i"
                class="review-star"
                :icon="['fas', 'star']"
              />
            </h5>

            <u class="d-block mt-1">
              Leave us feedback
              <font-awesome-icon class="ml-1 reducer-pink" :icon="['fas', 'chevron-right']" />
            </u>
          </div>
        </a>
      </b-col>
      <b-col cold="12" md="4" class="my-2">
        <a
          class="review-card reducer-box-shadow"
          href="https://quickbooks.intuit.com/app/apps/appdetails/reducer/en-gb/"
          target="_blank"
        >
          <img class="mx-2" border="0" height="50px" width="50px" src="@/assets/images/qb.png" />
          <div class="ml-2 ml-md-0 ml-lg-2">
            <h5 class="m-0">
              <span class="mr-2"><b>5.0</b></span>
              <font-awesome-icon
                v-for="i in 5"
                :key="i"
                class="review-star"
                :icon="['fas', 'star']"
              />
            </h5>

            <u class="d-block mt-1">
              Leave us feedback
              <font-awesome-icon class="ml-1 reducer-pink" :icon="['fas', 'chevron-right']" />
            </u>
          </div>
        </a>
      </b-col>
      <b-col v-if="!demo" cols="12" md="4" class="my-2">
        <a
          class="review-card reducer-box-shadow"
          href="https://uk.trustpilot.com/review/www.reducer.co.uk"
          target="_blank"
        >
          <img
            class="mx-2"
            border="0"
            height="50px"
            width="50px"
            src="@/assets/images/trustpilot.png"
          />
          <div class="ml-2 ml-md-0 ml-lg-2">
            <h5 class="m-0">
              <b>4.9</b>
              <img
                class="trustpilot-stars mx-2"
                border="0"
                src="@/assets/images/trustpilot_stars.svg"
              />
            </h5>

            <u class="d-block mt-1">
              Leave us feedback
              <font-awesome-icon class="ml-1 reducer-pink" :icon="['fas', 'chevron-right']" />
            </u>
          </div>
        </a>
      </b-col>
    </b-row>

    <FAQs :faqs="faqs" class="mt-4" />
  </div>
</template>

<style lang="scss">
@import "@/styles/common.scss";
#report-footer {
  .section-header {
    font-weight: 900;
    text-align: center;
  }

  #share-report-section {
    & > #share-report-button {
      color: $color-font-para;
      border-color: $color-grey-lighter3;
      background-color: white;
      & > svg {
        color: $color-pink-main;
      }
    }
  }

  #get-in-touch-section {
    background-color: $color-grey-lighter5;
    padding: 1rem;
    border-radius: 10px;

    #book-a-call-button {
      color: $color-font-headings;
      background-color: $color-grey-lighter5;
      max-width: 8rem;
      &:hover {
        background-color: $color-font-headings;
        color: white;
      }
    }

    h3,
    p {
      color: $color-font-headings;
      padding: 0;
      margin: 0;
    }
  }

  #review-us-section {
    .review-card {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1rem;
      border-radius: 10px;
      color: $color-font-headings !important;

      &:hover {
        cursor: pointer;
        text-decoration: none;
      }

      .review-star {
        color: $color-yellow-red1;
        font-size: 17px;
        margin: 0 3px 0 3px;
        @media screen and (max-width: 991px) and (min-width: 768px) {
          margin: 0;
        }
      }

      .trustpilot-stars {
        max-width: 110px;
      }

      @media screen and (max-width: 991px) and (min-width: 768px) {
        h5 {
          font-size: 15px;
        }
        .review-star,
        u {
          font-size: 14px;
        }
        .trustpilot-stars {
          max-width: 70px !important;
        }
      }
    }
  }

  #faq-section {
    text-align: center;
    padding: 2rem;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;

    p,
    span {
      font-size: 18px;
    }

    p {
      margin-left: 1rem;
    }

    .faqs {
      width: 100%;

      .faq {
        border-color: white;
        width: 100%;
        text-align: left !important;
        .faq-chevron {
          color: $color-pink-lighter5;
          font-size: 1.5rem;
          font-weight: bold;
        }

        &:hover {
          background-color: white;
          color: $color-font-headings;
        }
      }
      .faq-answer {
        text-align: left;
      }
    }
  }
}
</style>

<script>
import ShareSavingsReport from "@/components/modals/ShareSavingsReport";
import ApiHelper from "@/helper/apihelper";
import DemoSavingsReportModal from "@/components/savingsreport/DemoSavingsReportModal";
import { EventBus } from "@/components/eventbus";
import DotCards from "@/components/DotCards";
import BusinessCarousel from "@/components/BusinessCarousel";
import FAQs from "@/components/FAQs";

export default {
  name: "ReportFooter",
  props: {
    savings: Object,
    branding: Object,
    genericInfo: Object,
    demo: Boolean,
    preview: Boolean,
    organisationId: String
  },
  components: {
    ShareSavingsReport,
    DemoSavingsReportModal,
    DotCards,
    BusinessCarousel,
    FAQs
  },
  data() {
    return {
      carouselInterval: null,
      reportToShare: null,
      demoModal: false,
      selectedBusinessIndex: 0,
      selectedBusiness: {},
      businesses: [
        {
          business: "crafty_belle.png",
          owner: "josh_mullet_sadones.png",
          quote:
            "Reducer's platform is so easy to read and understand. It has a clean and clear design which means there are no surprises when it comes to renewals charges. They're amazingly helpful and they're always on hand to explain things to me should I have any questions.",
          by: "- Josh Mullet-Sadones, Crafty Belle"
        },
        {
          business: "ab10_clinic_square.png",
          owner: "jacqueline_davidson.png",
          quote:
            "Thanks to Reducer, I’ve made some huge cuts on my bills, so I can use the money I’ve saved on more important things.",
          by: "- Jacqueline Davidson, AB10 Clinic"
        },
        {
          business: "facepunch_studios_square.png",
          owner: null,
          quote:
            "I was very impressed with how simple this service is. I'm very glad I tried it as it has saved me over £4,000 per year on my electricity, so I would definitely recommend it.",
          by: "- Craig Gwilt, Facepunch Studios"
        },
        {
          business: "greenwood_property_square.png",
          owner: "james_grimsted.png",
          quote:
            "Reducer's responsive and helpful staff have delivered tangible reductions in cost for my businesses.",
          by: "- James Grimstead, Barons & Greenwoods Property Centre"
        },
        {
          business: "runway_east.jpg",
          owner: "andy_williams.jpeg",
          quote:
            "Reducer is a brilliant tool for any finance team or small business owner - we've saved thousands of pounds to date. The Reducer team handled just about everything for us and from our perspective the ROI on 5 minutes of set up is a no-brainer!",
          by: "- Andy Williams, Runway East "
        },
        {
          business: "canburysecret.png",
          owner: "selini.jpg",
          quote:
            "I'm very happy with the service from Reducer - very smooth and professionally managed. They provided me with plenty of options and worked around the needs of my business. ",
          by: "- Filippo Selini, Canbury Secret"
        },
        {
          business: "cafe_roya.png",
          owner: "royabishop.jpg",
          quote:
            "Reducer provided a quick and efficient service and helped save me money on my energy bills. The switch couldn't have been easier. I would highly recommend Reducer. ",
          by: "- Roya Bishop, Cafe Roya"
        },
        {
          business: "wild_beer.jpg",
          owner: null,
          quote:
            "Reducer have made it so easy to save money for our business. They have really taken the headache away of managing our supplier contracts!",
          by: "- Tori Knowles, Wild Beer co"
        },
        {
          business: "bdb.png",
          owner: "liz_coulter.jpg",
          quote:
            "I like the idea of being able to lock in prices for contracts up to one year before a switch and for the duration of the contract.  I have been stung in the past where suppliers have increased my pricing mid contract. Reducer have been very helpful in explaining the process of switching and then the future handling of my account.",
          by: "- Liz Coulter, Beautifully Different"
        },
        {
          business: "bobabox.png",
          owner: "yoshi_lee.jpg",
          quote:
            "I highly recommend Reducer. They were super helpful, going above and beyond our expectations when reviewing our rates and contracts. They have literally saved us thousands of pounds per month.",
          by: "- Yoshi Lee, Boba Box"
        }
      ],
      faqs: [
        {
          index: 1,
          q: "How were the savings calculated?",
          a:
            "We analysed your spending data through an integration with your accounting platform, such as Xero or QuickBooks. We then compared your current rates against our network of suppliers and presented you with most cost effective options."
        },
        {
          index: 2,
          q: "How do you access spending data?",
          a:
            "Our integration with your accounting platform gives us access to non-sensitive spend data which is then used to identify any savings available."
        },
        {
          index: 3,
          q: "Are there any costs associated with using Reducer?",
          a:
            "Reducer is completely free to use. We receive a small fee from your new supplier if you choose to switch."
        },
        {
          index: 4,
          q: "What happens when I take a deal?",
          a:
            "We will prepare and email you a digital contract through DocuSign. You are not committed to anything until you sign this contract. Once the contract is signed, we handle everything else, including notifying your old supplier of the switch."
        },
        {
          index: 5,
          q: "Will I need to fill out lots of forms?",
          a:
            "No. We have most of the details we need to switch you. On the DocuSign contract you will just need to complete your payment details and click a button to sign. "
        },
        {
          index: 6,
          q: "What happens if there are any issues after switching supplier?",
          a:
            "You will be assigned a dedicated Client Partnership Manager who you can contact directly should you have any queries or issues after switching."
        },
        {
          index: 7,
          q: "How long are the offers valid for?",
          a:
            "Energy prices change frequently. This means when you choose to take an energy deal, and the offers we generated are more than a few days old, we might need to update the pricing slightly. Prices in all of the other areas we quote on are more stable. We will inform you of any price changes before we ask you to sign anything."
        },
        {
          index: 8,
          q: "How can I protect myself from increasing prices?",
          a:
            "Entering a fixed term contract is a good way to protect yourself from price rises. Your dedicated Client Partnership Manager will advise you on best options by taking into account business needs and market activity at the time."
        },
        {
          index: 9,
          q: "Can you update some details?",
          a:
            "If you have another service you would like us to quote on, or you think there is a mistake in the details we used to generate offers, get in touch with our Client Support Team and we will update the savings for you."
        }
      ]
    };
  },
  mounted() {
    this.moveCarousel("up");
    this.carouselInterval = window.setInterval(() => {
      this.moveCarousel("up");
    }, 8000);
  },
  methods: {
    moveCarousel(way) {
      if (way == "up") {
        if (this.selectedBusinessIndex == this.businesses.length - 1) {
          this.selectedBusinessIndex = 0;
        } else {
          this.selectedBusinessIndex++;
        }
      } else {
        if (this.selectedBusinessIndex == 0) {
          this.selectedBusinessIndex = this.businesses.length - 1;
        } else {
          this.selectedBusinessIndex--;
        }
      }

      this.selectedBusiness = this.businesses[this.selectedBusinessIndex];
    },

    getImgUrl(img_name) {
      return require("@/assets/images/businesses/" + img_name);
    },

    openShareModal() {
      if (!this.demo) {
        var obj = new Object();

        obj["created"] = this.genericInfo.createdDate;
        obj["organisationId"] = this.organisationId;

        this.reportToShare = obj;
      } else {
        this.demoModal = !this.demoModal;
      }
    },

    showAlert(message, variant) {
      EventBus.$emit("show-toast", { message: message, variant: variant });
    }
  },
  beforeDestroy() {
    clearInterval(this.carouselInterval);
  }
};
</script>
