<template>
  <div class="w-100">
    <!-- MOBILE NUMBERS -->
    <div
      class="dropdown-reducer"
      @click="$emit('toggle', id)"
      v-if="deal.type == 'MOBILE' && deal.numbers && deal.numbers.length > 0 && suppliers"
    >
      <h5>
        {{ title }}
        <font-awesome-icon
          class="mr-1 mt-1 float-right"
          :id="id + '-chevron'"
          :icon="['fas', 'chevron-up']"
          v-bind:style="{ color: color }"
        />
      </h5>
    </div>

    <div
      :id="id"
      v-bind:class="{ show: open }"
      class="collapse w-100"
      v-if="
        deal.type == 'MOBILE' &&
        typeof deal.numbers !== 'undefined' &&
        deal.numbers &&
        deal.numbers.length > 0
      "
    >
      <MobileDealBreakdown :deal="deal" :color="color" :suppliers="suppliers"></MobileDealBreakdown>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/styles/common.scss";
</style>

<script>
import FormatHelper from "@/helper/formathelper";
import MobileDealBreakdown from "@/components/savingsreport/MobileDealBreakdown";

export default {
  name: "MobileDetails",
  components: {
    MobileDealBreakdown
  },
  props: {
    deal: Object,
    suppliers: Object,
    id: {
      type: String
    },
    open: {
      type: Boolean,
      default: true
    },
    title: String,
    color: {
      type: String
    }
  }
};
</script>