<template>
  <b-modal ref="demoSavingsReportModal" hide-header hide-footer lazy>
    <p class="m-4">
      You are in a
      <b v-bind:style="{ color: color }">demo</b> version. This button has no effect here.
    </p>
  </b-modal>
</template>


<style lang="scss">
@import "@/styles/common.scss";
</style>

<script>
export default {
  name: "DemoSavingsReportModal",
  props: {
    demoModal: Boolean,
    color: {
      type: String,
      default: "#F40C8F"
    }
  },
  watch: {
    demoModal: function () {
      this.$refs.demoSavingsReportModal.show();
    }
  }
};
</script>

