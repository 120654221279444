<template>
  <div>
    <b-modal
      ref="takeDealModal"
      id="take-deal-modal"
      @ok="handleOk"
      @hidden="$emit('cancelled')"
      hide-header
      hide-footer
      lazy
    >
      <div>
        <h5 class="mb-3">Great, we're ready to generate the contract for you.</h5>

        <h6 class="mb-3">
          You haven't committed to anything until you've signed the digital contract, which we will
          send to
          <span v-bind:style="{ color: primaryColor }">{{ user.email }}</span>
        </h6>

        <h6 v-if="user.phoneNumbers[0].number">
          If we have any questions we will call you on
          <span v-bind:style="{ color: primaryColor }">{{ user.phoneNumbers[0].number }}</span
          >. If you need to update your number, please provide the new number below.
          <br />
          <br />If you need to contact us, you can email
          <a href="mailto:support@reducer.co.uk" v-bind:style="{ color: primaryColor }"
            >support@reducer.co.uk</a
          >, or call us on
          <a href="tel:+442039704686" v-bind:style="{ color: primaryColor }">020 3970 4686</a>.
        </h6>

        <h6 v-else>
          If you need to contact us, you can email
          <a href="mailto:support@reducer.co.uk" v-bind:style="{ color: primaryColor }"
            >support@reducer.co.uk</a
          >
          , or call us on
          <a href="tel:+442039704686" v-bind:style="{ color: primaryColor }">020 3970 4686</a>. We
          might need to contact you to confirm details for the contract. Please provide a direct
          contact number we can reach you on for this purpose:
        </h6>

        <p v-if="phoneErrorMessage" class="modalError p-0 px-2 py-1">{{ phoneErrorMessage }}</p>

        <div class="mt-2">
          <b-form-input v-model="providedPhoneNumber" placeholder="Contact phone number" />
        </div>
      </div>
      <div class="p-0 pt-2 float-right">
        <b-button variant="secondary" @click="$emit('cancelled')">Cancel</b-button>
        <b-button
          class="ml-2 gtm-sendme-contact"
          variant="primary"
          @click="handleOk"
          v-bind:style="{ 'background-color': secondaryColor, 'border-color': secondaryColor }"
          >Send me the contract</b-button
        >
      </div>
    </b-modal>

    <b-modal v-model="showTookDealModal" hide-header hide-footer lazy>
      <h5>
        Thanks. We're getting the contract ready, which you will receive by email within one working
        day.
      </h5>

      <div class="float-right">
        <b-button variant="secondary" @click="showTookDealModal = false">Ok</b-button>
      </div>
    </b-modal>
  </div>
</template>

<style lang="scss">
@import "@/styles/common.scss";
#take-deal-modal {
  .modalError {
    background-color: $color-red-light3;
    border-radius: 16px;
    color: white;
  }
}
</style>

<script>
import { EventBus } from "@/components/eventbus";
import ApiHelper from "@/helper/apihelper";
import OrganisationEventHelper from "@/helper/organisationeventhelper";
import Console from "@/console";

import CognitoAuth from "@/cognito/cognitoauth";
import TokenHelper from "@/cognito/tokenhelper";
import PhoneHelper from "@/helper/phonehelper";
export default {
  name: "TakeDealModal",
  props: {
    deal: Object,
    organisationId: String,
    created: String,
    primaryColor: {
      type: String,
      default: "#F40C8F"
    },
    secondaryColor: {
      type: String,
      default: "#007BFF"
    }
  },
  watch: {
    deal: function (value) {
      if (value) {
        this.mydeal = value;
        this.getUserInfo();
        this.takeDealButtonClicked();
        this.$refs.takeDealModal.show();
      } else {
        this.$refs.takeDealModal.hide();
      }
    }
  },
  data() {
    return {
      user: {
        phoneNumbers: [{ number: null }]
      },
      providedPhoneNumber: null,
      showTookDealModal: false,
      phoneErrorMessage: null,
      mydeal: null
    };
  },
  methods: {
    sendTakeDeal: OrganisationEventHelper.sendTakeDeal,
    sendOpenedTakeDeal: OrganisationEventHelper.sendOpenedTakeDeal,

    async handleOk(evt) {
      this.phoneErrorMessage = null;
      if (
        PhoneHelper.isValid(this.providedPhoneNumber) ||
        this.providedPhoneNumber == null ||
        this.providedPhoneNumber == ""
      ) {
        if (this.providedPhoneNumber != null && this.providedPhoneNumber != "")
          await this.setUserPhoneNumber();
        var sendEvent = await this.sendTakeDealEvent();
        if (sendEvent != false) {
          this.$refs.takeDealModal.hide();
          this.showTookDealModal = true;
        } else {
          EventBus.$emit("show-toast", {
            message: "Due an unexpected error we couldn't submit your request.",
            variant: "warning"
          });
        }
      } else {
        this.phoneErrorMessage = "Please enter a valid phone number";
        evt.preventDefault();
      }
    },

    async takeDealButtonClicked() {
      try {
        const client = await ApiHelper.http();
        const response = await client.post(
          `${ApiHelper.endPoint()}users/contact`,
          [
            "organisationId=" + this.organisationId,
            "type=openedTakeDeal",
            "dealSortKey=" + this.mydeal.sortKey
          ].join("&")
        );

        if (response.data.status === "success") {
          this.sendOpenedTakeDeal(this.organisationId, this.created, this.mydeal.sortKey);
          return true;
        } else {
          Console.error("Failed to submit event");
          return false;
        }
      } catch (err) {
        Console.error(err);
        return false;
      }
    },

    async getUserId() {
      const token = await CognitoAuth.getCurrentUserAccessToken();
      return TokenHelper.parseJwtToken(token).sub;
    },

    async getUserInfo() {
      try {
        let client = await ApiHelper.http();
        var response = await client.get(`${ApiHelper.endPoint()}users/${await this.getUserId()}`);

        if (response.data.status === "success") {
          this.user = response.data.user;

          if (
            typeof this.user.phoneNumbers == "undefined" ||
            !PhoneHelper.isValid(this.user.phoneNumbers[0].number)
          ) {
            this.user["phoneNumbers"] = [
              {
                number: null
              }
            ];
          }
        } else {
          this.showAlert(
            "Sorry, we had a problem getting your user data from our database.",
            "warning"
          );
        }
      } catch (err) {
        Console.error(err);
        this.showAlert(
          "Sorry, we had a problem getting your user data from our database",
          "warning"
        );
      }
    },

    async setUserPhoneNumber() {
      try {
        let client = await ApiHelper.http();
        var response = await client.post(`${ApiHelper.endPoint()}users/${await this.getUserId()}`, {
          user: {
            phoneNumbers: [{ number: this.providedPhoneNumber }]
          }
        });
        if (response.data.status === "success") {
          OrganisationEventHelper.sendAddedPhone(
            this.organisationId,
            this.created,
            this.providedPhoneNumber
          );

          if (this.user) {
            this.user.phoneNumbers[0].number = this.providedPhoneNumber;
          }
        } else {
          this.showAlert(
            "Sorry, we had a problem saving your phone number. We may ask you for the number again in future.",
            "warning"
          );
        }
      } catch (err) {
        Console.error(err);
        this.showAlert(
          "Sorry, we had a problem saving your phone number. We may ask you for the number again in future.",
          "warning"
        );
      }
    },

    showAlert(message, variant) {
      EventBus.$emit("show-toast", { message: message, variant: variant });
    },

    async sendTakeDealEvent() {
      try {
        const client = await ApiHelper.http();
        const response = await client.post(
          `${ApiHelper.endPoint()}users/contact`,
          [
            "organisationId=" + this.organisationId,
            "type=takeDeal",
            "dealSortKey=" + this.mydeal.sortKey
          ].join("&")
        );

        if (response.data.status === "success") {
          this.sendTakeDeal(this.organisationId, this.created, this.mydeal.sortKey);
          return true;
        } else {
          Console.error("Failed to submit event");
          return false;
        }
      } catch (err) {
        Console.error(err);
        return false;
      }
    }
  }
};
</script>
