<template>
  <div class="other-saving-options-container px-4 px-md-0">
    <!-- Filtering -->
    <b-row v-if="supply.deals.length > 8" class="options-filter mx-1 mx-md-3">
      <b-col cols="12" class="d-flex justify-content-between pb-1 align-items-end">
        <p v-if="filteredDeals.length == 0" class="m-0">No options available</p>
        <p v-else class="m-0">Showing {{ filteredDeals.length }} options</p>
        <b-button size="sm" variant="light" class="border" @click="resetFilter()"
          >Reset filter</b-button
        >
      </b-col>

      <b-col cols="4" class="filter-section">
        <h5>Contract length</h5>
        <b-checkbox-group v-model="selectedTerms" :options="termOptions" buttons />
      </b-col>
      <b-col v-if="anyGreenDeals" cols="4" class="filter-section">
        <h5>Energy tariff</h5>
        <b-checkbox-group v-model="selectedTariffs" :options="tariffOptions" buttons />
      </b-col>
    </b-row>

    <div v-for="(deal, dealName) in filteredDeals" :key="dealName" v-show="deal.supplierId">
      <!-- Recommended, Contract info badge -->
      <div :style="styleObject" :class="'mx-3 mx-md-5 info-badge-' + displayInfoBadge(deal)">
        <p v-if="hasActiveContract && contract.dealId == deal.dealId" class="mb-0 px-3 pt-1">
          {{ contract.state == "OUT" ? "Contract ready to sign" : "Contract has been signed" }}
        </p>
        <p v-else-if="deal.recommended" class="mb-0 px-3 pt-1">
          Recommended
        </p>
      </div>

      <div class="w-100 other-savings-option d-block">
        <b-row
          :style="styleObject"
          :class="
            'my-3 d-flex justify-content-center align-items-center position-relative reducer-box-shadow savings-card pt-1 show-border-' +
              displayInfoBadge(deal)
          "
        >
          <!-- Supplier image column -->
          <b-col cols="12" md="2" class="deal-col mt-2 mb-3 m-md-0">
            <div v-if="deal.greenTariff" class="absolute-badge badge-styles">
              <b-badge variant="success">Green <span class="d-md-none">tariff</span></b-badge>
            </div>
            <b-img
              v-if="
                deal.supplierId &&
                  suppliers &&
                  suppliers[deal.supplierId] &&
                  typeof suppliers[deal.supplierId] != 'undefined' &&
                  suppliers[deal.supplierId].logos3url
              "
              :src="suppliers[deal.supplierId].logos3url"
              :alt="suppliers[deal.supplierId].name"
              class="align-items-center image-size"
            />
            <h5 v-else class="m-0">
              <span
                v-if="
                  suppliers[deal.supplierId] &&
                    typeof suppliers[deal.supplierId] != 'undefined' &&
                    suppliers[deal.supplierId].name
                "
              >
                {{ suppliers[deal.supplierId].name }}
              </span>
            </h5>
          </b-col>

          <b-col cols="12" class="d-md-none">
            <div class="text-center" v-if="hasEnoughData(deal)">
              <u class="pointer supplier-link" @click="openSupplierDetailsModal(deal.supplierId)"
                >Supplier details</u
              >
            </div>
          </b-col>

          <!-- Deal details (sm screens) -->

          <b-col cols="12" class="d-md-none p-0">
            <div class="deal-details-row px-3">
              <span>Contract Term</span>
              <b>{{ formatMonthsToYears(deal.contractTerm.toFixed(0)) }}</b>
            </div>

            <div v-if="deal.estimatedAnnualCost" class="deal-details-row px-3">
              <span>Estimated Annual Cost</span
              ><b>{{ $currencySymbol }}{{ formatNumberToDisplay(deal.estimatedAnnualCost) }}</b>
            </div>
            <div v-else class="deal-details-row px-3">
              <span>Annual Cost</span
              ><b>{{ $currencySymbol }}{{ formatNumberToDisplay(deal.annualCost) }}</b>
            </div>

            <div class="deal-details-row px-3">
              <span v-if="deal.projectedAnnualCost">Annual Savings vs Renewal</span>
              <span v-else>Annual Savings</span>
              <b>{{ $currencySymbol }}{{ formatNumberToDisplay(deal.saving) }}</b>
            </div>
          </b-col>

          <!-- Deal details columns (md< screens) -->

          <b-col cols="4" md="2" class="deal-col deal-details-col">
            <div>
              <h5 v-if="deal.contractTerm">
                <span>{{ formatMonthsToYears(deal.contractTerm) }}</span>
              </h5>
              <p v-if="deal.contractTerm" class="m-0">Contract</p>
            </div>
          </b-col>

          <b-col cols="4" md="2" class="deal-col deal-details-col">
            <div>
              <div v-if="deal.annualCost">
                <h5>
                  <span>{{ $currencySymbol }}{{ formatNumberToDisplay(deal.annualCost) }}</span>
                </h5>
                <p class="m-0">Annual cost</p>
              </div>
              <div v-else-if="deal.estimatedAnnualCost">
                <h5>
                  <span
                    >{{ $currencySymbol
                    }}{{ formatNumberToDisplay(deal.estimatedAnnualCost) }}</span
                  >
                </h5>
                <p class="m-0">Estimated Annual cost</p>
              </div>
            </div>
          </b-col>

          <b-col cols="4" md="2" class="deal-col deal-details-col">
            <div>
              <h5>
                <span v-if="deal.saving"
                  >{{ $currencySymbol }}{{ formatNumberToDisplay(deal.saving) }}</span
                >
                <span v-else>{{ $currencySymbol }}0</span>
              </h5>
              <p class="m-0">Savings/yr</p>
            </div>
          </b-col>

          <!-- More details, Take Deal columns -->

          <b-col cols="6" md="2" class="deal-col d-none d-md-flex">
            <div>
              <u
                class="pointer"
                @click="
                  dealDetails = deal;
                  $refs.dealDetailModal.show();
                "
                >Offer details</u
              >
              <div class="mt-2" v-if="hasEnoughData(deal)">
                <u class="pointer" @click="openSupplierDetailsModal(deal.supplierId)"
                  >Supplier details</u
                >
              </div>
            </div>
          </b-col>

          <b-col cols="6" md="2" class="deal-col" :style="styleObject">
            <!-- Show whether a deal is a placeholder -->
            <div
              v-if="showPlaceholders"
              :class="['plc-indicator ', { 'is-plc': deal.placeholder }]"
              v-b-tooltip="deal.placeholder ? 'Placeholder' : 'Non-placeholder'"
            ></div>
            <b-button
              class="take-deal-btn gtm-takedeal-attempt py-2 px-4 px-md-2 px-xl-4"
              :style="hasActiveContract ? `background: grey !important;` : ``"
              @click="handleTakeDeal(deal)"
              :disabled="hasActiveContract"
            >
              {{ hasActiveContract && contract.dealId == deal.dealId ? "Deal taken" : "Take deal" }}
            </b-button>
          </b-col>

          <!-- Show more details on the right for sm screens -->
          <b-col cols="6" md="2" class="deal-col d-md-none">
            <div>
              <u
                class="pointer"
                @click="
                  dealDetails = deal;
                  $refs.dealDetailModal.show();
                "
                >Offer details</u
              >
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
    <SupplierDetailsModal
      :supplier="supplierDetails"
      @cancelled="supplierDetails = null"
      :primaryColor="primaryColor"
    ></SupplierDetailsModal>
    <DealDetailsModal
      ref="dealDetailModal"
      :suppliers="suppliers"
      :deal="dealDetails"
      :currentDeal="supply.currentDeal"
      :supply="supply"
      :color="primaryColor"
    ></DealDetailsModal>
    <DemoSavingsReportModal :demoModal="demoModal" :color="primaryColor"></DemoSavingsReportModal>
    <TakeDealModal
      :deal="takeDeal"
      :organisationId="organisationId"
      :created="created"
      :primaryColor="primaryColor"
      :secondaryColor="secondaryColor"
      @cancelled="takeDeal = null"
    />
  </div>
</template>

<script>
import { otherSavingOptionsMixin } from "./mixins/otherSavingOptionsMixin";

export default {
  name: "OtherSavingOptionsEnergy",
  mixins: [otherSavingOptionsMixin],
  data() {
    return {
      selectedTerms: [12, 24, 36],
      termOptions: [
        { text: "1 year", value: 12 },
        { text: "2 years", value: 24 },
        { text: "3+ years", value: 36 }
      ],
      selectedTariffs: [],
      tariffOptions: [{ text: "Green", value: "green" }]
    };
  },
  computed: {
    anyGreenDeals() {
      return this.supply.deals.some(d => d.greenTariff);
    },
    filteredDeals() {
      let deals = this.offers;

      //  Contract term filtering
      if (this.selectedTerms.length > 0 && this.selectedTerms.length != this.termOptions.length) {
        deals = deals.filter(d => {
          if (this.selectedTerms.includes(36)) {
            return this.selectedTerms.includes(d.contractTerm) || d.contractTerm > 36;
          } else return this.selectedTerms.includes(d.contractTerm);
        });
      }

      if (this.selectedTariffs.length) {
        deals = deals.filter(d => d.greenTariff);
      }

      return deals;
    }
  },
  created() {
    this.updateFilterOptions();
  },
  methods: {
    resetFilter() {
      this.selectedTariffs = [];
      this.selectedTerms = [12, 24, 36];
    },
    updateFilterOptions() {
      this.resetFilter();

      // Remove contract term options that don't exist in deals
      if (!this.supply.deals.some(d => d.contractTerm == 12)) {
        this.termOptions = this.termOptions.filter(o => o.value != 12);
        this.selectedTerms = this.selectedTerms.filter(o => o != 12);
      }
      if (!this.supply.deals.some(d => d.contractTerm == 24)) {
        this.termOptions = this.termOptions.filter(o => o.value != 24);
        this.selectedTerms = this.selectedTerms.filter(o => o != 24);
      }
      if (!this.supply.deals.some(d => d.contractTerm >= 36)) {
        this.termOptions = this.termOptions.filter(o => o.value != 36);
        this.selectedTerms = this.selectedTerms.filter(o => o != 36);
      }
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/common.scss";
@import "./styles/otherSavingOptions";
</style>
