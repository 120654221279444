<template>
  <div>
    <b-row
      class="mx-4 my-2 px-3 reducer-box-shadow"
      v-if="openedDeal.cnpCreditFee || currentDeal.cnpCreditFee"
    >
      <b-col lg="5" md="5" sm="5" cols="12" class="px-1 py-2 my-auto">
        <h5>CNP Credit Fee</h5>
      </b-col>
      <b-col lg="4" md="4" sm="4" cols="6" class="px-1 py-2 my-auto">
        <h5 v-if="currentDeal.cnpCreditFee">
          {{ $currencySymbol }}{{ currentDeal.cnpCreditFee.toFixed(2) }}
        </h5>
        <h5 v-else>--</h5>
        <p class="m-0">Current Fee</p>
      </b-col>
      <b-col lg="3" md="3" sm="3" cols="6" class="px-1 py-2 my-auto">
        <h5 v-if="openedDeal.cnpCreditFee" v-bind:style="{ color: color }">
          {{ $currencySymbol }}{{ openedDeal.cnpCreditFee.toFixed(2) }}
        </h5>
        <h5 v-else v-bind:style="{ color: color }">--</h5>
        <p class="m-0">New Fee</p>
      </b-col>
    </b-row>

    <b-row
      class="mx-4 my-2 px-3 reducer-box-shadow"
      v-if="openedDeal.cnpDebitFee || currentDeal.cnpDebitFee"
    >
      <b-col lg="5" md="5" sm="5" cols="12" class="px-1 py-2 my-auto">
        <h5>CNP Debit Fee</h5>
      </b-col>
      <b-col lg="4" md="4" sm="4" cols="6" class="px-1 py-2 my-auto">
        <h5 v-if="currentDeal.cnpDebitFee">
          {{ $currencySymbol }}{{ currentDeal.cnpDebitFee.toFixed(2) }}
        </h5>
        <h5 v-else>--</h5>
        <p class="m-0">Current Fee</p>
      </b-col>
      <b-col lg="3" md="3" sm="3" cols="6" class="px-1 py-2 my-auto">
        <h5 v-if="openedDeal.cnpDebitFee" v-bind:style="{ color: color }">
          {{ $currencySymbol }}{{ openedDeal.cnpDebitFee.toFixed(2) }}
        </h5>
        <h5 v-else v-bind:style="{ color: color }">--</h5>
        <p class="m-0">New Fee</p>
      </b-col>
    </b-row>

    <b-row
      class="mx-4 my-2 px-3 reducer-box-shadow"
      v-if="openedDeal.cnpCreditInternationalFee || currentDeal.cnpCreditInternationalFee"
    >
      <b-col lg="5" md="5" sm="5" cols="12" class="px-1 py-2 my-auto">
        <h5>CNP Credit International Fee</h5>
      </b-col>
      <b-col lg="4" md="4" sm="4" cols="6" class="px-1 py-2 my-auto">
        <h5 v-if="currentDeal.cnpCreditInternationalFee">
          {{ $currencySymbol }}{{ currentDeal.cnpCreditInternationalFee.toFixed(2) }}
        </h5>
        <h5 v-else>--</h5>
        <p class="m-0">Current Fee</p>
      </b-col>
      <b-col lg="3" md="3" sm="3" cols="6" class="px-1 py-2 my-auto">
        <h5 v-if="openedDeal.cnpCreditInternationalFee" v-bind:style="{ color: color }">
          {{ $currencySymbol }}{{ openedDeal.cnpCreditInternationalFee.toFixed(2) }}
        </h5>
        <h5 v-else v-bind:style="{ color: color }">--</h5>
        <p class="m-0">New Fee</p>
      </b-col>
    </b-row>

    <b-row
      class="mx-4 my-2 px-3 reducer-box-shadow"
      v-if="openedDeal.cnpDebitInternationalFee || currentDeal.cnpDebitInternationalFee"
    >
      <b-col lg="5" md="5" sm="5" cols="12" class="px-1 py-2 my-auto">
        <h5>CNP Debit International Fee</h5>
      </b-col>
      <b-col lg="4" md="4" sm="4" cols="6" class="px-1 py-2 my-auto">
        <h5 v-if="currentDeal.cnpDebitInternationalFee">
          {{ $currencySymbol }}{{ currentDeal.cnpDebitInternationalFee.toFixed(2) }}
        </h5>
        <h5 v-else>--</h5>
        <p class="m-0">Current Fee</p>
      </b-col>
      <b-col lg="3" md="3" sm="3" cols="6" class="px-1 py-2 my-auto">
        <h5 v-if="openedDeal.cnpDebitInternationalFee" v-bind:style="{ color: color }">
          {{ $currencySymbol }}{{ openedDeal.cnpDebitInternationalFee.toFixed(2) }}
        </h5>
        <h5 v-else v-bind:style="{ color: color }">--</h5>
        <p class="m-0">New Fee</p>
      </b-col>
    </b-row>

    <b-row
      class="mx-4 my-2 px-3 reducer-box-shadow"
      v-if="openedDeal.interRegional || currentDeal.interRegional"
    >
      <b-col lg="5" md="5" sm="5" cols="12" class="px-1 py-2 my-auto">
        <h5>Inter-Regional Fee</h5>
      </b-col>
      <b-col lg="4" md="4" sm="4" cols="6" class="px-1 py-2 my-auto">
        <h5 v-if="currentDeal.interRegional">{{ currentDeal.interRegional.toFixed(2) }}%</h5>
        <h5 v-else>--</h5>
        <p class="m-0">Current Fee</p>
      </b-col>
      <b-col lg="3" md="3" sm="3" cols="6" class="px-1 py-2 my-auto">
        <h5 v-if="openedDeal.interRegional" v-bind:style="{ color: color }">
          {{ openedDeal.interRegional.toFixed(2) }}%
        </h5>
        <h5 v-else v-bind:style="{ color: color }">--</h5>
        <p class="m-0">New Fee</p>
      </b-col>
    </b-row>

    <b-row
      class="mx-4 my-2 px-3 reducer-box-shadow"
      v-if="openedDeal.intraRegional || currentDeal.intraRegional"
    >
      <b-col lg="5" md="5" sm="5" cols="12" class="px-1 py-2 my-auto">
        <h5>Intra-Regional Charge</h5>
      </b-col>
      <b-col lg="4" md="4" sm="4" cols="6" class="px-1 py-2 my-auto">
        <h5 v-if="currentDeal.intraRegional">{{ currentDeal.intraRegional.toFixed(2) }}%</h5>
        <h5 v-else>--</h5>
        <p class="m-0">Current Fee</p>
      </b-col>
      <b-col lg="3" md="3" sm="3" cols="6" class="px-1 py-2 my-auto">
        <h5 v-if="openedDeal.intraRegional" v-bind:style="{ color: color }">
          {{ openedDeal.intraRegional.toFixed(2) }}%
        </h5>
        <h5 v-else v-bind:style="{ color: color }">--</h5>
        <p class="m-0">New Fee</p>
      </b-col>
    </b-row>

    <b-row
      class="mx-4 my-2 px-3 reducer-box-shadow"
      v-if="openedDeal.internationalFee || currentDeal.internationalFee"
    >
      <b-col lg="5" md="5" sm="5" cols="12" class="px-1 py-2 my-auto">
        <h5>International Fee</h5>
      </b-col>
      <b-col lg="4" md="4" sm="4" cols="6" class="px-1 py-2 my-auto">
        <h5 v-if="currentDeal.internationalFee">
          {{ $currencySymbol }}{{ currentDeal.internationalFee.toFixed(2) }}
        </h5>
        <h5 v-else>--</h5>
        <p class="m-0">Current Fee</p>
      </b-col>
      <b-col lg="3" md="3" sm="3" cols="6" class="px-1 py-2 my-auto">
        <h5 v-if="openedDeal.internationalFee" v-bind:style="{ color: color }">
          {{ $currencySymbol }}{{ openedDeal.internationalFee.toFixed(2) }}
        </h5>
        <h5 v-else v-bind:style="{ color: color }">--</h5>
        <p class="m-0">New Fee</p>
      </b-col>
    </b-row>

    <b-row
      class="mx-4 my-2 px-3 reducer-box-shadow"
      v-if="openedDeal.managementFee || currentDeal.managementFee"
    >
      <b-col lg="5" md="5" sm="5" cols="12" class="px-1 py-2 my-auto">
        <h5>Management Fee</h5>
      </b-col>
      <b-col lg="4" md="4" sm="4" cols="6" class="px-1 py-2 my-auto">
        <h5 v-if="currentDeal.managementFee">
          {{ $currencySymbol }}{{ currentDeal.managementFee.toFixed(2) }}
        </h5>
        <h5 v-else>--</h5>
        <p class="m-0">Current Fee</p>
      </b-col>
      <b-col lg="3" md="3" sm="3" cols="6" class="px-1 py-2 my-auto">
        <h5 v-if="openedDeal.managementFee" v-bind:style="{ color: color }">
          {{ $currencySymbol }}{{ openedDeal.managementFee.toFixed(2) }}
        </h5>
        <h5 v-else v-bind:style="{ color: color }">--</h5>
        <p class="m-0">New Fee</p>
      </b-col>
    </b-row>

    <b-row
      class="mx-4 my-2 px-3 reducer-box-shadow"
      v-if="openedDeal.authorisationFee || currentDeal.authorisationFee"
    >
      <b-col lg="5" md="5" sm="5" cols="12" class="px-1 py-2 my-auto">
        <h5>Authorisation Fee</h5>
      </b-col>
      <b-col lg="4" md="4" sm="4" cols="6" class="px-1 py-2 my-auto">
        <h5 v-if="currentDeal.authorisationFee">{{ currentDeal.authorisationFee.toFixed(2) }}p</h5>
        <h5 v-else>--</h5>
        <p class="m-0">Current Fee</p>
      </b-col>
      <b-col lg="3" md="3" sm="3" cols="6" class="px-1 py-2 my-auto">
        <h5 v-if="openedDeal.authorisationFee" v-bind:style="{ color: color }">
          {{ openedDeal.authorisationFee.toFixed(2) }}p
        </h5>
        <h5 v-else v-bind:style="{ color: color }">--</h5>
        <p class="m-0">New Fee</p>
      </b-col>
    </b-row>

    <b-row
      class="mx-4 my-2 px-3 reducer-box-shadow"
      v-if="openedDeal.nonComplianceFee || currentDeal.nonComplianceFee"
    >
      <b-col lg="5" md="5" sm="5" cols="12" class="px-1 py-2 my-auto">
        <h5>Non-Compliance Fee</h5>
      </b-col>
      <b-col lg="4" md="4" sm="4" cols="6" class="px-1 py-2 my-auto">
        <h5 v-if="currentDeal.nonComplianceFee">
          {{ $currencySymbol }}{{ currentDeal.nonComplianceFee.toFixed(2) }}
        </h5>
        <h5 v-else>--</h5>
        <p class="m-0">Current Fee</p>
      </b-col>
      <b-col lg="3" md="3" sm="3" cols="6" class="px-1 py-2 my-auto">
        <h5 v-if="openedDeal.nonComplianceFee" v-bind:style="{ color: color }">
          {{ $currencySymbol }}{{ openedDeal.nonComplianceFee.toFixed(2) }}
        </h5>
        <h5 v-else v-bind:style="{ color: color }">--</h5>
        <p class="m-0">New Fee</p>
      </b-col>
    </b-row>

    <b-row
      class="mx-4 my-2 px-3 reducer-box-shadow"
      v-if="openedDeal.minimumMonthlyCharge || currentDeal.minimumMonthlyCharge"
    >
      <b-col lg="5" md="5" sm="5" cols="12" class="px-1 py-2 my-auto">
        <h5>Monthly Min. Charge</h5>
      </b-col>
      <b-col lg="4" md="4" sm="4" cols="6" class="px-1 py-2 my-auto">
        <h5 v-if="currentDeal.minimumMonthlyCharge">
          {{ $currencySymbol }}{{ currentDeal.minimumMonthlyCharge.toFixed(2) }}
        </h5>
        <h5 v-else>--</h5>
        <p class="m-0">Current Fee</p>
      </b-col>
      <b-col lg="3" md="3" sm="3" cols="6" class="px-1 py-2 my-auto">
        <h5 v-if="openedDeal.minimumMonthlyCharge" v-bind:style="{ color: color }">
          {{ $currencySymbol }}{{ openedDeal.minimumMonthlyCharge.toFixed(2) }}
        </h5>
        <h5 v-else v-bind:style="{ color: color }">--</h5>
        <p class="m-0">New Fee</p>
      </b-col>
    </b-row>
  </div>
</template>

<style lang="scss">
@import "@/styles/common.scss";
</style>

<script>
import FormatHelper from "@/helper/formathelper";

export default {
  name: "CardPaymentsFees",
  props: {
    currentDeal: {
      type: Object,
      default: null
    },
    openedDeal: {
      type: Object,
      default: null
    },
    color: {
      type: String,
      default: "#F40C8F"
    }
  },
  methods: {
    formatNumberToDisplay: FormatHelper.formatNumberToDisplay,
    formatMonthsToYears: FormatHelper.formatMonthsToYears
  }
};
</script>
