<template>
  <b-modal
    ref="supplierDetailsModal"
    id="supplierDetailsModalId"
    @hidden="$emit('cancelled')"
    ok-only
    hide-footer
    content-class="shadow"
    ok-variant="secondary"
    ok-title="Close"
    headerClass="p-0 pt-2 pr-3 border-bottom-0"
    size="lg"
  >
    <div
      v-if="supplier && Object.keys(supplier).length != 0"
      class="mx-5 px-2 mb-3"
      :style="styleObject"
    >
      <b-row v-if="supplier.shortDescription && supplier.shortDescription.length < 650">
        <b-col lg="5" class="my-auto p-0">
          <div class="text-center">
            <b-img
              class="supplier-details-modal-logo"
              rounded
              :src="supplier.logos3url"
              fluid
              alt="Supplier logo not found"
            ></b-img>
          </div>

          <div class="mt-4 contact-button-div">
            <b-button
              variant="outline-secondary"
              pill
              class="contact-button"
              v-if="supplier.socialMedia && supplier.socialMedia.websiteUrl"
              :href="supplier.socialMedia.websiteUrl"
              target="_blank"
            >
              <font-awesome-icon
                :icon="['fal', 'globe']"
                v-bind:style="{ color: primaryColor }"
                class="fa-lg"
              />
              <span class="ml-2">{{ checkWebsite(supplier.socialMedia.websiteUrl) }}</span>
            </b-button>
          </div>
        </b-col>

        <b-col cols="12" lg="7" class="my-auto p-0">
          <p class="supplier-details-modal-text mt-4" v-if="supplier.shortDescription">
            {{ supplier.shortDescription }}
          </p>
        </b-col>
      </b-row>

      <b-row v-else>
        <b-col cols="12" class="my-auto p-0 text-center">
          <div>
            <b-img
              class="supplier-details-modal-logo"
              rounded
              :src="supplier.logos3url"
              fluid
              alt="Supplier logo not found"
            ></b-img>
          </div>

          <div class="mt-4">
            <b-button
              variant="outline-secondary"
              pill
              class="contact-button"
              v-if="supplier.socialMedia && supplier.socialMedia.websiteUrl"
              :href="supplier.socialMedia.websiteUrl"
              target="_blank"
            >
              <font-awesome-icon
                :icon="['fal', 'globe']"
                v-bind:style="{ color: primaryColor }"
                class="fa-lg"
              />
              <span class="ml-2">{{ checkWebsite(supplier.socialMedia.websiteUrl) }}</span>
            </b-button>
          </div>
        </b-col>

        <b-col cols="12" class="mt-4 p-0">
          <p class="supplier-details-modal-text pl-1" v-if="supplier.shortDescription">
            {{ supplier.shortDescription }}
          </p>
        </b-col>
      </b-row>

      <b-row
        class="mt-4 px-3 py-4 row-card"
        v-if="
          (supplier.keyPoints && supplier.keyPoints.length > 0) ||
          (ratingPercentage > 0 && scorePerTen > 0)
        "
      >
        <b-col class="my-auto px-1" v-if="supplier.keyPoints && supplier.keyPoints.length > 0">
          <div class="mb-2">
            <p class="supplier-details-modal-text">
              <b>Key Points:</b>
            </p>
          </div>

          <div class="px-2">
            <p
              class="supplier-details-modal-text small"
              v-for="(keyPoint, index) in supplier.keyPoints"
              v-bind:key="index + 'keyPoint'"
            >
              <font-awesome-icon
                class="mr-2"
                :icon="['fas', 'chevron-right']"
                v-bind:style="{ color: primaryColor }"
              ></font-awesome-icon>
              {{ keyPoint }}
            </p>
          </div>
        </b-col>

        <b-col
          cols="12"
          lg="3"
          class="d-flex justify-content-center mr-2 p-0"
          v-if="
            ratingPercentage > 0 &&
            scorePerTen > 0 &&
            supplier.keyPoints &&
            supplier.keyPoints.length > 0
          "
        >
          <ProgressBarCircle
            class="m-1"
            :percentage="ratingPercentage"
            title="Rating"
            :score="scorePerTen"
          ></ProgressBarCircle>
        </b-col>

        <b-col
          class="d-flex justify-content-center mr-2 p-0"
          v-else-if="ratingPercentage > 0 && scorePerTen > 0"
        >
          <ProgressBarCircle
            class="m-1"
            :percentage="ratingPercentage"
            title="Rating"
            :score="scorePerTen"
          ></ProgressBarCircle>
        </b-col>
      </b-row>
    </div>
    <div v-else>
      <h4 class="text-center">This supplier doesn't have any more information yet.</h4>
    </div>
  </b-modal>
</template>

<style lang="scss">
@import "@/styles/common.scss";

#supplierDetailsModalId {
  .supplier-details-modal-logo {
    max-height: 12em;
    max-width: 220px;
  }

  .contact-button {
    box-shadow: 2px 2px 5px rgba($color: $color-font-para, $alpha: 0.1);
    color: var(--branding-primary-color);
    border-color: var(--branding-primary-color);
    width: 260px;

    &:hover {
      color: white;
      border-color: var(--branding-primary-color);
      background-color: var(--branding-primary-color);

      & > svg {
        color: white !important;
      }
    }
  }

  .supplier-details-modal-text {
    color: $color-font-headings;
    font-size: 18px;

    &.small {
      font-size: 16px;
    }
  }

  .row-card {
    box-shadow: 2px 2px 5px rgba($color: $color-font-headings, $alpha: 0.2);
    border-radius: 12px;
  }

  @media only screen and (max-width: 991px) {
    .supplier-details-modal-text {
      font-size: 15px;
      &.small {
        font-size: 14px;
      }
    }

    .supplier-details-modal-logo {
      max-height: 9em;
    }

    .contact-button-div {
      text-align: center;
    }

    .modal-body {
      padding: 0;
    }
  }
}
</style>

<script>
import ProgressBarCircle from "@/components/savingsreport/ProgressBarCircle";
import FormatHelper from "@/helper/formathelper";

export default {
  name: "SupplierDetailsModal",
  components: { ProgressBarCircle },
  props: {
    supplier: {
      type: Object,
      default: null
    },
    primaryColor: {
      type: String,
      default: "#f27dbf"
    }
  },
  data() {
    return {
      ratingPercentage: 0,
      scorePerTen: 0
    };
  },
  computed: {
    styleObject: function () {
      return {
        "--branding-primary-color": this.primaryColor
      };
    }
  },
  watch: {
    supplier: function (value) {
      if (value) {
        if (this.isEnoughData()) {
          //clear values
          this.ratingPercentage = 0;
          this.scorePerTen = 0;

          this.$refs.supplierDetailsModal.show();

          if (value.reducerScore) {
            this.ratingPercentage = value.reducerScore * 10;
            this.scorePerTen = parseFloat(value.reducerScore.toFixed(1));
          }

          if (value.socialMedia && value.socialMedia.websiteUrl) {
            value.socialMedia.websiteUrl = FormatHelper.validateHTTP(value.socialMedia.websiteUrl);
          }
        } else {
          this.$refs.supplierDetailsModal.hide();
        }
      } else {
        this.$refs.supplierDetailsModal.hide();
      }
    }
  },
  methods: {
    checkWebsite(value) {
      if (value.length < 25) {
        return value;
      } else {
        return "Website";
      }
    },
    isEnoughData() {
      let nrOfData = 0;

      if (this.supplier.reducerScore && this.supplier.reducerScore != 0) {
        nrOfData++;
      }
      if (this.supplier.logos3url) {
        nrOfData++;
      }
      if (this.supplier.shortDescription && this.supplier.shortDescription.length > 0) {
        nrOfData++;
      }
      if (this.supplier.keyPoints && this.supplier.keyPoints.length > 0) {
        nrOfData++;
      }

      return nrOfData > 1 ? true : false;
    }
  }
};
</script>
