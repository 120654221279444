import Console from "@/console";
import ReducerPDF from "@/helper/reducerPdf.js";
import FormatHelper from "@/helper/formathelper";
import SupplyType from "@/helper/supplytypehelper";
import jsPDF from "jspdf";
import { app } from "@/config/reducerproperties";

class GeneratePDF {
  static report;
  static doc;
  static recommendedList;

  static supplierSwitchPage(type, supplyTypeName, supply) {
    this.doc.addPage();

    if (
      typeof supply.recommendedDeal == "undefined" ||
      typeof supply.currentDeal == "undefined"
    )
      return {
        status: false,
        message:
          "Suppliers data couldn't be fetched. Error in Supplier Switch page.",
      };

    if (supplyTypeName == "CUSTOM") {
      supplyTypeName = supply.customTypeName;
    }

    ReducerPDF.supplierSwitchPage(type,
      this.doc,
      this.report.images,
      FormatHelper.formatCoboldCase(supplyTypeName),
      supply.name,
      supply.currentDeal,
      supply.recommendedDeal,
      supply.deals.length
    );

    Console.log("Supplier Switch PAGE for " + FormatHelper.formatCoboldCase(supplyTypeName) + ": " + supply.name + " has been created.")
    this.otherOptionsPage(type, supplyTypeName, supply);

    if (supply.recommendedDeal.supplierId)
      this.supplierDescriptionPage(type,
        this.report.suppliers[supply.recommendedDeal.supplierId]
      );
  }

  static cardPaymentsHelper(rows, current, recommended, type, field, tag) {
    if (
      FormatHelper.isExistingField(current[field]) ||
      FormatHelper.isExistingField(recommended[field])
    ) {
      var value1 = "-";
      var value2 = "-";

      if (FormatHelper.isExistingField(current[field])) {
        if (tag == app.currencySymbol) value1 = tag + current[field];
        else {
          value1 = current[field] + tag;
        }
      }

      if (FormatHelper.isExistingField(recommended[field])) {
        if (tag == app.currencySymbol) value2 = tag + recommended[field];
        else {
          value2 = recommended[field] + tag;
        }
      }

      var row = { type: type, cRates: value1, nRates: value2 };
      rows.push(row);
    }
  }

  static otherOptionsPage(type, supplyTypeName, supply) {
    var table = new Map();
    var observations = new Array();
    var highlightRow = "";
    var header;
    var rows = new Array();

    if (typeof supply.header != "undefined")
      observations.push({
        logo: this.report.images.reducer_pink,
        main: supply.header,
      });
    if (typeof supply.footer != "undefined")
      observations.push({
        logo: this.report.images.reducer_pink,
        main: supply.footer,
      });

    if (supply.deals.length > 2) {
      header = [
        { header: "Name", dataKey: "supplier" },
        { header: "Cost/Yr.", dataKey: "cost" },
        { header: "Contract", dataKey: "contract" },
        { header: "Savings/Yr.", dataKey: "savings" },
      ];
      rows = new Array();
      for (var i = 0; i < supply.deals.length; i++) {
        if (supply.deals[i].recommended) { highlightRow = i; }
        if (
          supply.deals[i].state != "CURRENT" &&
          supply.deals[i].supplierName
        ) {
          var contract = "";
          if (typeof supply.deals[i].contractTerm != "undefined") {
            contract = FormatHelper.formatMonthsToYears(
              supply.deals[i].contractTerm
            );
          }

          var cost = "";
          if (
            typeof supply.deals[i].annualCost != "undefined" &&
            supply.deals[i].annualCost != ""
          ) {
            cost =
              app.currencySymbol +
              FormatHelper.formatNumberToDisplay(supply.deals[i].annualCost);
          }

          var savings = "";
          if (
            typeof supply.deals[i].saving != "undefined" &&
            supply.deals[i].saving != ""
          ) {
            savings =
              app.currencySymbol + FormatHelper.formatNumberToDisplay(supply.deals[i].saving);
          } else {
            savings = app.currencySymbol + "0";
          }

          var row = {
            supplier: supply.deals[i].supplierName,
            cost: cost,
            contract: contract,
            savings: savings,
          };
          rows.push(row);
        }
      }

      table["header"] = header;
      table["rows"] = rows;
    }

    if (
      (typeof table.rows != "undefined" && table.rows.length > 0) ||
      observations.length > 0
    ) {
      this.doc.addPage();
      ReducerPDF.otherOptionsPage(type,
        this.doc,
        this.report.images.reducer,
        FormatHelper.formatCoboldCase(supplyTypeName) + ": " + supply.name,
        table,
        observations,
        highlightRow
      );

      Console.log("Other Options PAGE for " + FormatHelper.formatCoboldCase(supplyTypeName) + ": " + supply.name + " has been created.")
    }

    if (supplyTypeName == "CARD_PAYMENTS") {
      var current = supply.currentDeal;
      var recommended = supply.recommendedDeal;
      table = new Map();
      rows = new Array();

      header = [
        { header: "Card Type", dataKey: "type" },
        { header: "Current Rates", dataKey: "cRates" },
        { header: "New Rates", dataKey: "nRates" },
      ];

      this.cardPaymentsHelper(
        rows,
        current,
        recommended,
        "CNP Credit",
        "cnpCredit",
        "%"
      );
      this.cardPaymentsHelper(
        rows,
        current,
        recommended,
        "CNP Debit",
        "cnpDebit",
        "%"
      );
      this.cardPaymentsHelper(
        rows,
        current,
        recommended,
        "CNP Credit International",
        "cnpCreditInternational",
        "%"
      );
      this.cardPaymentsHelper(
        rows,
        current,
        recommended,
        "CNP Debit International",
        "cnpDebitInternational",
        "%"
      );
      this.cardPaymentsHelper(
        rows,
        current,
        recommended,
        "Personal Credit",
        "creditPersonal",
        "%"
      );
      this.cardPaymentsHelper(
        rows,
        current,
        recommended,
        "Personal Debit",
        "debitPersonal",
        "%"
      );
      this.cardPaymentsHelper(
        rows,
        current,
        recommended,
        "Business Credit",
        "creditBusiness",
        "%"
      );
      this.cardPaymentsHelper(
        rows,
        current,
        recommended,
        "Business Debit",
        "debitBusiness",
        "%"
      );
      this.cardPaymentsHelper(
        rows,
        current,
        recommended,
        "International Credit",
        "creditInternational",
        "%"
      );
      this.cardPaymentsHelper(
        rows,
        current,
        recommended,
        "International Debit",
        "debitInternational",
        "%"
      );
      this.cardPaymentsHelper(
        rows,
        current,
        recommended,
        "MC Personal",
        "mcPersonal",
        "%"
      );
      this.cardPaymentsHelper(
        rows,
        current,
        recommended,
        "MC Business",
        "mcBusiness",
        "%"
      );
      this.cardPaymentsHelper(
        rows,
        current,
        recommended,
        "MC Premium",
        "mcPremiumCredit",
        "%"
      );

      table["header"] = header;
      table["rows"] = rows;

      if (table["rows"].length > 0) {
        this.doc.addPage();
        ReducerPDF.cardPaymentsSwitchPage(type,
          this.doc,
          this.report.images.reducer,
          FormatHelper.formatCoboldCase(supplyTypeName) + ": " + supply.name,
          table
        );

        Console.log("Card Payments switch PAGE for " + FormatHelper.formatCoboldCase(supplyTypeName) + ": " + supply.name + " has been created.")
      }

      table = new Map();
      rows = new Array();
      header = [
        { header: "Other Charges", dataKey: "type" },
        { header: "Current Rates", dataKey: "cRates" },
        { header: "New Rates", dataKey: "nRates" },
      ];

      this.cardPaymentsHelper(
        rows,
        current,
        recommended,
        "CNP Credit Fee",
        "cnpCreditFee",
        app.currencySymbol
      );
      this.cardPaymentsHelper(
        rows,
        current,
        recommended,
        "CNP Debit Fee",
        "cnpDebitFee",
        app.currencySymbol
      );
      this.cardPaymentsHelper(
        rows,
        current,
        recommended,
        "CNP Credit International Fee",
        "cnpCreditInternationalFee",
        app.currencySymbol
      );
      this.cardPaymentsHelper(
        rows,
        current,
        recommended,
        "CNP Debit International Fee",
        "cnpDebitInternationalFee",
        app.currencySymbol
      );
      this.cardPaymentsHelper(
        rows,
        current,
        recommended,
        "Inter-Regional Fee",
        "interRegional",
        app.currencySymbol
      );
      this.cardPaymentsHelper(
        rows,
        current,
        recommended,
        "Intra-Regional Charge",
        "intraRegional",
        "%"
      );
      this.cardPaymentsHelper(
        rows,
        current,
        recommended,
        "International Fee",
        "internationalFee",
        app.currencySymbol
      );
      this.cardPaymentsHelper(
        rows,
        current,
        recommended,
        "Management Fee",
        "managementFee",
        app.currencySymbol
      );
      this.cardPaymentsHelper(
        rows,
        current,
        recommended,
        "Authorisation Fee",
        "authorisationFee",
        "p"
      );
      this.cardPaymentsHelper(
        rows,
        current,
        recommended,
        "Non-Compliance Fee",
        "nonComplianceFee",
        app.currencySymbol
      );
      this.cardPaymentsHelper(
        rows,
        current,
        recommended,
        "Monthly Min. Charge",
        "minimumMonthlyCharge",
        app.currencySymbol
      );

      table["header"] = header;
      table["rows"] = rows;

      if (table["rows"].length > 0) {
        this.doc.addPage();
        ReducerPDF.cardPaymentsSwitchPage(type,
          this.doc,
          this.report.images.reducer,
          FormatHelper.formatCoboldCase(supplyTypeName) + ": " + supply.name,
          table
        );

        Console.log("Card Payments Switch PAGE for " + FormatHelper.formatCoboldCase(supplyTypeName) + ": " + supply.name + " has been created.")
      }
      table = new Map();
    }
  }

  static supplierDescriptionPage(type, recommended) {
    if (typeof recommended != "undefined" &&
      typeof recommended.shortDescription != "undefined" &&
      !this.recommendedList.includes(recommended.name)
    ) {
      this.doc.addPage();
      this.recommendedList.push(recommended.name);

      ReducerPDF.supplierDescriptionPage(type,
        this.doc,
        this.report.images,
        recommended
      );

      if (typeof recommended != "undefined") {
        Console.log("Supplier Description PAGE for " + recommended.name + " supplier has been created.")
      }

    }
  }

  static savingsPage(type) {
    var savingsFound = Object.keys(this.report.supplyData);

    if (!savingsFound)
      return {
        status: false,
        message: "Savings Page Error: No savings found.",
      };

    var savings = new Array();
    SupplyType.supplyTypes().forEach((element) => {
      //skip the custom types for now
      if (element.toLowerCase()) {
        var supplierType = new Map();

        var imageName = "";
        if (typeof this.report.images[element.toLowerCase()] != "undefined")
          imageName = element.toLowerCase();
        else imageName = "reducer";

        if (savingsFound.includes(element)) {
          if (typeof this.report.supplyData[element].savings == "undefined") {
            supplierType["secondary"] = app.currencySymbol + "0";
          } else {
            supplierType["secondary"] = app.currencySymbol + FormatHelper.formatNumberToDisplay(
              this.report.supplyData[element].savings);
          }
        } else {
          supplierType["secondary"] = "Not found";
          if (imageName != "reducer") imageName += "_disabled";
        }

        supplierType["logo"] = this.report.images[imageName];
        if (element == "CUSTOM") {
          element = "OTHER";
        }

        supplierType["main"] = FormatHelper.formatCoboldCase(element);
        savings.push(supplierType);
      }
    });

    if (type == "Reducer") {
      ReducerPDF.savingsPage(type,
        this.doc,
        this.report.images.reducer,
        this.report.orgName,
        this.report.switches.toFixed(0),
        app.currencySymbol + FormatHelper.formatNumberToDisplay(this.report.savings),
        savings
      );
    } else {
      ReducerPDF.savingsPage(type,
        this.doc,
        this.report.images.reducer,
        this.report.orgName,
        this.report.switches.toFixed(0),
        app.currencySymbol + FormatHelper.formatNumberToDisplay(this.report.savings),
        savings,
        this.report.images.aw
      );
    }


    Console.log("Savings PAGE with ", savingsFound.length, " suggested switched and " + app.currencySymbol + FormatHelper.formatNumberToDisplay(this.report.savings) + " savings has been created.")
  }

  static supplierNoSwitchPage(type, supplyTypeName, supply) {
    this.doc.addPage();

    var observations = new Array();
    if (supply.noQuotes == "NOT_ENOUGH_DATA") {
      observations.push(
        "We are unable to quote for this area as there was no recorded spend on your accounting system."
      );
      observations.push(
        "If this is not the case, please provide all pages of a recent copy of your bill and we will quote you accordingly."
      );

      ReducerPDF.supplierNoSwitchPage(type,
        this.doc,
        this.report.images.reducer,
        FormatHelper.formatCoboldCase(supply.noQuotes),
        FormatHelper.formatCoboldCase(supplyTypeName) + ": " + supply.name,
        observations
      );
    } else {
      if (supply.noQuotes == "ON_GOOD_DEAL") {
        var text =
          "Nice one, you are on a good deal with your current supplier";
        if (
          typeof supply.currentDeal != "undefined" &&
          typeof supply.currentDeal.supplierName != "undefined" &&
          supply.currentDeal.supplierName
        ) {
          text += ", " + supply.currentDeal.supplierName + ".";
        } else {
          text += ".";
        }
        observations.push(text);
      } else if (supply.noQuotes == "IN_CONTRACT") {
        text = "You are currently in contract with your supplier";
        if (
          typeof supply.currentDeal != "undefined" &&
          typeof supply.currentDeal.supplierName != "undefined" &&
          supply.currentDeal.supplierName != ""
        ) {
          text += ", " + supply.currentDeal.supplierName;
        } else {
          text += ".";
        }

        observations.push(text);

        if (
          typeof supply.currentDeal.contractExpiry != "undefined" &&
          supply.currentDeal.contractExpiry != ""
        ) {
          observations.push(
            "Your contract with this supplier will expire on " +
            FormatHelper.formatDate(supply.currentDeal.contractExpiry) +
            "."
          );
        }
      }

      if (
        typeof supply.currentDeal != "undefined" &&
        typeof this.report.suppliers[supply.currentDeal.supplierId] !=
        "undefined"
      ) {
        if (supplyTypeName == "CUSTOM") {
          supplyTypeName = supply.customTypeName;
        }

        ReducerPDF.supplierNoSwitchPage(type,
          this.doc,
          this.report.images.reducer,
          FormatHelper.formatCoboldCase(supply.noQuotes),
          FormatHelper.formatCoboldCase(supplyTypeName) + ": " + supply.name,
          observations,
          this.report.images[supply.currentDeal.supplierId]
        );

      } else {
        if (supplyTypeName == "CUSTOM") {
          supplyTypeName = supply.customTypeName;
        }

        ReducerPDF.supplierNoSwitchPage(type,
          this.doc,
          this.report.images.reducer,
          FormatHelper.formatCoboldCase(supply.noQuotes),
          FormatHelper.formatCoboldCase(supplyTypeName) + ": " + supply.name,
          observations
        );
      }
    }

    Console.log("No Switch PAGE with " + FormatHelper.formatCoboldCase(supply.noQuotes) + " for " + FormatHelper.formatCoboldCase(supplyTypeName) + ": " + supply.name + " has been created.")
  }

  static keyInformationPage(type) {
    this.doc.addPage();

    var logos = new Array();
    logos.push(this.report.images.handshake);
    logos.push(this.report.images.consultation);

    ReducerPDF.keyInformationPage(type,
      this.doc,
      logos,
      this.report.images.reducer,
      this.report.savings
    );

    Console.log("Ending PAGE has been created.")

  }

  static generate(report, type) {
    if (!report) {
      Console.error("No data provided, empty pdf cannot be generated.");
      return false;
    }

    Console.log("Generating PDF report out of: ", report);

    this.report = report;
    this.recommendedList = [];
    this.doc = new jsPDF();

    this.savingsPage(type);

    if (type == "Reducer") {
      for (var supplyType in this.report.supplyData) {
        for (var supply in this.report.supplyData[supplyType].supplies) {
          if (
            typeof this.report.supplyData[supplyType].supplies[supply].noQuotes !=
            "undefined"
          ) {
            this.supplierNoSwitchPage(type,
              supplyType.toString(),
              this.report.supplyData[supplyType].supplies[supply]
            );
          } else {
            this.supplierSwitchPage(type,
              supplyType.toString(),
              this.report.supplyData[supplyType].supplies[supply]
            );
          }
        }
      }

      this.keyInformationPage(type);
    }

    let title = "";
    if (this.report.orgName != "") {
      title = "for " + this.report.orgName;
    }

    this.doc.save("Savings Report " + title + ".pdf");
  }
}
export default GeneratePDF;
